const CommonServices = {
    capitalizeLabel: function (str: string): string {
        let convertedStr = str.replace(/\d+$/, '');
        convertedStr = convertedStr.charAt(0).toUpperCase() + convertedStr.slice(1);
        convertedStr = convertedStr.replace(/_/g, " ");
        return convertedStr;
    },

    convertIntoModelName: function (str: string): string {
        const words = str.split('_');
        const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
        return capitalizedWords.join('');
    },

    stringFormat: function (str: string): string {
        let convertedStr = str.replaceAll("_", " ");
        convertedStr = convertedStr.charAt(0).toUpperCase() + convertedStr.slice(1);
        return convertedStr;
    },

    checkPermissions: async (
        userPermissions: any,
        requiredPermissions: any,
        strict: boolean,
        actions: boolean,
        override?: boolean
    ): Promise<boolean> => {
        if (override) {
            return true;
        }

        if (requiredPermissions.length === 0) {
            return true; // No required permissions, grant access
        }

        // Strict Mode: Ensure all required permissions are satisfied
        if (strict) {
            for (const allowedPermission of requiredPermissions) {
                try {
                    const resourcePermissions = userPermissions[allowedPermission.permission]; // Access by resource key

                    if (!resourcePermissions) {
                        return false;
                    }

                    if (allowedPermission.read && !resourcePermissions.read) {
                        return false;
                    }
                    if (allowedPermission.create && !resourcePermissions.create) {
                        return false;
                    }
                    if (allowedPermission.update && !resourcePermissions.update) {
                        return false;
                    }
                    if (allowedPermission.delete && !resourcePermissions.delete) {
                        return false;
                    }
                } catch (error) {
                    console.error("Error checking permissions:", error);
                    return false;
                }
            }
            return true;
        }
        // Non-strict mode: Actions-based check
        else if (actions) {
            for (const allowedPermission of requiredPermissions) {
                try {
                    const resourcePermissions = userPermissions[allowedPermission.permission];

                    if (!resourcePermissions) {
                        return false;
                    }

                    const isAnyPermissionTrue = Object.keys(allowedPermission).some((key) => {
                        return (
                            key !== "permission" &&
                            allowedPermission[key] &&
                            resourcePermissions[key] === true
                        );
                    });

                    if (isAnyPermissionTrue) {
                        return true;
                    }
                } catch (error) {
                    console.error("Error checking permissions:", error);
                    return false;
                }
            }
            return false;
        }
        // Default: Check if any of the permissions are satisfied
        else {
            for (const allowedPermission of requiredPermissions) {
                const resourcePermissions = userPermissions[allowedPermission.permission];

                if (!resourcePermissions) {
                    return false;
                }

                const isAnyTrue = Object.values(resourcePermissions).some((val) => val === true);
                if (isAnyTrue) {
                    return true;
                }
            }
            return false;
        }
    },
};

export default CommonServices;
