import React, { useState, useContext, useEffect } from "react";
import { PcContext } from "./PcContext";
import { translate } from "../../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../../services/ApiServices";
import {
  ADD_PC_AGE,
  GET_AGE,
  UPDATE_PC_AGE,
} from "../../../routes/ApiEndpoints";
import Title from "../../common/atoms/Title";
import { t } from "../../CentralDataMangement/translation/Translation";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import Button from "../../common/atoms/Button";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";

const Addage = (props) => {
  const {
    pc_unique_key,
    setCurrent_sec,
    setSec_completed,
    sec_completed,
    setPc_unique_key,
    cat_subsec_type,
    setCat_subsec_type,
    setCat_subsec_id,
    setCat_fun_updated,
    setCat_rightsec,
    setCat_leftsec,
    pc_view_type,
  } = useContext(PcContext);

  const [startAge, setStartAge] = useState(21);
  const [selectedAge, setSelectedAge] = useState(0);
  const [formDisplay, setFormDisplay] = useState(false);
  const [percentages, setPercentages] = useState({});
  const [errors, setErrors] = useState({});
  const [sec_width, setSec_width] = useState("col-md-6");
  const [disableForm, setDisableForm] = useState(false); // for view and edit type of pc
  const [id, setId] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await ApiCall.getService(
          GET_AGE + "/" + pc_unique_key,
          "GET",
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.success) {
          const data = response.data;
          setId(data.pcid);
          setStartAge(data.options_age);
          setSelectedAge(data.selected_age + 1);

          const newPercentages = {};
          Object.keys(data).forEach((key) => {
            if (key !== "selected_age") {
              const { age, min_sal_percent } = data[key];
              newPercentages[age] = min_sal_percent;
            }
          });
          setPercentages(newPercentages);
          setFormDisplay(true);
        } else {
          setSelectedAge(0);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [pc_unique_key]);

  useEffect(() => {
    if (pc_view_type === "viewpc") {
      setDisableForm(true);
      setSec_width("col-md-12");
    }
    if (pc_view_type === "editpc") {
      setSec_width("col-md-12");
    }
  }, [pc_view_type]);

  const handleAgeChange = (e) => {
    const age = parseInt(e.target.value);
    setSelectedAge(age);
    if (age !== 0) {
      setFormDisplay(true);
      // Reset percentages state with only the relevant ages
      const newPercentages = {};
      for (let i = age - 1; i >= 15; i--) {
        newPercentages[i] = ""; // Initialize with an empty string or default value
      }
      setPercentages(newPercentages);
    } else {
      setFormDisplay(false);
      setPercentages({});
    }
  };

  const handlePercentageChange = (age, e) => {
    const value = e.target.value;

    setPercentages((prevState) => ({
      ...prevState,
      [age]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [age]: "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Validate each percentage field
    for (let age = selectedAge - 1; age >= 15; age--) {
      const value = percentages[age].replace(",", ".");
      if (!value || isNaN(value) || parseFloat(value) <= 0 || parseFloat(value) > 100) {
        newErrors[age] = "Please enter a valid percentage within range 1 to 100";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      console.log("Submitted percentages:", percentages);
      postdata();
    }
  };

  let next_redirection = () => {
    setCurrent_sec(4);
    var res1 = sec_completed;
    res1["age"] = true;
    setSec_completed(res1);
  };

  const postdata = async () => {
    if (id === "") {
      // for add age
      try {
        const data = {
          percentages: percentages,
          pc_unique_key: pc_unique_key,
        };
        const response = await ApiCall.service(
          ADD_PC_AGE,
          "POST",
          data,
          false,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.success) {
          if (cat_subsec_type == 4) {
            setCat_fun_updated("age" + response.pcid);
            setCat_rightsec("d-none");
            setCat_leftsec("col-md-12");
            setCat_subsec_type(0);
            setCat_subsec_id("");
          } else {
            setCurrent_sec(4);
            var res1 = sec_completed;
            res1["age"] = true;
            setSec_completed(res1);
          }
        }
      } catch (error) {
        console.error("Error submitting age data:", error);
      }
    } else {
      const data = {
        percentages: percentages,
        pc_unique_key: pc_unique_key,
      };

      try {
        const response = await ApiCall.service(
          UPDATE_PC_AGE,
          "POST",
          data,
          false,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );

        if (response.success) {
          console.log(response, "response");
          if (cat_subsec_type === 4) {
            setCat_fun_updated("age" + response.data);
            setCat_rightsec("d-none");
            setCat_leftsec("col-md-12");
            setCat_subsec_type(0);
            setCat_subsec_id("");
          } else {
            next_redirection();
          }
          window.location.reload();
        }
      } catch (error) {
        console.error("Error submitting age data:", error);
      }
    }
  };

  const renderPercentFields = () => {
    const fields = [];
    for (let age = selectedAge - 1; age >= 15; age--) {
      fields.push(
        <div key={age} className="mb-3">
          <div className="input-group position-relative">
            <LabelWithInputField
              label={t("Minimum salary for") + " " + age + " " + t("years")}
              disabled={disableForm}
              value={percentages[age] || ""}
              name="maxworkHorsperWeekError"
              id="maxworkHorsperWeekError"
              handleChange={(e) => handlePercentageChange(age, e)}
              error={errors[age]}
              className="ps-2"
              isTop={true}
            />
            <span className="fieldSuffix position-absolute">%</span>
          </div>
        </div>
      );
    }
    return fields;
  };

  // for age options
  const options = [];
  options.push(
    <option key={0} value={0}>
      Select an age
    </option>
  );
  for (let i = startAge; i >= 15; i--) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <div className="border p-3 rounded-3 mt-1">
      <OverFlowWrapper className="ageWrapper ">
        <Title
          title={t("At which age full salary is paid") + "?"}
          className="colorPrimary poppins-medium mb-3 pcTitle"
        />
        <div style={{ paddingLeft: "0.1vw" }}>
          <select
            value={selectedAge}
            onChange={handleAgeChange}
            disabled={disableForm}
            className="form-select form-control inputFieldColor functionCategoryField shadow-none mb-3 ps-2 rounded-3"
          >
            {options}
          </select>
          {formDisplay && (
            <form onSubmit={handleSubmit} className="text-end">
              {renderPercentFields()}
              <Button
                disabled={disableForm}
                type="submit"
                title={t("Save")}
                className="searchBtn px-3"
              />
            </form>
          )}
        </div>

      </OverFlowWrapper>
    </div>
  );
};

export default translate(Addage);
