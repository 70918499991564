import React, { CSSProperties, useState } from "react";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import Button from "../atoms/Button";
import { t } from "../atoms/translation";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const styles = {
  zone: {
    alignItems: "center",
    border: `2px dashed #00182C`,
    borderRadius: 0,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 0,
    color: "#00182C",
    minHeight: 85,
  } as CSSProperties,
  file: {
    // background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 65,
    // width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    justifyContent: "center",
    display: "flex",
  } as CSSProperties,
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 18,
    marginBottom: "0.5em",
  } as CSSProperties,
  progressBar: {
    bottom: 16,
    height: 4,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  zoneHover: {
    borderColor: GREY_DIM,
  } as CSSProperties,
  default: {
    borderColor: GREY,
  } as CSSProperties,
  remove: {
    height: 23,
    position: "absolute",
    right: -35,
    top: 18,
    width: 23,
  } as CSSProperties,
};

const CSVReader = ({ importFromCSV, errorMsg }: any) => {
  const [data, setData] = useState();
  const [fileData, setFileData] = useState();
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  const handleFileData = (data: any, file: any) => {
    setData(data);
    setFileData(file);
    setZoneHover(false);
  };

  const saveImport = () => {
    importFromCSV(data, fileData);
  };

  const handleRemove = () => {
    setData(undefined); // Clear the data
    setFileData(undefined); // Clear the fileData
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <CSVReader
            onUploadAccepted={(results: any, file: File) => {
              handleFileData(results, file);
            }}
            onDragOver={(event: DragEvent) => {
              event.preventDefault();
              setZoneHover(true);
            }}
            onDragLeave={(event: DragEvent) => {
              event.preventDefault();
              setZoneHover(false);
            }}
            config={{ encoding: "UTF-8" }}
          >
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
              Remove,
            }: any) => (
              <>
                <div
                  {...getRootProps()}
                  style={Object.assign(
                    {},
                    styles.zone,
                    zoneHover && styles.zoneHover
                  )}
                  className="csvReader mobileFont"
                >
                  {acceptedFile ? (
                    <>
                      <div style={styles.file}>
                        <div style={styles.info}>
                          <span style={styles.size}>
                            {formatFileSize(acceptedFile.size)}{" "}
                            <span className="ms-2">{acceptedFile.name}</span>
                          </span>
                          {/* <span style={styles.name}>{acceptedFile.name}</span> */}
                        </div>
                        <div style={styles.progressBar}>
                          <ProgressBar />
                        </div>
                        <div
                          {...getRemoveFileProps()}
                          style={styles.remove}
                          onMouseOver={(event: Event) => {
                            event.preventDefault();
                            setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                          }}
                          onMouseOut={(event: Event) => {
                            event.preventDefault();
                            setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                          }}
                        >
                          <span title={t("Remove file")} onClick={handleRemove}>
                            <Remove color={removeHoverColor} />
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    t("Drop CSV file here or click to upload")
                  )}
                </div>
              </>
            )}
          </CSVReader>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-12">
          {errorMsg && (
            <div className="text-danger mb-2">{t(`${errorMsg}`)}</div>
          )}
          <Button
            title={t("Submit")}
            type="submit"
            className="searchBtn float-end"
            handleClick={saveImport}
          />
        </div>
      </div>
    </>
  );
};

export default CSVReader;
