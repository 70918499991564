import React, { useEffect, useRef, useState } from 'react';
import Image from './Image';
import LabelField from './LabelField';
import Icon from '../../../Icon';
import useWindowWidth from './WindowWidth';

interface CompanyObj {
  value: number;
  label: string;
  url: string;
}

interface CompanySelectCardProps {
  relatedCompanies: CompanyObj[];
  onSelect: (companyId: number | null) => void; // Function to handle selected company ID
  // getLocations: (companyId: number) => Promise<void>;
  getLocations?: (companyId: number) => Promise<void>
  initialSelectedCompanyId?: number | null; // New prop for initial selected company ID 
  error?: string;
}

const CompanySelectCard: React.FC<CompanySelectCardProps> = ({ relatedCompanies, onSelect, getLocations, initialSelectedCompanyId, error }) => {

  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(initialSelectedCompanyId || null);

  useEffect(() => {
    setSelectedCompanyId(initialSelectedCompanyId ?? null); // Use null if initialSelectedCompanyId is undefined
    if (initialSelectedCompanyId) {
      if (getLocations) {
        getLocations(initialSelectedCompanyId);
      }
    }
  }, [initialSelectedCompanyId]);

  const handleSelectCompany = async (company: CompanyObj) => {
    if (selectedCompanyId === company.value) {
      // If the clicked company is already selected, deselect it
      setSelectedCompanyId(null);
      onSelect(null); // Deselect the company
    } else {
      // Otherwise, select the company and fetch locations
      console.log("in company cards");
      setSelectedCompanyId(company.value);
      onSelect(company.value);
      if (getLocations) {
        await getLocations(company.value); // Call getLocations only if it exists
      }
    }
  };

  const scrollRef = useRef<HTMLDivElement>(null);
  // Function to handle scroll (left or right)
  const handleScroll = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const scrollAmount = direction === "right" ? 200 : -200;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const isDesktop = useWindowWidth(1024);

  return (
    <>
      <div className={`d-flex align-items-center locatopnCompanyCard ${error ? 'error-border' : ''}`}>
        <div
          style={{ width: isDesktop ? "3%" : "8%" }}
          className="nextIconWrapper"
          onClick={() => handleScroll("left")}
        >
          <Icon
            name="backIcon"
            isIcon={true}
            width={"1.7vw"}
            height={"1.7vw"}
            fillColor="#8571b9"
          />
        </div>
        <div style={{ width: isDesktop ? "94%" : "84%" }}>
          <div
            ref={scrollRef}
            className={`mx-auto d-flex p-2 pb-3 scrollBar`}
            style={{
              overflowX: "auto",
              // scrollbarWidth: "none", // For Firefox
              gap: "0.5vw",
            }}
          >
            {relatedCompanies.map((company) => (
              <div
                key={company.value}
                className={`radioButtonContainer locationRadioBtn company-card ${selectedCompanyId === company.value ? 'selectedLocation' : ''}`}
                onClick={() => handleSelectCompany(company)} // Handle single click for selection
              //onDoubleClick={() => handleDoubleClick(company)} // Handle double click for deselection
              >
                <Image src={company.url} imageWidth={"3vw"} imageHeight={"3vw"} imageBorderRadius={"50%"} />
                <div>
                  <LabelField title={company.label} className='colorPrimary companyName text-break mt-3 text-break' />

                </div>
                {/* <img src={company.url} alt={company.label} className="company-logo" /> */}
                {/* <h3>{company.label}</h3> */}
              </div>
            ))}
          </div>
        </div>
        <div
          style={{ width: isDesktop ? "3%" : "8%" }}
          className="text-end nextIconWrapper"
          onClick={() => handleScroll("right")}
        >
          <Icon
            name="nextIcon"
            isIcon={true}
            width={"1.7vw"}
            height={"1.7vw"}
            fillColor="#8571b9"
          />
        </div >
      </div>
      {error && <div className="text-danger">{error}</div>}
    </>
  );
};

export default CompanySelectCard;
