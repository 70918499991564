import React, { useState } from "react";
import DateAndTime from "../common/atoms/DateWithTime";
import HeaderWIthFilter from "./HeaderWIthFilter";
import PlanningTable from "./PlanningTable";
import { useSelector } from "react-redux";
import { selectPlanningShow } from "../../features/planning/planningSlice";
import { selectCurrentLocations } from "../../features/auth/AuthSlice";
import PlanningTabs from "./PlanningTabs";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import OverFlowWrapper from "../common/OverFlowWrapper";

interface Employee {
  id: number;
  first_name: string;
  last_name: string;
  is_fav: boolean;
  profile_picture_url: string | null;
  employee_type: string;
  employee_type_id: number;
  employee_type_category: string;
  employee_type_category_id: number;
  paritaire_commitee: string;
  paritaire_commitee_id: number;
  functional_salary: string;
  function_id: number;
  function_name: string;
}

const DayPlanningOverview = () => {
  const show = useSelector(selectPlanningShow); // Access the `show` state
  const companyId = useSelector((state: any) => state.auth.currentCompany?.id);
  const locations = useSelector(selectCurrentLocations) ?? []; // Access the locations state

  const [date, setDate] = useState(new Date());
  const [selectedLocationIndex, setSelectedLocationIndex] = useState(0); // New state for selected location index
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]); // State to store the selected employees

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
  };

  const handleLocationChange = (index: number) => {
    setSelectedLocationIndex(index); // Update selected location index
  };

  const handleEmployeeClick = (employee: Employee) => {
    setSelectedEmployees((prevSelected = []) => {
      // Check if the employee is already selected
      if (prevSelected?.find((emp) => emp.id === employee.id)) {
        // If selected, remove from the array
        return prevSelected?.filter((emp) => emp.id !== employee.id);
      } else {
        // If not selected, add to the array
        return [...prevSelected, employee];
      }
    });
  };
  return (
    <>
      <div>
        <HeaderWIthFilter
          headerName={t("Planning - Day")}
          type={"day"}
          selectedLocation={locations[selectedLocationIndex]} // Pass the currently selected location
          onLocationChange={handleLocationChange} // Pass the handler function
          handleEmployeeClick={handleEmployeeClick}
          selectedEmployees={selectedEmployees} // Pass the selected employees
        >
          <DateAndTime
            isDate={true}
            showDate={true}
            showMonth={true}
            showYear={true}
            isDay={true}
            isIcon={true}
            monthFormat="long"
            dataTimeWrapperClassName="d-inline-flex align-items-center colorSkyBlue bg-white planningDateWrapper"
            dataTimeClassName="mx-3 planningPlannedDate"
            dayClassName="me-1"
            iconFillColor="#8571b9"
            onDateChange={handleDateChange}
          />
        </HeaderWIthFilter>
      </div>
      <div className="row">
        <div className="col-12">
          <OverFlowWrapper className="dayPlanningOverviewTable">
            <PlanningTable
              selectedDate={date}
              companyId={companyId}
              selectedLocation={locations[selectedLocationIndex]}
              selectedEmployees={selectedEmployees}
            />
            {/* Pass selected employees as a prop */}
          </OverFlowWrapper>
        </div>
      </div>
      {show && <PlanningTabs />}{" "}
      {/* Conditionally render the modal based on the `show` state */}
    </>
  );
};

export default translate(DayPlanningOverview);
