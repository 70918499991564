import { FormValues } from './MyFareInterface';

export const fareFormValidation = (values: FormValues) => {
    const errors: { [key: string]: string } = {};

    // Validate fare name
    if (!values.fare_name?.trim()) {
        errors.fare_name = 'Fare name is required';
    }

    // Validate start date
    if (!values.start_date) {
        errors.start_date = 'Start date is required';
    }

    // Validate end date
    if (!values.end_date) {
        errors.end_date = 'End date is required';
    } else if (values.start_date && new Date(values.end_date) <= new Date(values.start_date)) {
        errors.end_date = 'End date must be after start date';
    }

    return errors;
};