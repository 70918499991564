import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { ApiCall } from '../../../../services/ApiServices';
import TableRendererCoefficientTypes from './TableRendererCoefficientTypes';
import { t, translate } from '../../../CentralDataMangement/translation/Translation';

import OperationCoefficientTypes from './OperationCoefficientTypes';
import { FETCH_COEFFICIENT_TYPES } from '../../../../routes/ApiEndpoints';
import { FullScreenLoading } from '../../../common/utlis/FullScreenLoading';
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from '../../../../Constants';

const ManageCoefficientTypes = (props) => {
    const [state, setState] = useState({ rows: [], headers: [], loaded: false });

    const { id, manageType } = useParams();
    const location = useLocation();
    const resolvedUrl = location.pathname + location.search;

    useEffect(() => {
        loadData();
    }, [manageType]);

    const loadData = () => {
        ApiCall.getService(
            FETCH_COEFFICIENT_TYPES,
            "GET",
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
            true
        )
            .then((result) => {
                setState({
                    ...state,
                    loaded: true,
                    rows: result.success ? result.data : [],
                    headers: ['Coefficient', 'Actions'],
                    manageType,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // Determine whether to show OperationCoefficientTypes or TableRendererCoefficientTypes based on URL
    const isEditingOrAdding = resolvedUrl.includes('edit') || resolvedUrl.includes('add');

    return (
        <>
            <div className='container-fluid p-0 m-0'>
                <div className='md-3 col-md-12'>
                    {state.loaded ? (
                        isEditingOrAdding ? (
                            <OperationCoefficientTypes {...state} />
                        ) : (
                            <TableRendererCoefficientTypes {...state} loadData={loadData} />
                        )
                    ) : (
                        <FullScreenLoading />
                    )}
                </div>
            </div>
        </>
    );
};

export default translate(ManageCoefficientTypes);
