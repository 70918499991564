import React, { useRef, useCallback, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import Button from "./Button";
import { t } from "./translation";
import { useSelector } from "react-redux";
import { ReduxState } from "../../Dashboard/services/CompanyInterface";
import { uploadFile } from "../../../services/common/CommonServices";

interface SignatureFieldProps {
  onChange?: (dataUrl: string) => void;
  onSave?: (dataUrl: string) => void;
  className?: string;
  height?: string;
  width?: string;
  clearBtnClass?: string;
  saveBtnClass?: string;
  disabled?: boolean;
  dataUrl?: string;
}

const SignatureField: React.FC<SignatureFieldProps> = ({
  onChange = () => { },
  onSave = () => { },
  className = "",
  height = "300px",
  width = "450px",
  clearBtnClass = "",
  saveBtnClass = "",
  disabled = false,
  dataUrl = "",
}) => {
  const signatureRef = useRef<SignatureCanvas>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const user = useSelector((state: ReduxState) => state?.auth?.userId);

  // Load existing signature if dataUrl is provided
  useEffect(() => {
    if (dataUrl && signatureRef.current) {
      fetch(dataUrl)
        .then(response => response.blob())
        .then(blob => blobToBase64(blob))
        .then(base64 => {
          if (signatureRef.current) {
            signatureRef.current.fromDataURL(base64);
          }
        })
        .catch(err => {
          console.error("Error loading signature:", err);
          setErrorMessage("Failed to load signature.");
        });
    }
  }, [dataUrl]);

  // Convert base64 to Blob
  const base64ToBlob = (base64Data: string, contentType: string = 'image/png') => {
    const byteCharacters = atob(base64Data.split(',')[1]);
    const byteNumbers = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Blob([byteNumbers], { type: contentType });
  };

  // Convert Blob to Base64
  const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  // Clear signature
  const clearSignature = useCallback(() => {
    if (signatureRef.current) {
      signatureRef.current.clear();
      onChange("");
      setErrorMessage(null);
    }
  }, [onChange]);

  // Save signature
  const handleSaveSignature = useCallback(async () => {
    if (signatureRef.current && !signatureRef.current.isEmpty()) {
      try {
        const signatureDataUrl = signatureRef.current.toDataURL("image/png");

        // Convert base64 to Blob
        const blob = base64ToBlob(signatureDataUrl);
        const fileName = user ? `${user}_signature.png` : "signature.png";
        const file = new File([blob], fileName, { type: "image/png" });

        // Upload the file
        const uploadResponse = await uploadFile(file, fileName, 15, "signature");

        // Use the uploaded file's URL in the onSave callback
        onSave(uploadResponse?.data?.fileUrl);
        setErrorMessage(null);
      } catch (error) {
        console.error(error);
        setErrorMessage("Failed to save signature due to a CORS issue.");
      }
    } else {
      setErrorMessage(`${t("Please add signature before saving")}!!`);
    }
  }, [onSave, user]);

  // Detect signature changes
  const handleEndSignature = useCallback(() => {
    if (signatureRef.current && !signatureRef.current.isEmpty()) {
      const signatureDataUrl = signatureRef.current.getTrimmedCanvas().toDataURL("image/png");
      onChange(signatureDataUrl); // Call onChange with the data URL
    }
  }, [onChange]);

  return (
    <div>
      <div className={`border rounded-4 mb-3 ${className}`} style={{ width, height }}>
        {!disabled ? (
          <SignatureCanvas
            ref={signatureRef}
            penColor="black"
            canvasProps={{
              className: "signatureCanvas",
              style: { width, height },
            }}
            onEnd={handleEndSignature}
          />
        ) : (
          <div className="text-center">
            <img src={dataUrl} alt="Signature" style={{ width, height, opacity: 0.7 }} />
          </div>
        )}
      </div>
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
      <div style={{ width }} className="d-flex justify-content-between">
        <Button
          disabled={disabled}
          className={`btn delete-btn px-3 ${clearBtnClass}`}
          style={{ backgroundColor: 'var(--color-primary)', color: 'var(--color-white)' }}
          handleClick={clearSignature}
        >
          {t("Clear")}
        </Button>
        <Button
          disabled={disabled}
          className={`btn form-button float-end px-3 ${saveBtnClass}`}
          style={{ backgroundColor: 'var(--color-primary)', color: 'var(--color-white)' }}
          handleClick={handleSaveSignature}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
};

export default SignatureField;
