import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { OptionProps } from "../../components/common/utlis/TypeAnnotations";
// import { DateInfoProps } from '../../components/common/layout/Header';

// Interface for a company
interface Company {
    id: number | null;
    name: string;
    official_name: string;
    profile_picture_url: string;
    locations: Locations[];
}

// Interface for the authentication state
interface Locations {
    address_id: number,
    id: number,
    location_image_id: number,
    name: string
}
interface AuthState {
    token: string;
    email: string;
    isAuthenticated: boolean;
    refreshToken: string | undefined;
    userId: number | null;
    role: string;
    name: string;
    userPermissions: any;
    isSuperAdmin: boolean;
    profilePicName: string;
    candidateId: number | null;
    profilePicId: number | null;
    profilePicPath: string;
    lastLogin: Date | null;
    // dateInfo: DateInfoProps;
    office: string;
    officeId: number | null;
    companies: Array<Company> | null; 
    currentCompany: Company; //
    primaryOfficeID: number | null
}

// Initial date used for default dateInfo
const initialDate = new Date();

// Initial state for the authentication slice
const initialState: AuthState = {
    token: "",
    email: "",
    isAuthenticated: false,
    refreshToken: "",
    userId: null,
    role: "",
    name: "",
    userPermissions: [],
    isSuperAdmin: false,
    profilePicName: "",
    candidateId: null,
    profilePicId: null,
    profilePicPath: "",
    lastLogin: null,
    companies: [], 
    currentCompany: { id: null, name: "", official_name:"",profile_picture_url: "" ,locations:[]},
    // dateInfo: {
    //     type: 'month',
    //     value: initialDate.getMonth() + 1, // Month value (1-12)
    //     label: initialDate.toLocaleString('default', { month: 'long' }) + " " + initialDate.getFullYear(), // e.g., "August 2024"
    //     year: initialDate.getFullYear() // Year value
    // },
    office: "",
    officeId: null,
    primaryOfficeID: null
}

// Creating the authentication slice
const AuthSlice = createSlice({
    name: 'auth', // Name of the slice
    initialState, // Initial state for the slice
    reducers: { // Reducer functions
        // Reducer to add user details
        addUserDetails: (state, action: PayloadAction<object>) => {
            return { ...state, ...action.payload }; // Merge the current state with the payload
        },
        // Reducer to remove user details and reset to initial state
        removeUserDetails: (state, action: PayloadAction<void>) => {
            return initialState; // Reset state to initial state
        },
        // Reducer to add user profile picture (currently empty implementation)
        addUserProfilePic: (state, action: PayloadAction<string>) => {
            // Implementation can be added here
        },
        // Reducer to update or add a company
        updateOrAddCompany: (state, action: PayloadAction<Company>) => {
            const existingIndex = state.companies?.findIndex(company => company.id === action.payload.id);
            if (existingIndex !== undefined && existingIndex >= 0) {
                // Update existing company
                if (state.companies) {
                    state.companies[existingIndex] = action.payload;
                }
            } else {
                // Add new company
                if (state.companies) {
                    state.companies.push(action.payload);
                } else {
                    state.companies = [action.payload];
                }
            }
        },
        // Reducer to set the companies list
        setCompanies: (state, action: PayloadAction<Company[]>) => {
            state.companies = action.payload;
        },
    }
});

// Exporting the actions created by the slice
export const { addUserDetails, removeUserDetails, updateOrAddCompany, setCompanies } = AuthSlice.actions;

// Selector to access the authentication state
export const selectAuth = (state: RootState) => state.auth;
export const selectCurrentCompany = (state: RootState) => state.auth.currentCompany;
export const selectCurrentLocations = (state: RootState) => state.auth.currentCompany?.locations;



// Exporting the reducer function created by the slice
export default AuthSlice.reducer;
