import "./css/login.css";
import React, { useState } from "react";
import Icon from "../../../../Icon";
import LoadImage from "../../../../static/images/LoginImage.png";
import InputTextFieldWithLabel from "../../../common/molecules/InputTextFieldWithLabel";
import Button from "../../../common/atoms/Button";
import IconWithTitle from "../../../common/molecules/IconWithTitle";
import { t } from "../../../CentralDataMangement/translation/Translation";
import CustomNotify from "../../../common/atoms/CustomNotify";
import { ApiCall } from "../../../../services/ApiServices";
import { FORGOT_PASSWORD } from "../../../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../../Constants";
import { useNavigate } from "react-router-dom";

const ForgotPassword: React.FC = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState<string>('');

	const items = [
		{ name: "Time", title: "SAVE TIME" },
		{ name: "pathway", title: "FLEXIBLE" },
		{ name: "protection", title: "WEEKLY PAY" },
		{ name: "timeSlot", title: "24/7 CONTRACTING" },
		{ name: "security", title: "SAFETY" },
		{ name: "shakeHands", title: "SECURITY" },
		{ name: "Headset", title: "ANSWERS TO ALL YOUR QUESTIONS" },
		{ name: "editAgreement", title: "CONTRACTING TOOL" },
	];

	const handleSubmit = async () => {
		if (email) {
			const updatedState = {
				email: email.toLowerCase()
			};
			const response = await ApiCall?.service(
				FORGOT_PASSWORD,
				"POST",
				updatedState,
				true,
				CENTRAL_DATA_MANAGEMENT_MICROSERVICE
			);

			if (response.status === 'success') {
				navigate('/login');
				CustomNotify({
					type: 'success',
					message: t(response?.message)
				})
			} else {
				let message = ''
				if (response.status === 'validation-error') {
					message = Object.values(response.data).join(", ")
				} else {
					message = response.message
				}

				CustomNotify({
					type: 'error',
					message: message
				})
			}
		} else {
			CustomNotify({
				type: 'error',
				message: t('Please fill the required field')
			});
		}
	}

	return (
		<div className="container-fluid">
			<div className="loginWrapper">
				<div className="row gx-5">
					<div className="col-4">
						<div className="loginLeftSection text-center h-100 position-relative">
							<div className="loginLeftSectionContent text-white">
								{t("Completely")}
								<span className="colorYellow ms-1">{t("digital")},</span>
								<br />
								{t("but with a")}
								<br />
								{t("smile")}!
							</div>
							<div className="loginImageWrapper">
								<img src={LoadImage} alt={t("login")} className="img-fluid" />
							</div>
						</div>
					</div>
					<div className="col-4 loginMiddleSection">
						<div className="card border-0 loginCard h-100 rounded-0 d-grid align-content-center">
							<div className="loginCardPadding py-0">
								<div className="text-center">
									<Icon
										name="Logo"
										width="15vw"
										height="3vw"
										className="img-fluid mx-auto"
										isIcon={true}
									/>
								</div>
								<div className="loginMiddleSectionContent text-center">
									<div className="colorSkyBlue loginInText">
										{t("Forgot password")}
									</div>
									<div className="row">
										<InputTextFieldWithLabel
											placeholder={t("Email address")}
											type="email"
											value={email}
											name={"email"}
											id="emailId"
											className="mb-3 rounded-0"
											handleChange={(e) => setEmail(e.target.value)}
										/>
									</div>
									<div className="row">
										<div className="col-12">
											<Button
												type="submit"
												title={t("Submit")}
												className="signInBtn w-100"
												handleClick={handleSubmit}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-4">
						<div className="loginRightSection h-100">
							<div className="row">
								{items.map(item => (
									<div className="col-6 text-center" key={item?.name}>
										<IconWithTitle
											name={item?.name}
											isIcon={true}
											isTitle={true}
											width="10vw"
											height="2vw"
											className="img-fluid mx-auto"
											title={t(`${item?.title}`)}
											borderRadius={"10vw"}
											labelClassName="text-white loginRightSectionTitle"
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
