import React from "react";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import { Modal } from "react-bootstrap";
import Button from "../../common/atoms/Button";

function Popup(props) {
  return (
    <Modal
      show={true}
      onHide={props.popupActionNo}
      centered
      backdrop="static"
      className={`modalWithoutTabs customModal`}
    >
      <Modal.Header
        closeButton
        style={{ backgroundColor: "rgb(133, 113, 185)" }}
        className="p-0 text-white"
      >
        <Modal.Title className="text-center w-100 py-2 modal-title">
          {t("Delete category")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="border-0">
        {t("Are you sure you want to delete this category ? Functions related to this category will also be deleted.")}
      </Modal.Body>

      <Modal.Footer className="border-0 pt-0 pb-3">
        <Button className="resetBtn px-3" handleClick={props.popupActionNo}>
          {t("No")}
        </Button>
        <Button
          className="searchBtn px-3"
          handleClick={props.popupActionYes}
        >
          {t("Yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default translate(Popup);
