import React from 'react';
import LabelField from '../../common/atoms/LabelField';
import InputTextFieldWithLabel from '../../common/molecules/InputTextFieldWithLabel';
import SelectWithSearch from '../../common/atoms/SelectWithSearch';
import ToggleButton from '../../common/atoms/ToggleButton';
import { DefaultFormState, DropdownOptions } from '../services/CompanyInterface';

interface AddressFormProps {
    formState: DefaultFormState;
    handleAddressChange: (event: React.ChangeEvent<HTMLInputElement>, type: 'official' | 'invoice') => void;
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (selectedOption: DropdownOptions, name: string) => void;
    clearFieldError: (fieldName: string) => void;
    inputFieldSharedProps: any;
    t: (text: string) => string;
    isInvoiceAddress: boolean;
    setFormState: (newState: any) => void;
}

const AddressForm: React.FC<AddressFormProps> = ({
    formState,
    handleAddressChange,
    handleSelectChange,
    handleCheckboxChange,
    clearFieldError,
    inputFieldSharedProps,
    isInvoiceAddress,
    setFormState,
    t,
}) => {
    const handleCountryChange = (selectedOption: DropdownOptions, name: string) => {
        handleSelectChange(selectedOption, name);

        setFormState((prevState: DefaultFormState) => {
            const officialCountry = prevState?.formValues?.address?.official?.country;
            const isInvoiceCountrySameAsOfficial = selectedOption?.value === officialCountry;

            return {
                ...prevState,
                formValues: {
                    ...prevState?.formValues,
                    address: {
                        ...prevState?.formValues?.address,
                        invoice: {
                            ...prevState?.formValues?.address?.invoice,
                            country: isInvoiceAddress
                                ? selectedOption?.value
                                : prevState?.formValues?.address?.invoice?.country,
                        },
                    },
                    is_invoice_address: isInvoiceAddress && !isInvoiceCountrySameAsOfficial
                        ? false
                        : prevState?.formValues?.is_invoice_address,
                },

            };
        });
    };


    const handleToggle = () => {
        const mockEvent = {
            target: {
                checked: !isInvoiceAddress,
            },
        } as React.ChangeEvent<HTMLInputElement>;

        handleCheckboxChange(mockEvent);
    };

    return (
        <div>
            {/* Official Address Form */}
            <div>
                <div className="row">
                    <LabelField title={t('Address registered office:')} className="bodyTitle colorPrimary my-3" />
                </div>

                <div className="row mb-2" onClick={() => clearFieldError('address_official_street')}>
                    <InputTextFieldWithLabel
                        label={t("Street name") + ":"}
                        type="text"
                        value={formState?.formValues?.address?.official?.street || ''}
                        handleChange={(e) => handleAddressChange(e, 'official')}
                        error={formState?.errors?.[`address_official_street`]}
                        name="street"
                        id="officialStreetName"
                        autoComplete="off"
                        isMandatory
                        {...inputFieldSharedProps}
                    />
                </div>

                <div className="row mb-2" onClick={() => clearFieldError('address_official_number')}>
                    <InputTextFieldWithLabel
                        label={t("House no") + ":"}
                        type="text"
                        value={formState?.formValues?.address?.official?.number || ''}
                        handleChange={(e) => handleAddressChange(e, 'official')}
                        error={formState?.errors?.[`address_official_number`]}
                        name="number"
                        id="officialHouseNo"
                        autoComplete="off"
                        isMandatory
                        {...inputFieldSharedProps}
                    />
                </div>
                <div className="row mb-2" onClick={() => clearFieldError('address_official_box')}>
                    <InputTextFieldWithLabel
                        label={t("Box") + ":"}
                        type="text"
                        value={formState?.formValues?.address?.official?.box || ''}
                        handleChange={(e) => handleAddressChange(e, 'official')}
                        error={formState?.errors?.[`address_official_box`]}
                        name="box"
                        id="officialBox"
                        autoComplete="off"
                        {...inputFieldSharedProps}
                    />
                </div>

                <div className="row mb-2" onClick={() => clearFieldError('address_official_postal_code')}>
                    <InputTextFieldWithLabel
                        label={t("Postal code") + ":"}
                        type="text"
                        value={formState?.formValues?.address?.official?.postal_code || ''}
                        handleChange={(e) => handleAddressChange(e, 'official')}
                        error={formState?.errors?.[`address_official_postal_code`]}
                        name="postal_code"
                        id="officialPostalCode"
                        autoComplete="off"
                        isMandatory
                        {...inputFieldSharedProps}
                    />
                </div>

                <div className="row mb-2" onClick={() => clearFieldError('address_official_city')}>
                    <InputTextFieldWithLabel
                        label={t("City") + ":"}
                        type="text"
                        value={formState?.formValues?.address?.official?.city || ''}
                        handleChange={(e) => handleAddressChange(e, 'official')}
                        error={formState?.errors?.[`address_official_city`]}
                        name="city"
                        id="officialCity"
                        isMandatory
                        autoComplete="off"
                        {...inputFieldSharedProps}
                    />
                </div>

                <div className="row mb-2 multiSelectWrapper" onClick={() => clearFieldError('address_official_country')}>
                    <SelectWithSearch
                        fieldLabel={`${t("Country")}: `}
                        title={`${t("Country")}:`}
                        name="address.official.country"
                        placeHolder={t("Select")}
                        search
                        options={formState?.countryOptions || []}
                        value={formState?.countryOptions?.find(
                            (option: DropdownOptions) => option.value === formState?.formValues?.address?.official?.country
                        ) || null}
                        onChange={(selectedOption) => handleCountryChange(selectedOption, 'address.official.country')}
                        isMulti={false}
                        error={formState.errors?.[`address_official_country`]}
                        className="select-field"
                        isTranslate
                        isMandatory
                        labelWrapperClassName="col-3 align-self-center"
                        colClassName="col-7"
                    />
                </div>
            </div>

            <div className="col-12 d-flex align-items-center mt-4">
                <LabelField
                    title={t("Is the employment address the same as the registered office address") + "?"}
                    className="colorPrimary bodyTitle"
                />
                <div className='mx-2'>
                    <ToggleButton value={isInvoiceAddress} onToggle={handleToggle} />
                </div>
            </div>
            <div>
                <div className="row">
                    <LabelField title={t('Employment address:')} className="bodyTitle colorPrimary my-3" />
                </div>

                <div className="row mb-2" onClick={() => clearFieldError('address_invoice_street')}>
                    <InputTextFieldWithLabel
                        label={t("Street name") + ":"}
                        type="text"
                        value={formState?.formValues?.address?.invoice?.street || ''}
                        handleChange={(e) => handleAddressChange(e, 'invoice')}
                        error={formState?.errors?.[`address_invoice_street`]}
                        name="street"
                        id="invoiceStreetName"
                        autoComplete="off"
                        {...inputFieldSharedProps}
                    />
                </div>

                <div className="row mb-2" onClick={() => clearFieldError('address_invoice_number')}>
                    <InputTextFieldWithLabel
                        label={t("House no") + ":"}
                        type="text"
                        value={formState?.formValues?.address?.invoice?.number || ''}
                        handleChange={(e) => handleAddressChange(e, 'invoice')}
                        error={formState?.errors?.[`address_invoice_number`]}
                        name="number"
                        id="invoiceHouseNo"
                        autoComplete="off"
                        {...inputFieldSharedProps}
                    />
                </div>

                <div className="row mb-2" onClick={() => clearFieldError('address_invoice_box')}>
                    <InputTextFieldWithLabel
                        label={t("Box") + ":"}
                        type="text"
                        value={formState?.formValues?.address?.invoice?.box || ''}
                        handleChange={(e) => handleAddressChange(e, 'invoice')}
                        error={formState?.errors?.[`address_invoice_box`]}
                        name="box"
                        id="invoiceBox"
                        autoComplete="off"
                        {...inputFieldSharedProps}
                    />
                </div>

                <div className="row mb-2" onClick={() => clearFieldError('address_invoice_postal_code')}>
                    <InputTextFieldWithLabel
                        label={t("Postal code") + ":"}
                        type="text"
                        value={formState?.formValues?.address?.invoice?.postal_code || ''}
                        handleChange={(e) => handleAddressChange(e, 'invoice')}
                        error={formState?.errors?.[`address_invoice_postal_code`]}
                        name="postal_code"
                        id="invoicePostalCode"
                        autoComplete="off"
                        {...inputFieldSharedProps}
                    />
                </div>

                <div className="row mb-2" onClick={() => clearFieldError('address_invoice_city')}>
                    <InputTextFieldWithLabel
                        label={t("City") + ":"}
                        type="text"
                        value={formState?.formValues?.address?.invoice?.city || ''}
                        handleChange={(e) => handleAddressChange(e, 'invoice')}
                        error={formState?.errors?.[`address_invoice_city`]}
                        name="city"
                        id="invoiceCity"
                        autoComplete="off"
                        {...inputFieldSharedProps}
                    />
                </div>

                <div className="row mb-2 multiSelectWrapper" onClick={() => clearFieldError('address_invoice_country')}>
                    <SelectWithSearch
                        fieldLabel={`${t("Country")}: `}
                        title={`${t("Country")}:`}
                        name="address.invoice.country"
                        placeHolder={t("Select")}
                        search
                        options={formState?.countryOptions || []}
                        value={formState?.countryOptions?.find(
                            (option: DropdownOptions) => option.value === formState?.formValues?.address?.invoice?.country
                        ) || null}
                        onChange={(selectedOption) => handleCountryChange(selectedOption, 'address.invoice.country')}
                        isMulti={false}
                        error={formState.errors?.[`address_invoice_country`]}
                        className="select-field"
                        isTranslate
                        labelWrapperClassName="col-3 align-self-center"
                        colClassName="col-7"
                    />

                </div>
            </div>
        </div>
    );
};

export default AddressForm;
