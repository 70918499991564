import React, { useEffect, useState } from "react";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import Header from "../common/layout/Header";
import TableHeaderWithIcon from "../common/molecules/TableHeaderWithIcon";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import ShiftTiming from "../Planning/ShiftTiming";
import "./css/timeRegistration.css";
import userImage from "../../static/images/User.svg";
import OverFlowWrapper from "../common/OverFlowWrapper";
import DateAndTime from "../common/atoms/DateWithTime";
import { useLocation } from "react-router-dom";
import Icon from "../../Icon";
import PlanningEmployeeDetailsCard from "../common/molecules/PlanningEmployeeDetailsCard";
import { useSelector } from "react-redux";
import { selectCurrentLocations } from "../../features/auth/AuthSlice";
import { ApiCall } from "../../services/ApiServices";
import { ENCODAGE_CREATE, ENCODAGE_OVERVIEW } from "../../routes/ApiEndpoints";
import Button from "../common/atoms/Button";
import NoRecords from "../common/atoms/NoRecords";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";

const TimeRegistrationOverview = () => {
  const [activeTab, setActiveTab] = useState("home");
  const [openEmployees, setOpenEmployees] = useState<Employee[]>([]);
  const [closedEmployees, setClosedEmployees] = useState<Employee[]>([]);
  const [selectedShift, setSelectedShift] = useState<{ [employeeId: number]: string[] }>({});
  const [employeeErrors, setEmployeeErrors] = useState<{
    [employeeId: number]: string | null;
  }>({});

  interface EncodageItem {
    enId: number;
    start_time: string;
    end_time: string;
    total_count: string;
    total_break: string;
    sequence: string;
    encoded_by: number;
  }

  interface ScheduleItem {
    planned: Array<{
      ptId: number;
      start_time: string;
      end_time: string;
      total_count: string;
      total_break: string;
      sequence: number;
    }>;
    timeRegistration: Array<{
      tsId: number;
      start_time: string;
      end_time: string;
      total_count: string;
      total_break: string;
      sequence: string;
    }>;
    encodage: EncodageItem[];
  }

  interface Employee {
    employee_id: number;
    employee_name: string;
    employee_type_id: number;
    employee_type: string;
    pc: string;
    pc_id: number;
    location_id: number;
    location_name: string;
    function_id: number;
    function: string;
    salary: string;
    planning_id: number;
    planning_employee_detail_id: number;
    schedule: Record<string, ScheduleItem>;
    start_date: string;
    end_date: string;
  }

  const companyId = useSelector((state: any) => state.auth?.currentCompany?.id);
  const locations = useSelector(selectCurrentLocations) ?? [];

  // console.log(locations);

  // for locations selection
  const initialCurrentIndex = 0;
  const [currentIndex, setCurrentIndex] = useState(initialCurrentIndex);

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex < locations.length - 1 ? prevIndex + 1 : prevIndex;
    });
  };
  const handlePreviousClick = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex > 0 ? prevIndex - 1 : prevIndex;
    });
  };

  // for week selection
  const getStartAndEndDate = (date: Date) => {
    const dayOfWeek = date.getDay();
    const startOfWeek = new Date(
      date.getTime() - dayOfWeek * 24 * 60 * 60 * 1000
    );
    const endOfWeek = new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000);

    return { startOfWeek, endOfWeek };
  };

  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
    const { startOfWeek, endOfWeek } = getStartAndEndDate(newDate);
    setStartDate(startOfWeek.toLocaleDateString("en-CA")); // YYYY-MM-DD
    setEndDate(endOfWeek.toLocaleDateString("en-CA")); // YYYY-MM-DD
  };

  const handleEncodageChange = (
    employeeId: number,
    date: string,
    updatedEncodage: EncodageItem[]
  ) => {
    setOpenEmployees((prevEmployees) =>
      prevEmployees.map((employee) =>
        employee.employee_id === employeeId
          ? {
            ...employee,
            schedule: {
              ...employee.schedule,
              [date]: {
                ...employee.schedule[date],
                encodage: updatedEncodage, // Update encodage with the new data
              },
            },
          }
          : employee
      )
    );
  };

  const toggleShiftSelection = (employeeId: number, shiftDate: string): void => {
    setSelectedShift((prev) => {
      const employeeShifts = prev[employeeId] || [];
      const isSelected = employeeShifts.includes(shiftDate);

      return {
        ...prev,
        [employeeId]: isSelected
          ? employeeShifts.filter((date) => date !== shiftDate)
          : [...employeeShifts, shiftDate],
      };
    });
  };



  const fetchEmployeeData = async (validated: number) => {
    try {
      let dataObj = {
        company_id: companyId,
        location_id: locations[currentIndex]?.id,
        // "company_id": 1,
        // "location_id": 2,
        start_date: startDate,
        end_date: endDate,
        validated: validated,
      };

      if (
        dataObj["start_date"] !== "" &&
        dataObj["end_date"] !== "" &&
        dataObj["company_id"] != null &&
        dataObj["location_id"] != null
      ) {
        let result = await ApiCall.service(
          ENCODAGE_OVERVIEW,
          "POST",
          dataObj,
          true
        );
        console.log(result);

        if (result.status === "success") {
          const employeesData: Employee[] = result.data; // Explicitly typing employeesData
          const formattedEmployees = employeesData.map((employee) => ({
            employee_id: employee.employee_id,
            employee_name: employee.employee_name,
            employee_type_id: employee.employee_type_id,
            employee_type: employee.employee_type,
            pc: employee.pc,
            pc_id: employee.pc_id,
            location_id: employee.location_id,
            location_name: employee.location_name,
            function_id: employee.function_id,
            function: employee.function,
            salary: employee.salary,
            image: userImage,
            planning_id: employee.planning_id,
            planning_employee_detail_id: employee.planning_employee_detail_id,
            schedule: employee.schedule,
            start_date: employee.start_date,
            end_date: employee.end_date,
          }));

          validated === 0
            ? setOpenEmployees(formattedEmployees)
            : setClosedEmployees(formattedEmployees);

          console.log(openEmployees, "res");

        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch open data
  const fetchOpenData = async () => {
    await fetchEmployeeData(0);
  };

  // Fetch closed data
  const fetchClosedData = async () => {
    await fetchEmployeeData(1);
  };

  const postData = async (data: Employee) => {
    // Implement your API call here
    console.log("Posting data:", data);

    try {
      let result = await ApiCall.service(
        ENCODAGE_CREATE,
        "POST",
        { data: [data] },
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      console.log(result, "res for post");
      if (result.status === 'success') {
        fetchOpenData();
        fetchClosedData();
        // window.location.reload()
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSend = (employee: Employee) => {
    const employeeSelectedShifts = selectedShift[employee.employee_id] || [];

    if (employeeSelectedShifts.length === 0) {
      setEmployeeErrors((prevErrors) => ({
        ...prevErrors,
        [employee.employee_id]: t("Please select at least one shift."),
      }));
      return;
    }

    // Clear error for the employee if they have selected shifts
    setEmployeeErrors((prevErrors) => ({
      ...prevErrors,
      [employee.employee_id]: null,
    }));

    const filteredSchedule: Record<string, ScheduleItem> = Object.keys(
      employee.schedule
    )
      .filter((date) => employeeSelectedShifts.includes(date))
      .reduce((acc, date) => {
        acc[date] = employee.schedule[date];
        return acc;
      }, {} as Record<string, ScheduleItem>);

    if (Object.keys(filteredSchedule).length > 0) {
      const filteredData: Employee = {
        ...employee,
        schedule: filteredSchedule,
      };

      postData(filteredData);
      // Reset selected shifts for the specific employee
      setSelectedShift((prevSelectedShift) => ({
        ...prevSelectedShift,
        [employee.employee_id]: [], // Clear selected shifts for this employee
      }));

    }
  };


  useEffect(() => {
    const { startOfWeek, endOfWeek } = getStartAndEndDate(date);
    setStartDate(startOfWeek.toLocaleDateString("en-CA")); // YYYY-MM-DD
    setEndDate(endOfWeek.toLocaleDateString("en-CA")); // YYYY-MM-DD
  }, [date]);
  // console.log(date, startDate, endDate, "dates");

  useEffect(() => {
    fetchOpenData();
    fetchClosedData();
  }, [companyId, currentIndex, startDate, endDate]);

  return (
    <>
      <Header
        headerName={t("Time registration per employee")}
        headerClassName="myAccountCardTitle"
      />
      <TableHeaderWithIcon
        onNextClick={handleNextClick}
        onPreviousClick={handlePreviousClick}
        title={locations[currentIndex]?.name || t("No Location Name")}
        TableHeaderWithIconClassName="plannedLocationHeader px-3"
        TableHeaderTitle="poppins-semibold text-white"
      />
      <div className="contentBg rounded-top-0 p-3">
        <div className="row mb-3">
          <div className="col-4 align-self-center">
            <ul className="nav nav-pills timeRegistrationTabs" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link ${activeTab === "home" ? "active" : ""}`}
                  data-bs-toggle="pill"
                  href="#home"
                  onClick={() => setActiveTab("home")}
                >
                  {t("Open")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeTab === "menu1" ? "active" : ""
                    }`}
                  data-bs-toggle="pill"
                  href="#menu1"
                  onClick={() => setActiveTab("menu1")}
                >
                  {t("Closed")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-4 align-self-center text-center">
            <DateAndTime
              isWeek={true}
              isIcon={true}
              monthFormat="long"
              dataTimeWrapperClassName="d-inline-flex align-items-center justify-content-center timeRegistrationDateWrapper"
              dataTimeClassName="mx-3 planningPlannedDate"
              dayClassName="me-1"
              isWeekDate={true}
              iconFillColor="#8571b9"
              onDateChange={handleDateChange}
            />
          </div>
          <div className="col-4 align-self-center">
            {activeTab === "menu1" && (
              <div className="me-3 totalValidatedHours">
                {t("Total validated hours") + ":"}
              </div>
            )}
            {/* <span
              onClick={() => { }}
              title={t("Print")}
              className="secondaryColorHoverEffect float-end"
            >
              <Icon isIcon={true} width="1vw" height="1vw" name="printIcon" />
            </span> */}
            {/* <span
              onClick={() => { }}
              title={t("Search")}
              className="secondaryColorHoverEffect me-3 float-end"
            >
              <Icon isIcon={true} width="1vw" height="1vw" name="searchIcon" />
            </span> */}
          </div>
        </div>
        {/* Tab panes */}
        <div className="tab-content">
          <div
            id="home"
            className={`tab-pane ${activeTab === "home" ? "active" : ""}`}
          >
            <OverFlowWrapper className="timeRegistrationPerEmployee container-fluid px-0">
              {openEmployees && openEmployees.length > 0 ? (
                openEmployees.map((employee) => (
                  <div className="d-flex mb-3" key={employee.employee_id} style={{paddingLeft:"0.2vw"}}>
                    <div className="align-self-center" style={{ width: "9%" }}>
                      <PlanningEmployeeDetailsCard
                        cardClassName="employeeDetailsCardPlanning colorPrimary text-break text-start d-flex align-items-center justify-content-center flex-column text-center"
                        cardMainClassName="timeRegistrationWrapper position-relative"
                        employeeInformationClass="planningEmployeeDetails timeRegistrationEmployeeCard w-100"
                        employeeName={employee.employee_name}
                        employeeEmpType={employee.employee_type}
                        employeeLocation={employee.location_name}
                        employeeFunction={employee.function}
                        employeePC={employee.pc}
                        employeeSalary={employee.salary}
                      />
                    </div>
                    <div
                      className="align-self-center ms-auto"
                      style={{ width: "89%" }}
                    >
                      {Object.entries(employee.schedule).map(
                        ([date, scheduleData], index) => {

                          return (
                            <ShiftTiming
                              key={index}
                              showLastAction={true}
                              isTimeRegistration={true}
                              category="open"
                              schedule={scheduleData}
                              date={date}
                              shiftIndex={index}
                              selectedDates={selectedShift[employee.employee_id] || []} // Pass the specific employee's selected shifts
                              onEncodageChange={(
                                updatedEncodage: EncodageItem[]
                              ) =>
                                handleEncodageChange(
                                  employee.employee_id,
                                  date,
                                  updatedEncodage
                                )
                              }
                              onCheckboxChange={() => toggleShiftSelection(employee.employee_id, date)}
                              activeEmployee={employee} // Pass the current employee as activeEmployee


                            />
                          );
                        }
                      )}
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12 text-end">
                            <Button
                              handleClick={() => handleSend(employee)}
                              className="searchBtn"
                              title={t("Send")}
                            />
                          </div>
                        </div>
                      </div>
                      {employeeErrors[employee.employee_id] && (
                        <div className="error-message">
                          {employeeErrors[employee.employee_id]}
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <NoRecords message={t("No open encodage for this week")} isDiv={true} className="pt-5" />
              )}
            </OverFlowWrapper>
          </div>
          <div
            id="menu1"
            className={`tab-pane ${activeTab === "menu1" ? "active" : ""}`}
          >
            <OverFlowWrapper className="timeRegistrationPerEmployee">
              {closedEmployees && closedEmployees.length > 0 ? (
                closedEmployees.map((employee) => (
                  <div className="d-flex mb-3" key={employee.employee_id}>
                    <div className="align-self-center" style={{ width: "9%" }}>
                      <PlanningEmployeeDetailsCard
                        cardClassName="employeeDetailsCardPlanning colorPrimary text-break text-start d-flex align-items-center justify-content-center flex-column text-center"
                        cardMainClassName="timeRegistrationWrapper position-relative"
                        employeeInformationClass="planningEmployeeDetails timeRegistrationEmployeeCard w-100"
                        employeeName={employee.employee_name}
                        employeeEmpType={employee.employee_type}
                        employeeLocation={employee.location_name}
                        employeeFunction={employee.function}
                        employeePC={employee.pc}
                        employeeSalary={employee.salary}
                      // employeeImage={employee.image}
                      />
                    </div>
                    <div
                      className="align-self-center ms-auto"
                      style={{ width: "89%" }}
                    >
                      {Object.entries(employee.schedule).map(
                        ([date, scheduleData], index) => (
                          <ShiftTiming
                            key={index}
                            showLastAction={true}
                            isTimeRegistration={true}
                            category="closed"
                            schedule={scheduleData}
                            date={date}
                            shiftIndex={index}
                          />
                        )
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <NoRecords isDiv={true} message={t("No closed encodage for this week")} className="pt-5" />

              )}
            </OverFlowWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(TimeRegistrationOverview);
