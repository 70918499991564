import React, { ChangeEvent, useRef, useEffect, useState } from "react";
import { t } from "../../../common/atoms/translation";
import LabelField from "../../../common/atoms/LabelField";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";
import SelectWithSearch from "../../../common/atoms/SelectWithSearch";
import CheckBoxField from "../../../common/atoms/CheckBoxField";
import Image from "../../../common/atoms/Image";
import defaultCompanyIcon from "../../../../static/images/emptyCompany.png";
import Button from "../../../common/atoms/Button";
import { ApiCall } from "../../../../services/ApiServices";
import {
  GET_EMPLOYEE_TYPES_BY_PC,
  GET_FUNCTIONS_BY_PC,
  OPTIONS_LIST_BY_GROUP_OF_COMPANIES,
  PC_LIST_BY_COMPANY_ID,
} from "../../../../routes/ApiEndpoints";
import { useSelector } from "react-redux";
import { DefaultEmpLegal } from "../Interfaces";
import { selectAuth } from "../../../../features/auth/AuthSlice";
import Icon from "../../../../Icon";
import { defaultEmpLegalDefaults } from "../State";
import { getEmployeePreviousSalaryByEmployeeType } from "../../../Planning/Services/PlanningSalaryHelper";

type OptionType = {
  value: string | number;
  label: string;
};
interface FunctionOptionType {
  label: string;
  value: number;
  function_id?: number;
  function_name?: string;
  salary?: string;
}

interface EmployeeSalaryInfoProps {
  user_id?: number;
  companies: number[];
  default_emp_legal: DefaultEmpLegal[];
  errors: any;
  updatePcValues: (updatedState: any) => void;
  handleSelectChange: (
    selectedOption: OptionType,
    fieldPath: string,
    index?: number
  ) => void;
  missing_companies: number[];
}
const EmployeeSalaryInfo: React.FC<EmployeeSalaryInfoProps> = ({
  user_id = 0,
  companies,
  default_emp_legal,
  errors,
  handleSelectChange,
  updatePcValues,
  missing_companies,
}) => {
  const [checkedItems, setCheckedItems] = useState<Set<number>>(new Set()); // For Checkbox's selected items
  const [pcOptions, setPCOptions] = useState<OptionType[][]>([]); // For Paritaire Commitee options
  const [fuctionsOptions, setFuctionsOptions] = useState<
    FunctionOptionType[][]
  >([]); // For Function options
  const [employeeTypeOptions, setEmployeeTypeOptions] = useState<
    OptionType[][]
  >([]); // For Employee Type options
  const [companyData, setCompanyData] = useState<{ companies: any[] }>({
    companies: [],
  }); // For Showing Companies for Checkbox's
  const [selectedFunctionIds, setSelectedFunctionIds] = useState<Set<number>>(
    new Set()
  ); // For Selected Function Ids to remove from selected functions
  const [companiesOptions, setCompaniesOptions] = useState<OptionType[]>([]); // For Desired Company Options

  const user = useSelector(selectAuth);
  // For Setting companies data from redux
  useEffect(() => {
    setCompanyData((prevState) => ({
      ...prevState,
      companies: user?.companies,
    }));
  }, [user?.companies]);

  // Used while ------ Edit Employee ------
  const fetchPcInfogroupBycompany = async () => {
    const body = {
      companies: companies,
    };
    try {
      const response = await ApiCall.service(
        OPTIONS_LIST_BY_GROUP_OF_COMPANIES,
        "POST",
        body
      );
      if (response.status === "success") {
        const { pcs, functions, employee_types } = response?.data;

        // Map over the state to set the options
        const updatedPCOptions = default_emp_legal.map((item) => {
          return item?.company_id ? pcs[item.company_id] || [] : [];
        });

        const updatedFunctionsOptions = default_emp_legal.map((item) => {
          return item?.paritaire_commitee_id
            ? functions[item.paritaire_commitee_id] || []
            : [];
        });

        const updatedEmployeeTypeOptions = default_emp_legal.map((item) => {
          return item?.paritaire_commitee_id
            ? employee_types[item.paritaire_commitee_id] || []
            : [];
        });
        console.log(updatedEmployeeTypeOptions);

        setPCOptions(updatedPCOptions);
        setFuctionsOptions(updatedFunctionsOptions);
        setEmployeeTypeOptions(updatedEmployeeTypeOptions);
      }
    } catch (error) {
      console.error("Error fetching Pc Info group By company:", error);
    }
  };

  // Used while ------ Edit Employee ------
  useEffect(() => {
    if (user_id !== 0 && !isNaN(user_id)) {
      fetchPcInfogroupBycompany();
    }
  }, [user_id]);

  const removeFunctionId = (index: number) => {
    // Update the selectedFunctionIds state to remove the function ID for the deleted item
    setSelectedFunctionIds((prevSelected) => {
      const updatedSet = new Set(prevSelected);
      const removedFunctionId = default_emp_legal[index]?.function_id;

      // Remove the function ID if it exists in the set
      if (removedFunctionId) {
        updatedSet.delete(removedFunctionId);
      }

      return updatedSet;
    });
  };

  const removeFunctionEmployeeTypeOptions = (index: number) => {
    setFuctionsOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = [];
      return newOptions;
    });
    setEmployeeTypeOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = [];
      return newOptions;
    });
  };

  // Fetching PC options by company ID
  const fetchPCOptions = async (index: number, selectedCompanyId: number) => {
    if (selectedCompanyId !== 0) {
      try {
        const result = await ApiCall.getService(
          `${PC_LIST_BY_COMPANY_ID}/${selectedCompanyId}`,
          "GET"
        );
        setPCOptions((prevOptions) => {
          const newOptions = [...prevOptions];
          newOptions[index] = result?.data;
          return newOptions;
        });
      } catch (error) {
        console.error("Error fetching PC options:", error);
      }
    } else {
      // If company is not selected, reset the PC options and make company id 0
      updatePcValues({
        companies: companies,
        errors: errors,
        default_emp_legal: default_emp_legal.map((item, i) =>
          i === index
            ? {
                ...item,
                id: null,
                company_id: 0,
              }
            : item
        ),
        missing_companies: missing_companies,
      });
      setPCOptions((prevOptions) => {
        const newOptions = [...prevOptions];
        newOptions[index] = [];
        return newOptions;
      });
    }
  };

  // Handler for PC change
  const CompanyHandler = async (index: number, selectedCompanyId: number) => {
    fetchPCOptions(index, selectedCompanyId);
    // If company is changed, reset the value and options of Functions and Employee-types
    updatePcValues({
      companies: companies,
      errors: errors,
      default_emp_legal: default_emp_legal.map((item, i) =>
        i === index
          ? {
              ...item,
              id: null,
              paritaire_commitee_id: null,
              employee_type_id: null,
              function_id: null,
              minWage: "",
              actual_salary: "",
              is_actual_added: false,
            }
          : item
      ),
      missing_companies: missing_companies,
    });
    removeFunctionEmployeeTypeOptions(index);
    removeFunctionId(index);
  };

  // Featching Functions and Employee-types by PC ID
  const fetchOptions = async (index: number, selectedPcId: number) => {
    if (selectedPcId !== 0) {
      try {
        const functionsResult = await ApiCall.getService(
          `${GET_FUNCTIONS_BY_PC}/${selectedPcId}`,
          "GET"
        );
        const employeeTypesResult = await ApiCall.getService(
          `${GET_EMPLOYEE_TYPES_BY_PC}/${selectedPcId}`,
          "GET"
        );
        setFuctionsOptions((prevOptions) => {
          const newOptions = [...prevOptions];
          newOptions[index] = functionsResult?.data?.functions;
          return newOptions;
        });

        setEmployeeTypeOptions((prevOptions) => {
          const newOptions = [...prevOptions];
          newOptions[index] = employeeTypesResult?.data?.employee_types;
          return newOptions;
        });
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    } else {
      removeFunctionEmployeeTypeOptions(index);
    }
    removeFunctionId(index);
    // If any change happen pc id then clear the function and employee type and set the min wage to default value
    updatePcValues({
      companies: companies,
      errors: errors,
      default_emp_legal: default_emp_legal.map((item, i) =>
        i === index
          ? {
              ...item,
              employee_type_id: null,
              function_id: null,
              minWage: "",
              actual_salary: "",
              is_actual_added: false,
            }
          : item
      ),
      missing_companies: missing_companies,
    });
  };

  const handleFunctionChange = async (
    index: number,
    fieldPath: string,
    selectedOption: FunctionOptionType | null
  ) => {
    let minWage = "";

    // Check if all parameters are not null or undefined
    if (
      default_emp_legal[index].paritaire_commitee_id != null &&
      default_emp_legal[index].company_id != null &&
      selectedOption?.value != null &&
      default_emp_legal[index].employee_type_id != null
    ) {
      // Await the resolved value of the promise
      minWage = await getEmployeePreviousSalaryByEmployeeType(
        default_emp_legal[index].paritaire_commitee_id,
        default_emp_legal[index].company_id,
        selectedOption?.value,
        default_emp_legal[index].employee_type_id,
        user_id ? user_id : -999
      );
    }

    // Update `default_emp_legal` with the new minWage
    const updatedEmpLegal = default_emp_legal.map((item: any, i: number) => {
      if (i === index) {
        return {
          ...item,
          [fieldPath]: selectedOption ? selectedOption.value : null,
          minWage: minWage, // Set the resolved minWage here
          actual_salary: "",
          is_actual_added: false,
        };
      }
      return item;
    });
    setSelectedFunctionIds((prevSelected) => {
      const updatedSet = new Set(prevSelected);
      const previousValue = default_emp_legal[index]?.function_id;
      if (previousValue) {
        updatedSet.delete(previousValue); // Remove previous value if it existed
      }
      if (selectedOption) {
        updatedSet.add(selectedOption.value); // Add the new selected value if it exists
      }
      return updatedSet;
    });
    updatePcValues({
      companies: companies,
      errors: errors,
      default_emp_legal: updatedEmpLegal,
      missing_companies: missing_companies,
    });
  };

  // For handling company checkboxes while ------Edit Employee---- filling Checkboxes
  useEffect(() => {
    if (Array.isArray(companies) && companyData?.companies) {
      // Initialize checked items as a Set from companies
      const initialCheckedItems = new Set(companies);
      setCheckedItems(initialCheckedItems);

      // Filter and map company data to create the initial companiesOptions
      const initialCompaniesOptions = companyData.companies
        .filter((company) => initialCheckedItems.has(company.id)) // Use Set for O(1) lookup
        .map((company) => ({
          label: company.name,
          value: company.id,
        }));
      setCompaniesOptions(initialCompaniesOptions);
    }
  }, [companyData, companies]);

  const handleCheckBoxClick = (company: any, isChecked: boolean) => {
    setCheckedItems((prev) => {
      const updated = new Set(prev);
      if (isChecked) {
        updated.add(company.id); // Add to checked items
      } else {
        updated.delete(company.id); // Remove from checked items
      }
      return updated;
    });

    // if(missing_companies && missing_companies.length > 0){

    // }
    // Call handleCompanyChange directly to update `companies` and `companiesOptions`
    handleCompanyChange(company, isChecked);
  };

  const handleCompanyChange = (company: any, isChecked: boolean) => {
    const updatedCompanies = isChecked
      ? [...companies, company.id]
      : companies.filter((id) => id !== company.id);

    const updatedMissedCompanies = isChecked
      ? [...missing_companies] // If checked, keep the existing list unchanged
      : missing_companies.filter((id) => id !== company.id); // If unchecked, remove the company ID

    const updatedCompaniesOptions = isChecked
      ? [...companiesOptions, { label: company.name, value: company.id }]
      : companiesOptions?.filter((option) => option.value !== company.id);

    let updatedDefaultEmpLegal;
    let index = -1;
    if (
      isChecked &&
      default_emp_legal.length > 0 &&
      default_emp_legal[default_emp_legal.length - 1].company_id === null
    ) {
      // Update the last index with the selected company_id if it's null
      updatedDefaultEmpLegal = [
        ...default_emp_legal.slice(0, default_emp_legal.length - 1),
        {
          ...default_emp_legal[default_emp_legal.length - 1],
          id: null,
          company_id: company.id,
        },
      ];
      index = default_emp_legal.length - 1;
    } else if (isChecked) {
      // Add a new entry for the selected company
      updatedDefaultEmpLegal = [
        ...default_emp_legal,
        {
          id: null,
          company_id: company.id,
          paritaire_commitee_id: null,
          employee_type_id: null,
          function_id: null,
          minWage: "",
          actual_salary: "",
          is_actual_added: false,
        },
      ];
      index = updatedDefaultEmpLegal.length - 1;
    } else if (!isChecked) {
      // Collect indexes of entries in default_emp_legal with the matching company_id
      const indexesToRemove = default_emp_legal
        .map((entry, index) => (entry.company_id === company.id ? index : -1))
        .filter((index) => index !== -1);

      // Clear errors for each index to remove and update errors state
      indexesToRemove.forEach((index) => {
        errors = clearOneIndexErrors(index, errors);
      });
      updatedDefaultEmpLegal = default_emp_legal;
    }

    // Check if an index is valid for fetchPCOptions and call the function
    if (index !== -1) {
      fetchPCOptions(index, company.id);
    }

    // Update state with new values
    setCompaniesOptions(updatedCompaniesOptions);
    updatePcValues({
      companies: updatedCompanies,
      errors: errors,
      default_emp_legal: updatedDefaultEmpLegal,
      missing_companies: updatedMissedCompanies,
    });
  };

  // Used to Remopve errors related to the removed entry
  const removeDefaultEmpLegalErrors = (indexToRemove: number) => {
    const updatedErrors = Object.keys(errors).reduce((acc, key) => {
      // Check if the error key starts with `default_emp_legal.[indexToRemove]`
      const pattern = `default_emp_legal.${indexToRemove}.`;
      if (!key.startsWith(pattern)) {
        acc[key] = errors[key]; // Keep errors that don't match the pattern
      }
      return acc;
    }, {} as Record<string, string>);

    // Update the errors state
    setErrors(updatedErrors);
  };

  // Filtering selected companies id in default_emp_legal array of objects
  const validateDefaultEmpLegal = () => {
    const indicesToRemove: number[] = [];
    let updatedData = default_emp_legal.filter((entry, index) => {
      const isValid =
        entry.company_id === null || companies.includes(entry.company_id);

      // If the entry is invalid, mark the index for removal
      if (!isValid) {
        indicesToRemove.push(index);
      }

      return isValid; // Only keep valid entries
    });
    if (updatedData.length === 0) {
      updatedData = [
        {
          ...defaultEmpLegalDefaults,
          id: null,
          company_id: null, // Explicitly set company_id to null
        },
      ];
    }
    // Remove errors for each invalid index
    indicesToRemove.forEach((index) => removeDefaultEmpLegalErrors(index));

    const allPCOptions = [...pcOptions]; // Use the state variable directly
    const allFunctionsOptions = [...fuctionsOptions]; // Use the state variable directly
    const allEmployeeTypeOptions = [...employeeTypeOptions]; // Use the state variable directly

    // Filter options based on indices to remove
    const updatedPCOptions = allPCOptions.filter(
      (_, i) => !indicesToRemove.includes(i)
    );
    const updatedFunctionsOptions = allFunctionsOptions.filter(
      (_, i) => !indicesToRemove.includes(i)
    );
    const updatedEmployeeTypeOptions = allEmployeeTypeOptions.filter(
      (_, i) => !indicesToRemove.includes(i)
    );

    // Update options arrays in the state
    setPCOptions(updatedPCOptions);
    setFuctionsOptions(updatedFunctionsOptions);
    setEmployeeTypeOptions(updatedEmployeeTypeOptions);

    const updatedSelectedFunctionIds = new Set<number>(
      updatedData
        .map((entry) => entry.function_id) // Get function IDs
        .filter((id): id is number => id !== undefined && id !== null) // Filter out undefined and null
    );

    setSelectedFunctionIds(updatedSelectedFunctionIds);

    updatePcValues({
      companies: companies,
      errors: errors,
      default_emp_legal: updatedData,
      missing_companies: missing_companies,
    });
  };

  useEffect(() => {
    validateDefaultEmpLegal();
  }, [companies]);

  const validateFields = () => {
    const updatedErrors: Record<string, string | undefined> = { ...errors };
    let isValid = true;

    // Iterate through each entry in default_emp_legal
    default_emp_legal.forEach((entry, index) => {
      const companyIdError = entry.company_id
        ? undefined
        : "This field is required";
      const paritaireCommiteeIdError = entry.paritaire_commitee_id
        ? undefined
        : "This field is required";
      const employeeTypeIdError = entry.employee_type_id
        ? undefined
        : "This field is required";
      const functionIdError = entry.function_id
        ? undefined
        : "This field is required";
      const minWageError =
        entry.minWage?.trim() !== "" || null ? undefined : "This field is required";

      updatedErrors[`default_emp_legal.${index}.company_id`] = companyIdError;
      updatedErrors[`default_emp_legal.${index}.paritaire_commitee_id`] =
        paritaireCommiteeIdError;
      updatedErrors[`default_emp_legal.${index}.employee_type_id`] =
        employeeTypeIdError;
      updatedErrors[`default_emp_legal.${index}.function_id`] = functionIdError;
      updatedErrors[`default_emp_legal.${index}.minWage`] = minWageError;
      // Remove fields with no errors
      Object.keys(updatedErrors).forEach((key) => {
        if (updatedErrors[key] === undefined) {
          delete updatedErrors[key];
        }
      });
      setErrors(updatedErrors);
      // Update isValid flag based on the errors
      if (
        companyIdError ||
        paritaireCommiteeIdError ||
        employeeTypeIdError ||
        functionIdError ||
        minWageError
      ) {
        isValid = false; // At least one field is invalid
      }
    });
    return isValid;
  };

  const handleEmployeeTypeChangeForClearFunction = (index: number) => {
    // Create a copy of `default_emp_legal` to avoid mutating the original array
    const updatedEmpLegal = [...default_emp_legal];

    // Clear `function_id` at the specified index
    if (updatedEmpLegal[index]) {
      updatedEmpLegal[index].function_id = null;
      updatedEmpLegal[index].minWage = "";
      updatedEmpLegal[index].actual_salary = "";
      updatedEmpLegal[index].is_actual_added = false;
    }
    updatePcValues({
      companies: companies,
      default_emp_legal: updatedEmpLegal, // Pass the modified array here
      errors: errors,
      missing_companies: missing_companies,
    });
  };

  const setErrors = (updatedErrors: Record<string, string | undefined>) => {
    // Update state with all errors at once
    updatePcValues({
      companies: companies,
      default_emp_legal: default_emp_legal,
      errors: updatedErrors,
      missing_companies: missing_companies,
    });
  };

  // Used to clear Options, Section for a specific index
  const clearingOptions = (index: number) => {
    setPCOptions((prevOptions) => prevOptions.filter((_, i) => i !== index));
    removeFunctionId(index);
    setFuctionsOptions((prevOptions) =>
      prevOptions.filter((_, i) => i !== index)
    );
    setEmployeeTypeOptions((prevOptions) =>
      prevOptions.filter((_, i) => i !== index)
    );
  };

  const clearOneIndexErrors = (
    index: number,
    errorsState: Record<string, string | undefined>
  ) => {
    const updatedErrors = Object.keys(errorsState).reduce((acc, key) => {
      if (key.startsWith(`default_emp_legal.`)) {
        const newIndex = Number(key.split(".")[1]);

        if (newIndex < index) {
          // Keep the keys that are less than the specified index
          (acc as any)[key] = errorsState[key];
        } else if (newIndex > index) {
          // Adjust the keys that are greater than the specified index
          const newKey = `default_emp_legal.${newIndex - 1}`;
          (acc as any)[newKey] = errorsState[key];
        }
      } else {
        // Keep keys that don't start with `default_emp_legal.`
        (acc as any)[key] = errorsState[key];
      }

      return acc;
    }, {});
    return updatedErrors;
  };

  // Used for the add field button
  const handleAddField = () => {
    const isValid = validateFields();
    if (isValid || default_emp_legal.length === 0) {
      const newIndex = default_emp_legal.length;
      // Add a new entry to the default_emp_legal array in state
      updatePcValues({
        companies: companies,
        errors: errors,
        default_emp_legal: [
          ...default_emp_legal.slice(0, newIndex),
          {
            id: null,
            company_id: null,
            paritaire_commitee_id: null,
            employee_type_id: null,
            function_id: null,
            minWage: "",
            actual_salary: "",
            is_actual_added: false,
          },
          ...default_emp_legal.slice(newIndex),
        ],
        missing_companies: missing_companies,
      });
    }
  };

  // Used for the remove button
  const handleRemoveField = (index: number) => {
    clearingOptions(index);
    const updatedEmpLegal = default_emp_legal.filter(
      (_: any, i: number) => i !== index
    );

    updatePcValues({
      companies: companies,
      default_emp_legal: updatedEmpLegal,
      errors: clearOneIndexErrors(index, errors),
      missing_companies: missing_companies,
    });
  };

  const handleSalaryChange = (value: string, index: number) => {
    const defaultEmpLegalDefaults: DefaultEmpLegal = {
      id: null,
      company_id: null,
      paritaire_commitee_id: 0,
      employee_type_id: 0,
      function_id: 0,
      minWage: "",
      actual_salary: "",
      is_actual_added: false,
    };

    // Check if updating 'default_emp_legal' array
    if (index !== undefined) {
      const updatedArray = [...default_emp_legal]; // Shallow copy the array

      // Ensure the object at the specified index is initialized with default values
      const item = updatedArray[index] || defaultEmpLegalDefaults;
      updatedArray[index] = {
        ...item,
        actual_salary: value,
      };

      updatePcValues({
        companies: companies,
        errors: errors,
        default_emp_legal: updatedArray,
        missing_companies: missing_companies,
      });
    }
  };

  //Used for filtering functions by unique combination
  const filterFunctionsByUniqueCombination = (
    index: number,
    functionOptions: FunctionOptionType[]
  ) => {
    const currentItem = default_emp_legal[index];
    const { company_id, paritaire_commitee_id, employee_type_id } = currentItem;

    return functionOptions.filter((option) => {
      const isAlreadySelectedForCombination = default_emp_legal.some(
        (item, idx) =>
          idx !== index &&
          item.company_id === company_id &&
          item.paritaire_commitee_id === paritaire_commitee_id &&
          item.employee_type_id === employee_type_id &&
          item.function_id === option.value
      );
      return !isAlreadySelectedForCombination;
    });
  };

  // Use ref to access the scrollable container
  const scrollRef = useRef<HTMLDivElement>(null);

  // Function to handle scroll (left or right)
  const handleScroll = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const scrollAmount = direction === "right" ? 200 : -200;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 mb-3">
          <LabelField
            title={t("To which company would you like to add this employee")}
            className="tab-title"
            isMandatory
          />
          {errors?.companies && (
            <div className="text-danger"> {errors?.companies}</div>
          )}
        </div>
        <div className="d-flex align-items-center">
          {companyData?.companies.length > 7 && (
            <div
              style={{ width: "3%" }}
              className="nextIconWrapper"
              onClick={() => handleScroll("left")}
            >
              <Icon
                name="backIcon"
                isIcon={true}
                width={"1.7vw"}
                height={"1.7vw"}
                fillColor="#8571b9"
              />
            </div>
          )}
          <div style={{ width: "94%" }}>
            <div
              ref={scrollRef}
              className="mx-auto d-flex p-2 pb-3 scrollBar"
              style={{
                overflowX: "auto",
                scrollbarWidth: "none", // For Firefox
                gap: "0.5vw",
              }}
            >
              {companyData?.companies?.map((company) => (
                <div
                  key={company?.id}
                  className={`checkBoxContainer text-break text-center ${
                    checkedItems.has(company.id)
                      ? missing_companies?.includes(company?.id)
                        ? "missedCompanies"
                        : "selectedCompanies"
                      : ""
                  }  cursor-pointer me-2`}
                  onClick={() =>
                    handleCheckBoxClick(company, !checkedItems.has(company.id))
                  }
                >
                  <CheckBoxField
                    name="addemployee"
                    value="0"
                    id={`company-${company.id}`}
                    lineHeight="25px"
                    className="checkBoxLabel"
                    checkBoxWrapper="addEmployeeToCompany text-center p-2"
                    wrapperClass="d-inline-flex"
                    isChildren
                    isChecked={checkedItems.has(company.id)}
                    onChangeHandler={(event) =>
                      handleCheckBoxClick(company, event.target.checked)
                    }
                  >
                    <div title={company?.name}>
                      <div style={{ height: "3vw" }}>
                        <Image
                          src={
                            company?.profile_picture_url ?? defaultCompanyIcon
                          }
                          imageWidth="3vw"
                          imageHeight="3vw"
                          imageBorderRadius="50%"
                        />
                      </div>
                      <div style={{ lineHeight: "1.5vw" }} className="mt-2">
                        <LabelField
                          title={company?.name}
                          className="cursor-pointer"
                        />
                      </div>
                    </div>
                  </CheckBoxField>
                </div>
              ))}
            </div>
          </div>
          {companyData?.companies.length > 7 && (
            <div
              style={{ width: "3%" }}
              className="text-end nextIconWrapper"
              onClick={() => handleScroll("right")}
            >
              <Icon
                name="nextIcon"
                isIcon={true}
                width={"1.7vw"}
                height={"1.7vw"}
                fillColor="#8571b9"
              />
            </div>
          )}
        </div>
        <div className="col-12">
          <LabelField
            title={t("Function & hourly wage")}
            className="tab-title"
          />
        </div>
        {errors?.default_emp_legal && (
          <span className="text-danger">{errors?.default_emp_legal}</span>
        )}
      </div>
      <div>
        {default_emp_legal.map((field, index) => (
          <div key={index} className="border rounded-3 mb-3 p-3">
            <div className="row position-relative">
              {default_emp_legal.length !== 1 && (
                <div className="col-12 text-end mb-3">
                  <span
                    onClick={() => handleRemoveField(index)}
                    title={t("Remove")}
                    className="secondaryColorHoverEffect"
                  >
                    <Icon
                      name="close"
                      isIcon={true}
                      width="0.8vw"
                      height="0.8vw"
                    />
                  </span>
                </div>
              )}
              <div className="col-4 mb-3">
                <div className="row multiSelectWrapper">
                  <SelectWithSearch
                    fieldLabel={t("Desired company") + ":"}
                    title={t("Desired company") + ":"}
                    name="company_id"
                    placeHolder="Select"
                    isMandatory
                    search
                    options={companiesOptions || []} // Pass the options here
                    value={
                      companiesOptions?.find(
                        (option) =>
                          option.value === default_emp_legal[index]?.company_id
                      ) || null
                    }
                    onChange={(selectedOption: any) => {
                      CompanyHandler(index, Number(selectedOption.value));
                      handleSelectChange(
                        selectedOption,
                        `default_emp_legal.${index}.company_id`,
                        index
                      );
                    }}
                    error={
                      errors[`default_emp_legal.${index}.company_id`] ?? ""
                    }
                    isTranslate
                    labelWrapperClassName="col-4"
                    colClassName="col-8"
                    className="leftlabelClass"
                  />
                </div>
              </div>

              <div className="col-4 mb-3">
                <div className="row multiSelectWrapper">
                  <SelectWithSearch
                    fieldLabel={t("Paritair committee") + ":"}
                    title={t("Paritair committee") + ":"}
                    name="paritaire_commitee_id"
                    placeHolder="Select"
                    isMandatory
                    search
                    options={pcOptions[index] || []} // Pass the options here
                    value={
                      (pcOptions[index] || []).find(
                        (option: OptionType) =>
                          option.value ===
                          default_emp_legal[index]?.paritaire_commitee_id
                      ) || null
                    }
                    onChange={(selectedOption: OptionType) => {
                      if (selectedOption.value !== undefined) {
                        fetchOptions(index, Number(selectedOption.value));
                      }
                      handleSelectChange(
                        selectedOption,
                        `default_emp_legal.${index}.paritaire_commitee_id`,
                        index
                      );
                    }}
                    error={
                      errors[
                        `default_emp_legal.${index}.paritaire_commitee_id`
                      ] || null
                    }
                    isTranslate
                    labelWrapperClassName="col-4"
                    colClassName="col-8"
                    className="leftlabelClass"
                  />
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="row multiSelectWrapper">
                  <SelectWithSearch
                    fieldLabel={t("Employee type") + ":"}
                    title={t("Employee type") + ":"}
                    name="employee_type_id"
                    placeHolder="Select"
                    isMandatory
                    search
                    options={(employeeTypeOptions[index] || []).sort(
                      (a: OptionType, b: OptionType) =>
                        a.label.localeCompare(b.label)
                    )}
                    value={
                      (employeeTypeOptions[index] || []).find(
                        (option: OptionType) =>
                          option.value ===
                          default_emp_legal[index]?.employee_type_id
                      ) || null
                    }
                    onChange={(selectedOption: OptionType) => {
                      handleEmployeeTypeChangeForClearFunction(index);
                      handleSelectChange(
                        selectedOption,
                        `default_emp_legal.${index}.employee_type_id` // Correct dynamic field path
                      );
                    }}
                    isMulti={false}
                    error={
                      errors[`default_emp_legal.${index}.employee_type_id`] ||
                      ""
                    }
                    isTranslate
                    labelWrapperClassName="col-4"
                    colClassName="col-8"
                    className="leftlabelClass"
                  />
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="row multiSelectWrapper">
                  <SelectWithSearch
                    fieldLabel={t("Function") + ":"}
                    title={t("Function") + ":"}
                    name="function_id"
                    placeHolder="Select"
                    isMandatory
                    search
                    options={
                      default_emp_legal[index]?.employee_type_id
                        ? filterFunctionsByUniqueCombination(
                            index,
                            fuctionsOptions[index] || []
                          ).sort((a, b) => a.label.localeCompare(b.label))
                        : []
                    }
                    value={
                      (fuctionsOptions[index] || []).find(
                        (option: FunctionOptionType) =>
                          option.value === default_emp_legal[index]?.function_id
                      ) || null
                    }
                    onChange={(selectedOption: FunctionOptionType | null) => {
                      handleFunctionChange(
                        index,
                        "function_id",
                        selectedOption
                      );
                    }}
                    isTranslate
                    error={
                      errors[`default_emp_legal.${index}.function_id`] || ""
                    }
                    labelWrapperClassName="col-4"
                    colClassName="col-8"
                    className="leftlabelClass"
                  />
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="row">
                  <LabelWithInputField
                    label={t("Minimum wage") + ":"}
                    className="inputFieldColor ps-2"
                    colClassName="col-8"
                    type="text"
                    id={`minWage-${index}`}
                    value={default_emp_legal[index]?.minWage || ""} // Access the value from the array
                    name="minWage"
                    error={
                      errors[`default_emp_legal.${index}.minWage`] || ""
                    }
                    isDisabled={true}
                    labelWrapperClassName="col-4"
                    autoComplete="off"
                    isMandatory={true}
                  >
                    <span className="colorPrimary helpText">
                      *{t("If flexi excl holiday pay")}
                    </span>
                  </LabelWithInputField>
                </div>
              </div>
              <div className="col-4 mb-3">
                <div className="row">
                  <LabelWithInputField
                    label={t("Actual wage") + ":"}
                    className="inputFieldColor ps-2"
                    colClassName="col-8"
                    type="text"
                    id={`actual_salary-${index}`}
                    value={default_emp_legal[index]?.actual_salary || ""} // Access the value from the array
                    handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleSalaryChange(e.target?.value, index)
                    }
                    name="actual_salary"
                    error={
                      errors[`default_emp_legal.${index}.actual_salary`] || ""
                    }
                    isDisabled={
                      user_id !== 0 &&
                      default_emp_legal[index]?.id !== null &&
                      default_emp_legal[index]?.actual_salary.trim() !== ""
                        ? true
                        : false
                    }
                    labelWrapperClassName="col-4"
                    autoComplete="off"
                  >
                    <span className="colorPrimary helpText">
                      *{t("If flexi excl holiday pay")}
                    </span>
                  </LabelWithInputField>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="col-12 mb-3 text-center">
          <Button
            title={"+ Add another PC"}
            className="modalSaveBtn"
            handleClick={handleAddField}
          />
        </div>
      </div>
    </>
  );
};
export default EmployeeSalaryInfo;
