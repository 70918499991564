import React, { useEffect, useRef, useState } from "react";
import Header from "../../common/layout/Header";
import { t, translate } from "../translation/Translation";
import { InputText } from "primereact/inputtext";
import InputTextFieldWithLabel from "../../common/molecules/InputTextFieldWithLabel";
import FileUpload from "../../common/molecules/FileUpload";
import {
  LocationFormData,
  fileData,
  formatHouseNumber,
  formatPostalCode,
  formatStreet,
  inputFields,
  intialFileData,
} from "./LocationUtils";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import {
  FILE_URL,
  GET_COMPANIES_BY_UID,
  GET_COUNTRIES,
  LOCATIONS,
  SHIFTS,
} from "../../../routes/ApiEndpoints";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import CustomNotify from "../../common/atoms/CustomNotify";
import MultiSelectField from "../../common/atoms/MultiSelectField";
import BackLink from "../../common/atoms/BackLink";
import Button from "../../common/atoms/Button";
import "./location.css";
import Image from "../../common/atoms/Image";
import { icons } from "../../Dashboard/WelcomeDashboard";
import RadioField from "../../common/atoms/RadioField";
// import CompanyRadioButtons from "../../common/atoms/CompanyRadioButtons";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../features/auth/AuthSlice";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import LabelField from "../../common/atoms/LabelField";
import Icon from "../../../Icon";
import CompanySelectCard from "../../common/atoms/CompanyCards";
import defaultCompanyIcon from "../../../static/images/emptyCompany.png"


interface CompanyObj {
  value: number;
  label: string;
  // url: string;
}

const AddLocation: React.FC = () => {
  // const [locationName, setLocationName] = useState("");
  const user = useSelector(selectAuth).userId;

  const initialFormData: LocationFormData = {
    location_pic: "",
    location_name: "",
    street: "",
    number: "",
    postal_code: "",
    box: "",
    city: "",
    country: "",
    company_id: [],
  };

  interface CompanyObj {
    value: number;
    label: string;
    url: string;
  }

  const locationinputFields = inputFields;
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [fileDetails, setFileDetails] = useState<fileData>(intialFileData);
  const [formData, setFormData] = useState<LocationFormData>(initialFormData);
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [checkedItems, setCheckedItems] = useState<Set<number>>(new Set());
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [relatedCompanies, setRelatedCompanies] = useState<CompanyObj[]>([]);
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    getCountries();
    getCompanies();
  }, []);

  const getCountries = async () => {
    const response = await ApiCall.getService(
      `${GET_COUNTRIES}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status == 'success') {
      console.log(response.data);
      setCountries(response.data);
    }
  };

  const getCompanies = async () => {
    console.log(`${GET_COMPANIES_BY_UID}/${user}`);
    const response = await ApiCall.getService(
      `${GET_COMPANIES_BY_UID}/${user}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    console.log(response);
    if (response.status == 'success') {
      // Convert the data to match the CompanyObj interface
      const formattedCompanies = response.data.map((company: any) => ({
        value: company.id,
        label: company.name,
        url:
          company.profile_picture_url ??
          defaultCompanyIcon,
      }));
      setRelatedCompanies(formattedCompanies);
    }
  };

  // Handle input change for text fields
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
    fieldKey: keyof LocationFormData
  ) => {
    let value: any = "";
    switch (fieldKey) {
      case "country":
        setSelectedCountry(e);
        value = e?.value || "";
        break;
      case "postal_code":
        value = formatPostalCode(e.target.value);
        break;
      case "number":
        value = formatHouseNumber(e.target.value);
        break;
      case "street":
        value = formatStreet(e.target.value);
        break;
      case "company_id":
        if (e && e.target) {
          var ids = JSON.parse(e.target.value); // Extract the value from the event (radio button click)
        } else {
          var ids = e; // Use value directly if passed explicitly (e.g., from onClick)
        }
        value = ids ? [ids] : [];
        break;
      default:
        value = e.target.value;
        break;
    }

    setFormData((prevData) => ({
      ...prevData,
      [fieldKey]: value,
    }));

    // Clear the error for the updated field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldKey]: value ? "" : prevErrors[fieldKey], // Remove the error if value is not empty
    }));
  };

  console.log(formData);

  const handleFileUploadResponse = (uploadResponse: any, index: number) => {
    console.log(uploadResponse);

    if (uploadResponse?.response) {
      setFileDetails({
        fileId: uploadResponse.response.data.fileId,
        fileName: uploadResponse.response.data.fileName,
        filePath: uploadResponse.response.data.filePath,
        fileUrl: uploadResponse.response.data.fileUrl,
      });
      // const fileUrl = uploadResponse.response["file_url"];
      setFormData((prevData) => ({
        ...prevData,
        location_pic: uploadResponse.response.data.fileId || "",
      }));
    }
    else {
      CustomNotify({
        type: "error",
        message: t(uploadResponse),
      });
    }
    // console.log(uploadResponse.response.data.fileId);

  };

  const handleFileDeleteResponse = (deleteResponse: any, index: number) => {
    console.log(deleteResponse);
    setFileDetails({
      fileId: 0,
      fileName: "",
      filePath: "",
      fileUrl: ""
    });
    setFormData((prevData) => ({
      ...prevData,
      location_pic: "",
    }));
  };

  const handleCompanySelect = (companyId: number | null) => {
    handleInputChange({ target: { value: companyId } }, 'company_id');
  };

  const handleRadioClick = (index: number, companyId: number) => {
    if (selectedIndex === index) {
      // Reset selection
      setSelectedIndex(-1);
      setSelectedCompanies([]);
      // handleInputChange([], "company_id");
    } else {
      setSelectedIndex(index);
      setSelectedCompanies([companyId]);
      // handleInputChange([companyId], "company_id");
    }
  };

  const validateForm = () => {
    let errors: Record<string, string> = {};
    // Loop through the form fields to check for validation
    locationinputFields.forEach((field) => {
      if (field.isMandatory && !formData[field.name]) {
        console.log(!formData[field.name]);
        errors[field.name] = `${t(field.label)} is required`;
      }
    });

    return errors;
  };

  // Handle form submit (with validation)
  const handleSubmit = async (e: React.FormEvent) => {
    console.log(formData);
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Stop the form submission if there are errors
    }
    try {
      let data = {
        data: {
          formData,
        },
      };
      const response = await ApiCall.service(
        LOCATIONS,
        "POST",
        formData,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == 'success') {
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
        setFormData(initialFormData);
        setFileDetails(intialFileData);
        setTimeout(() => {
          navigate("/manage-location");
        }, 1000);
      } else if (response.status == 'validation-error' || response.status === 'error') {
        const errorData = response.data; // Assuming the error data comes in response.data
        let updatedErrors: Record<string, string> = {};

        // Iterate through the error object and set errors for each field
        Object.keys(errorData).forEach((field) => {
          updatedErrors[field] = errorData[field][0]; // Assign the first error message
        });

        // Update form errors state
        setFormErrors(updatedErrors);

        CustomNotify({
          type: "error",
          message: t(response.message) || t(response.msg),
        });
      }
    } catch (error) {
      console.error("Error saving locations:", error);
    }
  };

  // Use ref to access the scrollable container
  const scrollRef = useRef<HTMLDivElement>(null);

  // Function to handle scroll (left or right)
  const handleScroll = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const scrollAmount = direction === "right" ? 200 : -200;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };
  return (
    <>
      <Header
        headerName={t("Add location")}
        headerClassName="myAccountCardTitle"
      />

      <div className="search-bar pb-1">
        <div className="row">
          <BackLink backLink="/manage-location" />
        </div>
      </div>
      <div className="createpagesBg">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div
              className={`col-lg-6 position-relative mb-3 ${fileDetails.filePath
                ? "locationImageUploaded"
                : "locationImageUpload"
                }`}
            >
              <div style={{ width: "fit-content", position: "relative" }}>
                <FileUpload
                  id="locations"
                  placeholder={t("Upload file")}
                  name="location_picture"
                  file_type={10}
                  isMandatory={false}
                  edit={false}
                  label={t("Upload")}
                  fileId={fileDetails.fileId}
                  fileName={fileDetails.fileName}
                  filePath={fileDetails.filePath}
                  fileUrl={fileDetails.fileUrl}
                  multiple={false}
                  uploadPath="locations"
                  formats=".jpg,.jpeg,.png,.gif"
                  maxFileSize={2}
                  returnFileUploadErrors={(errorInfo) => {
                    // Handle file upload errors
                    console.error('File upload error:', errorInfo)
                  }}
                  fileUploadResponse={handleFileUploadResponse}
                  deleteResponse={handleFileDeleteResponse}
                />
                {fileDetails.fileUrl ? (
                  <div>
                    <img
                      src={
                        fileDetails.fileUrl
                      }
                      alt={t("Uploaded")}
                      style={{
                        backgroundColor: "var(--color-white)",
                        borderRadius: "50%",
                        boxShadow:
                          "rgb(133, 113, 185) 0.052vw 0vw 0.32vw 0.02vw",
                        width: "5vw",
                        height: "5vw",
                        objectFit: "cover",
                      }}
                      width="50%"
                      height="50%"
                    />
                  </div>
                ) : null}
              </div>
            </div>
            {locationinputFields.map((field, index) =>
              field.type === "select" ? (
                <div className="col-lg-4 col-md-6 multiSelectWrapper">
                  <SelectWithSearch
                    key={index}
                    search={true}
                    options={countries}
                    value={selectedCountry}
                    onChange={(e) => handleInputChange(e, field.name)}
                    placeHolder={field.placeholder}
                    isMulti={false}
                    className="country-select"
                    isTranslate={false}
                    isMandatory={field.isMandatory}
                    fieldLabel={t("Country")}
                    title={t("Country")}
                    error={formErrors[field.name]}
                  />
                </div>
              ) : (
                <div className={`${field.columnSize} mb-3`}>
                  <InputTextFieldWithLabel
                    key={index}
                    type="text"
                    placeholder={t(field.placeholder)}
                    label={t(field.label)}
                    value={formData[field.name]}
                    name={field.name}
                    id={field.id}
                    handleChange={(e) =>
                      handleInputChange(e, field.name as keyof LocationFormData)
                    }
                    isMandatory={field.isMandatory}
                    error={formErrors[field.name]}
                    isTop={true}
                    className="ps-2"
                  />
                </div>
              )
            )}
            <LabelField
              title={t("To which company would you like to add this location:")}
              className="mb-2"
            />

            {/* Map your data items here */}
            <CompanySelectCard relatedCompanies={relatedCompanies} onSelect={handleCompanySelect} />

          </div>
          <div className="row py-4">
            <div className="col-12">
              <Button
                type="submit"
                className="searchBtn float-end px-3"
                title={t("Save location")}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default translate(AddLocation);
