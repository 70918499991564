import React, { useEffect, useState } from "react";
import DateAndTime from "../common/atoms/DateWithTime";
import HeaderWIthFilter from "./HeaderWIthFilter";
import PlanningTable from "./PlanningTable";
import WeeklyCalendar from "./WeeklyCalendar";
import { useSelector } from "react-redux";
import { selectPlanningShow } from "../../features/planning/planningSlice";
import PlanningTabs from "./PlanningTabs";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useParams, useSearchParams } from "react-router-dom";

const PlanningOverviewNew = () => {
    const localizer = momentLocalizer(moment);
    const show = useSelector(selectPlanningShow);
    const [search] = useSearchParams();
    let type = search.get('type') ?? "day";
    const [view, setView] = useState(type); // 'day', 'week', or 'month'
    const [date, setDate] = useState(new Date());
    useEffect(() => {
        setView(type)
    }, [search])

    const handleDateChange = (newDate: Date) => {
        setDate(newDate);
    };
    useEffect(() => {

    },[date])

    const renderView = () => {
        switch (view) {
            case "day":
                return <PlanningTable selectedDate={date} />;
            case "week":
                return <WeeklyCalendar startDate={date} />;
            case "month":
                const events: any = []; // Define your events array
                return (
                    <Calendar
                        localizer={localizer}
                        defaultDate={new Date()}
                        defaultView="month"
                        views={["month"]}
                        events={events}
                        style={{ height: "100vh" }}
                        toolbar={false}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="container-fluid">
            <HeaderWIthFilter headerName={t(`Planning - ${view.charAt(0).toUpperCase() + view.slice(1)}`)} type={view}>
                <DateAndTime
                    isDate={view === "day"}
                    isWeek={view === "week"}
                    isMonth={view === "month"}
                    isIcon={true}
                    monthFormat="long"
                    dataTimeWrapperClassName="d-flex align-items-center colorSkyBlue bg-white planningDateWrapper"
                    dataTimeClassName="mx-3 planningPlannedDate"
                    dayClassName="me-1"
                    iconFillColor="#8571b9"
                    onDateChange={handleDateChange}
                    showDate={true}
                    showMonth={true}
                    showYear={true}
                    isDay={true}
                />
            </HeaderWIthFilter>
            <div className="row">
                <div className="col-12">
                    {renderView()}
                </div>
            </div>
            {show && <PlanningTabs />}
        </div>
    );
};

export default translate(PlanningOverviewNew);
