import React, { useEffect, useState, useCallback } from "react";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import Header from "../common/layout/Header";
import BackLink from "../common/atoms/BackLink";
import { MANAGE_ALL_TRADES } from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import Icon from "../../Icon";
import { Link } from "react-router-dom";
import EditIcon from "./EditIcon";
import NoRecords from "../common/atoms/NoRecords";
import LabelField from "../common/atoms/LabelField";

// Define a type for Trade
interface Trade {
  id: number;
  name: string;
  start_date: string; // Assuming start_date is a string
  end_date: string; // Assuming end_date is a string
}

const ManageFares: React.FC = () => {
  const [mappedTrades, setMappedTrades] = useState<Trade[]>([]);
  const [newTradeLabel, setNewTradeLabel] = useState("");

  useEffect(() => {
    fetchTradeOptions();
  }, []);

  const fetchTradeOptions = useCallback(async () => {
    try {
      const result = await ApiCall?.getService(MANAGE_ALL_TRADES, "GET");
      setMappedTrades(result.data?.data ?? []);
    } catch (error) {
      console.error("Error fetching trade data:", error);
    }
  }, []);

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTradeLabel(event.target.value);
  };

  return (
    <div className="fare-form-container">
      <Header
        headerName={t("")}
        headerClassName="myAccountCardTitle"
      />

      <div className="search-bar pb-3">
        <div className="row">
          <BackLink backLink="/add-fare" />
          <div className="col-12 align-self-center text-center">
            <LabelField
              title={t("Manage trades")}
              className="myAccountCardTitle mb-0"
            />
          </div>
        </div>
      </div>

      <div className="managepagesBg mb-3">
        <table className="table table-hover pwa">
          <thead>
            <tr className="TableHeader">
              <th>{t("Fare name")}</th>
              <th>{t("Start date")}</th>
              <th>{t("End date")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {mappedTrades.length > 0 ? (
              mappedTrades.map((trade) => (
                <tr key={trade.id}>
                  <td>{trade.name}</td>
                  <td>{trade.start_date}</td>
                  <td>{trade.end_date}</td>
                  <td className="ActionTd">
                    <div className="d-none d-lg-block">
                      <Link to={`/add-fare/${trade.id}`} title={t("Edit")}>
                        <EditIcon />
                      </Link>
                      <span
                        className="actionWrapper cursor-pointer me-2"
                        title={t("Link")}
                      >
                        <Link to={`/follow-up/?tradeId=${trade.id}`} title={t("Link")}>
                          <span className="actionWrapper cursor-pointer me-2" title={t("Link")}>
                            <Icon isIcon={true} width="1vw" height="1vw" name="linkIcon" />
                          </span>
                        </Link>
                      </span>
                    </div>
                    <div className="d-block d-lg-none text-center">
                      <Link
                        to={`/add-fare/${trade.id}`}
                        title={t("Edit")}
                        className="w-75 d-block mx-auto mb-3"
                      >
                        <span className="searchBtn w-100 d-flex align-items-center justify-content-center">
                          {t("Edit")}
                        </span>
                      </Link>
                      <span className="searchBtn w-75 d-flex align-items-center justify-content-center mx-auto">
                        {t("Link")}
                      </span>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <NoRecords headerLength={4} message={t("No trades available")} />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default translate(ManageFares);
