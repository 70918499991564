import React, { useCallback, useEffect, useState } from "react";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { useNavigate } from "react-router-dom";

import Header from "../common/layout/Header";
import BackLink from "../common/atoms/BackLink";
import { MANAGE_FOLLOW_UPS } from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import NoRecords from "../common/atoms/NoRecords";
import LabelField from "../common/atoms/LabelField";
import IconWithTitle from "../common/molecules/IconWithTitle";
import Link from "../common/atoms/Link";
import Button from "../common/atoms/Button";
import Icon from "../../Icon";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/auth/AuthSlice";


interface FollowUpData {
  contact_person?: {
    first_name?: string;
    last_name?: string;
  };
  company_details?: {
    vat_number?: string;
    search_name?: string;
    official_name?: string;
    city?: string;
  };
  follow_up?: {
    date_next?: string;
    time_next?: string;
    user_next?: {
      first_name?: string;
    };
  };
}

const ManageFollowUps: React.FC = () => {
  const [mappedTrades, setMappedTrades] = useState<FollowUpData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const rowsPerPage = 10;
	const authData = useSelector(selectAuth);
  const userId = authData.userId;
  const userRole = authData.role[0];


  const followUps = useCallback(async (page: number = 1) => {
    try {
      const result = await ApiCall?.getService(`${MANAGE_FOLLOW_UPS}?page=${page}&userId=${userId}&userRole=${userRole}`, "GET");
      console.log("API Data:", result);
      setMappedTrades(result.data?.data ?? []);
      setLastPage(result.data?.last_page ?? 1); // Set the total number of pages
    } catch (error) {
      console.error("Error fetching trade data:", error);
    }
  }, []);

  useEffect(() => {
    followUps(currentPage); // Fetch data for the current page
  }, [followUps, currentPage]);

  // Handler for pagination
  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= lastPage) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="fare-form-container">
      <Header headerClassName="myAccountCardTitle" headerName={t("Manage follow up")}
      />
      <div className="search-bar pb-3">
        <div className="row">
          <BackLink backLink="/dashboard/follow-up" />
          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField
              title={t("Manage follow ups")}
              className="myAccountCardTitle mb-0"
            />
          </div>

          <div className="col-lg-2 col-12 ms-auto align-self-center text-end">
            <Link href="/follow-up" className="searchBtn d-inline-flex align-items-center justify-content-center text-decoration-none w-auto px-3">
              {t("Add follow up")}
            </Link>
          </div>
        </div>
      </div>

      <div className="managepagesBg">
        <table className="table table-hover customTableProperties pwa">
          <thead>
            <tr className="TableHeader">
              <th>{t("VAT number")}</th>
              <th>{t("Search name")}</th>
              <th>{t("Official name")}</th>
              <th>{t("City")}</th>
              <th>{t("Contact person name")}</th>
              <th>{t("Follow up date and time")}</th>
              <th>{t("Follow up user")}</th>
            </tr>
          </thead>
          <tbody>
            {mappedTrades.length > 0 ? (
              // Filter out rows without a VAT number before mapping
              mappedTrades
                .filter((trade) => trade.company_details?.vat_number)
                .map((trade, index) => (
                  <tr key={index}>
                    <td data-label={t("VAT number")}>{trade.company_details?.vat_number}</td>
                    <td data-label={t("Search name")}>{trade.company_details?.search_name}</td>
                    <td data-label={t("Official name")}>{trade.company_details?.official_name}</td>
                    <td data-label={t("City")}>{trade.company_details?.city}</td>
                    <td data-label={t("Contact person name")}>{`${trade.contact_person?.first_name} ${trade.contact_person?.last_name}`}</td>
                    <td data-label={t("Follow up date and time")}>{`${trade.follow_up?.date_next} ${trade.follow_up?.time_next}`}</td>
                    <td data-label={t("Follow up user")}>{trade.follow_up?.user_next?.first_name}</td>
                  </tr>
                ))
            ) : (
              <NoRecords headerLength={5} message={t("No data is available")} />
            )}
          </tbody>
        </table>


        {/* Pagination Controls */}
        <div className="pagination-controls text-center d-flex align-items-center justify-content-center pb-5 pb-lg-0">
          <Button
            handleClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`${currentPage === 1 ? "disabledIcon" : "backIconWrapper"} border-0 bg-transparent`}>
            <Icon isIcon={true} width="1.5vw" height="1.5vw" name="backIcon" />
          </Button>
          <div className="d-inline-flex align-items-center mx-lg-3 mx-5 poppins-medium">
            <span className="mobileFont">{`${t("Page ")}`}</span>
            <span className="colorPrimary ms-2 mobileFont">{currentPage}</span>
            <span className="mobileFont ms-2"> {`${t(" of ")}`}</span>
            <span className="ms-2 mobileFont">{lastPage}</span>

          </div>
          <Button
            handleClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === lastPage}
            className={`${currentPage === lastPage ? "disabledIcon" : "backIconWrapper"} border-0 bg-transparent`}
          >
            <Icon isIcon={true} width="1.5vw" height="1.5vw" name="nextIcon" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default translate(ManageFollowUps);
