import React from "react";
import Icon from "../../../Icon";
import { t } from "../../CentralDataMangement/translation/Translation";
import { useNavigate } from "react-router-dom";

interface Props {
  backLink?: string | (() => void); // Accept both string and function
}

const BackLink: React.FC<Props> = ({ backLink }) => {
  const navigate = useNavigate();

  return (
    <div className="col-2 mb-lg-2">
      <div className="pagebackIcon cursor-pointer" title={t("Back")}  onClick={() => navigate(`${backLink}`)}>
        <span>
          <Icon isIcon={true} width="1vw" height="1vw" name="longLeftArrow" />
        </span>          
      </div>
    </div>
  );
};

export default BackLink;
