import { FLEX_MAX_PERCENTAGE } from "../../../Constants";
import { t } from "../../CentralDataMangement/translation/Translation";
import { updatesalaryFormat } from "../Services/PlanningSalaryHelper";

export const newSalaryWarning = () => {
  return t("We notice that you have added a salary which is higher than the minimum salary and therefore this new salary will be considered as the minimum salary for all the future planning for this employee for the selected function. You can click on next to proceed further.");
}
export const lesserSalaryWarning = (maxSalary: number | string): string => {
  return t("The new salary cannot be lesser than the minimum salary. The minimum salary for the selected function is") + " " +
    updatesalaryFormat(maxSalary) + " Euros";
}
export const flexSalaryWarning = (): string => {
  return `The salary cannot exceed ${FLEX_MAX_PERCENTAGE}% of the function salary.`;
}