import { generateDateRange, formatDate } from '../../common/utlis/dateHelper';

interface Shifts {
    start_time: string;
    end_time: string;
    sequence: number;
}

interface Schedule {
    location_id: number;
    project_id?: number;
    cost_center?: number;
    shifts: Shifts[];
    error?: string;
    warning?: string[];
}

interface ScheduleMap {
    [date: string]: Schedule;
}

export const generateSchedule = (selectedDates: string[], locationId: number): ScheduleMap => {
    const initialSchedule: ScheduleMap = {};
    const defaultShift = {
        location_id: locationId,
        shifts: [{
            start_time: "", // First shift start time
            end_time: "",   // First shift end time
            sequence: 1,
        }],
        error: "",
        warning: []
    };

    if (selectedDates.length === 2) {
        const [startDate, endDate] = selectedDates;
        const datesInRange = generateDateRange(startDate, endDate);

        datesInRange.forEach((date: string) => {
            initialSchedule[formatDate(date)] = defaultShift;
        });
    } else {
        selectedDates.forEach((date: string) => {
            initialSchedule[formatDate(date)] = defaultShift;
        });
    }

    return initialSchedule;
};

export type Status = "FAILED" | "SUCCESS" | "IN_QUEUE";

interface StatusResult {
    color: string;
    title: string;
}

export function getStatusColorAndTitle(status: Status, failedReason?: string): StatusResult {
    let color = "orange"; // Default color for "IN_QUEUE"
    let title = "In Queue"; // Default title

    // Determine color and title based on status and reason
    if (status === "FAILED" && failedReason) {
        color = "red";
        title = failedReason;
    } else if (status === "SUCCESS" && !failedReason) {
        color = "green";
        title = "Success";
    }

    return { color, title };
}




