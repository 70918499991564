export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/password/forgot";
export const RESET_PASSWORD = "/password/reset";
export const WELCOME = "/welcome-dashboard";

export const MANAGE_EMPLOYEE_TYPES = "/manage/employee-types";
export const MANAGE_EMPLOYEE_TYPES_EDIT = "/manage/employee-types/edit/:id";
export const MANAGE_EMPLOYEE_TYPES_ADD = "/manage/employee-types/add";


export const MANAGE_CATEGORY = "/manage-categories";

export const MANAGE_FUNCTION = "/manage-functions";
export const MANAGE_PC = "/manage-pcs";
export const EDIT_PC = "/editpc/:uid";
export const VIEW_PC = "/viewpc/:uid";

export const TEST_FILE = "/testfile"
export const MANAGE_SALARY_BENEFITS = "/manage-salary-benefits";
export const MANAGE_SALARY_BENEFITS_EDIT = '/manage-salary-benefits/edit/:id';
export const SALARY_BENIFITS_EDIT_PC = "/salary-benefits/edit";
export const SALARY_BENIFITS_VIEW_PC = "/salary-benefits/view";

export const PERMISSION_CREATE = "/permission/create";
export const PERMISSION_EDIT = "/permission/edit/:id";
export const PERMISSION_UPDATE = "/permission/update/:id";
export const PERMISSION_DELETE = "/permission/delete/:id";
export const MANAGE_PERMISSIONS = "/manage-permissions";

export const MANAGE_ROLES = "/manage-roles";
export const ROLE_CREATE = "/role/create";
export const ROLE_EDIT = "/role/edit/:id";

export const MANAGE_TRANSLATIONS = "/translation/manage";
export const TRANSLATION_LINK_APP_LANGUAGE = "/translation/link-app-language";

export const EMAIL_TEMPLATE_CREATE = "/email-template/create";
export const EMAIL_TEMPLATE_EDIT = "/email-template/edit/:id";
export const EMAIL_TEMPLATE_VIEW = "/email-template/view/:id";
export const MANAGE_EMAIL_TEMPLATES = "/manage-email-templates";
export const MANAGE_EMAIL_LOGS = "/manage-email-logs";

export const DASHBOARD = "/";

export const EMPLOYEE_OVERVIEW = "/employee-overview";
export const WEEKLY_PLANNING = "/weekly-planning";
export const EMPLOYEE_REGISTRATION = "/employee-registration";
export const EMPLOYEE_EDIT = "/employee-registration/:id";
export const EMPLOYEE_REGISTRATION2 = "/registration";

export const INVOICE_OVERVIEW = "/invoice-overview";
export const EMPLOYEE_CONTRACTS = "/employee-contracts";
export const EMPLOYEE_INVITE = "/employee-invite"
export const DOCUMENTS_OVERVIEW = "/documents-overview";
export const WELCOME_DSHBOARD = "/welcome-dashboard";
export const EMPLOYEE_SCHEDULING = "/employee-scheduling";
export const PLANNING_OVERVIEW = "/planning-overview";
export const PLANNING_BY_LOCATION = "/plannings-by-location";
export const DAY_PLANNING = "/day-planning-overview";
export const WEEKLY_PLANNING_OVERVIEW = "/weekly-planning-overview";
export const MONTHLY_PLANNING = "/monthly-planning-overview";
export const LOCATION_DASHBOARD = "/location-dashboard";
export const MY_ACCOUNT = "/my-account";
export const EMPLOYEE_MY_ACCOUNT = "/employee-my-account";
export const TIME_REGISTRATION = "/time-registration";
export const PROCESSES_TIME_RECORDS = "/time-records";



export const MANAGE_COEFFICIENT_TYPES = "/manage/:manageType";
export const MANAGE_COEFFICIENT_TYPE_ADD = "/manage/:manageType/add";
export const MANAGE_COEFFICIENT_TYPE_EDIT = "/manage/:manageType/edit";

export const MANAGE_COEFFICIENT_PCS = "/manage/coefficient-pcs";
export const MANAGE_COEFFICIENT_EMPLOYEE_TYPE_LINKING = "/manage/coefficient-employee-type-linking";

export const MANAGE_COEFFICIENT_DEVIATION_PCS = "/manage/coefficient-deviation-pcs";
export const MANAGE_COEFFICIENT_DEVIATION_EMPLOYEE_TYPE_LINKING = "/manage/coefficient-deviation-employee-type-linking";

export const MANAGE_COEFFICIENT_DEVIATION = "manage/coefficient-deviation";

export const MANAGE_SHIFTS = "/manage/shifts";
export const ref = "/ref";
export const COOPERATION_AGREEMENT = "/cooperation-agreement"
export const MANAGE_COOPERATION_AGREEMENT = "/manage-cooperation-agreement";

//Locations
export const ADD_LOCATION = "/location/add"
export const MANAGE_LOCATIONS = "/locations-manage"
export const EDIT_LOCATION = "/location/edit/:id"
export const MANAGE_LOCATION_MANAGERS = "/manage/location-managers/:id"
//Projects
export const ADD_PROJECT = "/project/add"
export const MANAGE_PROJECTS = "/projects-manage"
export const EDIT_PROJECT = "/project/edit/:id"
//costcemters
export const ADD_COSTCENTER = "/costcenter/add"
export const MANAGE_COSTCENTES = "/costcenters-manage"
export const EDIT_COSTCENTER = "/costcenter/edit/:id"

export const CONFIG_AND_SETTINGS = "/config/settings";

// Report issue
export const REPORT_ISSUE = "/report-issue";

// Manage location
export const MANAGE_LOCATION = "/manage-location";

// QR code
export const QR_code = "/qr-code/:companyId";

// Manage administrator
export const MANAGE_ADMINISTRATOR = "/manage-administrators";

export const ADD_ADMINISTRATOR = "/add-administrator";

// flex salary

export const FLEX_SALARY = "/manage-flex-salary";

export const MANAGE_HOLIDAYS = "/manage/holidays";
export const MANAGE_HOLIDAYS_TYPE_ADD = "/manage/holidays/add";
export const MANAGE_HOLIDAYS_TYPE_EDIT = "/manage/holidays/edit";