import React, { useEffect, useState, useCallback } from "react";
import Header from "../common/layout/Header";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import { DropdownOptions } from "../Dashboard/services/CompanyInterface";
import { ApiCall } from "../../services/ApiServices";
import { GET_ACTIVE_FOLLOW_UPS, GET_ACTIVE_SALES, GET_HISTORYS, GET_SALES_AGENTS, UPDATE_OR_CREATE_FOLLOW_UP_TYPES, UPDATE_OR_SALES_TYPES } from "../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import Button from "../common/atoms/Button";
import CustomNotify from "../common/atoms/CustomNotify";

interface Option {
    value?: number | string | null;
    label?: string;
}

interface Types {
    sales: Option[];
    defaultType: Option | null;
}

const initialTypes: Types = {
    sales: [],
    defaultType: null
};

const Sales: React.FC = () => {
    const [salesTypeOptions, setHistoryTypeOptions] = useState<DropdownOptions[]>([]);
    const [formData, setFormData] = useState<Types>(initialTypes);

    const fetchSalesOptions = useCallback(async () => {
        try {
            const response = await ApiCall?.getService(GET_SALES_AGENTS, "GET");
            if (response.status === "success") {
                setHistoryTypeOptions(Object.values(response.data));
            }
        } catch (error) {
            console.error("Error fetching Slaes data:", error);
        }
    }, []);

    const fetchActiveSales = useCallback(async () => {
        try {
            const response = await ApiCall.getService(GET_ACTIVE_SALES, "GET", CENTRAL_DATA_MANAGEMENT_MICROSERVICE);
            if (response.status === "success") {
                const fetchedDefaultOption = response.data['defaultSale'];
                setFormData((prev) => ({
                    ...prev,
                    sales: Object.values(response.data['activeSales']),
                    defaultType: fetchedDefaultOption,
                }));
            }
        } catch (error) {
            console.error("Error fetching follow-up types:", error);
        }
    }, []);


    useEffect(() => {
        fetchSalesOptions();
        fetchActiveSales();
    }, []);

    const handleSelectChange = (selectedOption: DropdownOptions | null, name: string) => {
        setFormData((prev) => ({
            ...prev,
            [name]: selectedOption
        }));
    };

    const handleSubmit = async () => {

        try {
            const response = await ApiCall.service(
                UPDATE_OR_SALES_TYPES,
                "POST",
                formData,
                true,
                CENTRAL_DATA_MANAGEMENT_MICROSERVICE
            );
            
            if (response.status == 'success') {
                CustomNotify({
                    type: "success",
                    message: "Sales types created successfully",
                });
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        <>
            <Header
                headerName={t("Welcome") + "!"}
                headerClassName="myAccountCardTitle"
            />
            <div className="container-fluid">
                <div className="welcomeDashboardWrapper">
                    <div className="bg-white rounded-5">
                        <div className="row">
                            <div className="col-12 companyWrapper scrollBar ">
                                <div className="multiSelectWrapper">
                                    <SelectWithSearch
                                        fieldLabel={`${t("Sales")}: `}
                                        title={`${t("Sales")}:`}
                                        name="sales"
                                        placeHolder={t("Select a type")}
                                        search
                                        options={salesTypeOptions}
                                        value={formData.sales}
                                        onChange={(selectedOptions) =>
                                            handleSelectChange(selectedOptions as DropdownOptions, 'sales')
                                        }
                                        isMulti={true}
                                        className="select-field"
                                        isTranslate
                                        isMandatory
                                    />
                                </div>
                                <div className="multiSelectWrapper">
                                    <SelectWithSearch
                                        fieldLabel={`${t("Select default type")}: `}
                                        title={`${t("Select default type")}:`}
                                        name="defaultType"
                                        placeHolder={t("Select a type")}
                                        search
                                        options={formData.sales || []}
                                        value={formData.defaultType}
                                        onChange={(selectedOptions) =>
                                            handleSelectChange(selectedOptions as DropdownOptions, 'defaultType')
                                        }
                                        isMulti={false}
                                        className="select-field"
                                        isTranslate
                                        isMandatory
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <Button
                                type="submit"
                                className="searchBtn px-3"
                                title={t("Save")}
                                style={{ width: "fit-content" }}
                                handleClick={handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default translate(Sales);
