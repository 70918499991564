import { Dispatch } from "redux";
import { setPCData } from "../../../features/planning/employeeSlice";
import { ApiCall } from "../../../services/ApiServices";
import { t } from "../../CentralDataMangement/translation/Translation";
import { RootState } from "../../../store";
import { GET_PC_BY_COMPANY } from "../../../routes/ApiEndpoints";

export const getTitle = (type: string = "day"): string => {
    let title = "";
    switch (type) {
        case "day":
            title = t("Employee scheduling daily overview");
            break;
        case "week":
            title = t("Employee scheduling weekly overview");
            break;
        case "month":
            title = t("Employee scheduling monthly overview");
            break;
        default:
            break;
    }
    return title;
};
export const fetchPcInfo = async (companyId: number, dispatch: Dispatch, state: RootState, type = 1) => {
    try {
        const res = await ApiCall.getService(
            `${GET_PC_BY_COMPANY}/${companyId}?type=extend`,
            "GET"
        );
        if (res.status !== "success") {
            throw new Error("Network response was not ok");
        }
        if (res.status === "success") {
            dispatch(setPCData(res.data)); // Dispatch the data to Redux
        } else {
            throw new Error(res.message);
        }
    } catch (err) {
        console.error(err);
    }
};