import React, { useState } from "react";
import DateAndTime from "../common/atoms/DateWithTime";
import HeaderWIthFilter from "./HeaderWIthFilter";
import WeeklyCalendar from "./WeeklyCalendar";
import { useSelector } from "react-redux";
import { addDate, selectPlanningShow } from "../../features/planning/planningSlice";
import { selectCurrentLocations } from "../../features/auth/AuthSlice";
import PlanningTabs from "./PlanningTabs";
import { t, translate } from "../CentralDataMangement/translation/Translation";

const WeeklyPlanningOverview = () => {
  
  const show = useSelector(selectPlanningShow); // Access the `show` state
  const companyId = useSelector((state: any) => state.auth.currentCompany?.id);
  const locations = useSelector(selectCurrentLocations) ?? []; // Access the locations state

  const [date, setDate] = useState(new Date());
  const [selectedLocationIndex, setSelectedLocationIndex] = useState(0); // New state for selected location index

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
  };

  const handleLocationChange = (index: number) => {
    setSelectedLocationIndex(index); // Update selected location index
  };

  return (
    <>
      <HeaderWIthFilter
        headerName={t("Planning - Week")}
        type="week"
        selectedLocation={locations[selectedLocationIndex]} // Pass the currently selected location
        onLocationChange={handleLocationChange} // Pass the handler function
      >
        <DateAndTime
          isWeek={true}
          isIcon={true}
          monthFormat="long"
          dataTimeWrapperClassName="d-inline-flex  align-items-center colorSkyBlue bg-white planningDateWrapper"
          dataTimeClassName="mx-3 planningPlannedDate"
          dayClassName="me-1"
          iconFillColor="#8571b9"
          onDateChange={handleDateChange} // Pass date change handler
          isWeekDate={true}
          weekFormat="short"
        />
      </HeaderWIthFilter>
      <div className="row">
        <div className="col-12">
          <WeeklyCalendar startDate={date} companyId={companyId} selectedLocation={locations[selectedLocationIndex]} /> {/* Pass the selected location */}
        </div>
      </div>
      {show && <PlanningTabs />} {/* Conditionally render the modal based on the `show` state */}
    </>
  );
};

export default translate(WeeklyPlanningOverview);
