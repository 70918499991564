import "./css/login.css";
import React, { useState } from "react";
import Icon from "../../../../Icon";
import LoadImage from "../../../../static/images/LoginImage.png";
import InputTextFieldWithLabel from "../../../common/molecules/InputTextFieldWithLabel";
import Button from "../../../common/atoms/Button";
import IconWithTitle from "../../../common/molecules/IconWithTitle";
import { t } from "../../../CentralDataMangement/translation/Translation";
import CustomNotify from "../../../common/atoms/CustomNotify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiCall } from "../../../../services/ApiServices";
import { RESET_PASSWORD } from "../../../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../../Constants";
import { validatePasswordComplexityOneMessage } from "../../../../services/ValidationService";

const ResetPassword: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const email = queryParams.get('email');
	const token = queryParams.get('token');

	const [state, setState] = useState({
		password: '',
		confirm_password: '',
		show_password: false,
		show_confirm_password: false
	});

	const items = [
		{ name: "Time", title: "SAVE TIME" },
		{ name: "pathway", title: "FLEXIBLE" },
		{ name: "protection", title: "WEEKLY PAY" },
		{ name: "timeSlot", title: "24/7 CONTRACTING" },
		{ name: "security", title: "SAFETY" },
		{ name: "shakeHands", title: "SECURITY" },
		{ name: "Headset", title: "ANSWERS TO ALL YOUR QUESTIONS" },
		{ name: "editAgreement", title: "CONTRACTING TOOL" },
	];

	const validate = () => {
		let error: string | null = null;
		if (!(state?.password && state?.confirm_password)) {
			error = 'Please fill the required fields';
		} else {
			const complexityError = validatePasswordComplexityOneMessage(state.password);
			if (complexityError) {
				error = complexityError;
			} else if (state.password !== state.confirm_password) {
				error = 'Passwords confirmation failed';
			}
		}

		return error;
	}

	const handleSubmit = async () => {
		const error = validate();

		if (error === null) {
			const updatedState = {
				password: state.password,
				confirm_password: state.confirm_password,
				email: email,
				token: token
			};
			const response = await ApiCall.service(
				RESET_PASSWORD,
				"POST",
				updatedState,
				true,
				CENTRAL_DATA_MANAGEMENT_MICROSERVICE
			);

			if (response.status === 'success') {
				navigate('/login');
				CustomNotify({
					type: 'success',
					message: response.message
				})
			} else {
				let message = ''
				if (response.status === 'validation-error') {
					message = Object.values(response.data).join(", ")
				} else {
					message = response.message
				}

				CustomNotify({
					type: 'error',
					message: message
				})
			}
		} else {
			CustomNotify({
				type: 'error',
				message: error
			})
		}
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setState(prevState => ({
			...prevState,
			[name]: value
		}));
	}

	return (
		<div className="container-fluid">
			<div className="loginWrapper">
				<div className="row gx-5">
					<div className="col-4">
						<div className="loginLeftSection text-center h-100 position-relative">
							<div className="loginLeftSectionContent text-white">
								{t("Completely")}
								<span className="colorYellow ms-1">{t("digital")},</span>
								<br />
								{t("but with a")}
								<br />
								{t("smile")}!
							</div>
							<div className="loginImageWrapper">
								<img src={LoadImage} alt={t("login")} className="img-fluid" />
							</div>
						</div>
					</div>
					<div className="col-4 loginMiddleSection">
						<div className="card border-0 loginCard h-100 rounded-0 d-grid align-content-center">
							<div className="loginCardPadding py-0">
								<div className="text-center">
									<Icon
										name="Logo"
										width="15vw"
										height="3vw"
										className="img-fluid mx-auto"
										isIcon={true}
									/>
								</div>
								<div className="loginMiddleSectionContent text-center">
									<div className="colorSkyBlue loginInText">
										{t("Reset password")}
									</div>
									<div className="row position-relative">
										<InputTextFieldWithLabel
											placeholder={t("Password")}
											type={state.show_password ? "text" : "password"}
											value={state.password}
											name={"password"}
											id="password"
											className="mb-3 rounded-0"
											handleChange={handleChange}
										/>
										<span
											onClick={() =>
												setState((prevData) => ({
													...prevData,
													show_password: !prevData.show_password, // Toggle state
												}))
											}
											className="secondaryColorHoverEffect cursor-pointer col-auto eyeIcon"
										>
											<FontAwesomeIcon
												icon={state?.show_password ? faEye : faEyeSlash} // change icon based on state
											/>
										</span>
									</div>
									<div className="row position-relative">
										<InputTextFieldWithLabel
											placeholder={t("Confirm password")}
											type={state.show_confirm_password ? "text" : "password"}
											value={state.confirm_password}
											name={"confirm_password"}
											id="confirm_password"
											className="mt-1 mb-2 rounded-0"
											handleChange={handleChange}
											disableCopyPaste
										/>
										<span
											onClick={() =>
												setState((prevData) => ({
													...prevData,
													show_confirm_password: !prevData.show_confirm_password, // Toggle state
												}))
											}
											className="secondaryColorHoverEffect cursor-pointer col-auto eyeIcon"
										>
											<FontAwesomeIcon
												icon={state?.show_confirm_password ? faEye : faEyeSlash} // change icon based on state
											/>
										</span>
									</div>
									<div className="row">
										<div className="col-12">
											<Button
												type="submit"
												title={t("Submit")}
												className="signInBtn w-100"
												handleClick={handleSubmit}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-4">
						<div className="loginRightSection h-100">
							<div className="row">
								{items.map(item => (
									<div className="col-6 text-center" key={item?.name}>
										<IconWithTitle
											name={item?.name}
											isIcon={true}
											isTitle={true}
											width="10vw"
											height="2vw"
											className="img-fluid mx-auto"
											title={t(`${item?.title}`)}
											borderRadius={"10vw"}
											labelClassName="text-white loginRightSectionTitle"
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
