export function mapToBackendPayload(formValues: any,userId:number|null): any {
    console.log("kkkdnk",formValues);
    return {
        trade: {
            trade_id: formValues.trades || null,
            office_id: formValues.office_id,
            office_consultant_id: formValues.office_consultant_id ?? 83711,
        },
        company: {
            vatcountry_iso: formValues.iban_code,
            vat_number: formValues.vat_number,
            search_name: formValues.search_name,
            official_name: formValues.official_name || formValues.offical_name,
            street: formValues.street,
            street_number: formValues.street_number,
            postal_code: formValues.postal_code,
            box: formValues.bus || "", // assuming 'bus' is equivalent to 'box'
            city: formValues.city,
            country: formValues.countries ? parseInt(formValues.countries) : undefined,
            bright_id: formValues.bright_id || "",
            email:  formValues.contact_person_email  || formValues.trade_email ,
            phone: formValues.contact_person_phone || formValues.trade_phone_number,
        },
        company_contact: {
            id: formValues.contact_id,
            first_name: formValues.contact_person_frist_name || formValues.contact_person_frist_name,
            last_name: formValues.contact_person_last_name || formValues.contact_person_last_name,
            function: formValues.contact_person_title,
            email:  formValues.contact_person_email  || formValues.trade_email ,
            phone: formValues.contact_person_phone || formValues.trade_phone_number  ,
        },
        followup: {
            history_type_id: formValues.follow_up_type.value,
            info: formValues.info,
            date_next: formValues.next_date,
            time_next: formValues.next_time,
            user_next_id:  formValues.sales ?? 83711
        },
        userId: userId
    };
}
