import React, { useEffect } from "react";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CalendarIcon from "../atoms/CalendarIcon";
import { useSelector, useDispatch } from "react-redux";
import { formatDate, formatDateTime } from '../utlis/dateHelper'; // Adjust the import path as needed
import {
  selectContractType,
  selectDates,
  setWeekNumber,
  setDates,
  addDate,
  updateEmployeeSchedule,
  selectEmployees,
  selectLocationId,
  clearAllSchedules
} from "../../../features/planning/planningSlice";
import {
  startOfWeek,
  endOfWeek,
  getWeek,
  isWithinInterval,
  format // Import format from date-fns
} from "date-fns";
import { generateSchedule } from "../../Planning/Services/PlanningHelper";

interface PlanningCalendarProps {
  isReactCalendar?: boolean;
  iscalendarSeperator?: boolean;
  customClassName?: string;

}

const PlanningCalendar: React.FC<PlanningCalendarProps> = ({
  isReactCalendar,
  iscalendarSeperator,
  customClassName,
}) => {
  const contractType = useSelector(selectContractType);
  const dates = useSelector(selectDates);
  const locationId = useSelector(selectLocationId);
  const selectedEmployees = useSelector(selectEmployees);
  // const schedule = useSchedule(dates, locationId);
  const dispatch = useDispatch();




  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (!Array.isArray(dates) || dates.length === 0) {
      return "";
    }

    const startDate = new Date(dates[0]);
    let dateDate = new Date(dates[1] || startDate); // If end date doesn't exist, use start date for single-day selection
    if (contractType === "week") {
      // Check if the current tile's date falls within the calculated week range
      if (dates.includes(formatDateTime(date))) {
        return "date-selected"; // Add the class for week highlighting
      }
    }
    if (contractType === "day") {
      if (formatDateTime(date) === formatDateTime(startDate)) {
        return "date-selected"; // Add the class for week highlighting
      }
    }
    // For other cases, do not highlight
    return "";
  };


  // Set the value based on contract type
  const calendarValue = Array.isArray(dates) ? dates[0] : dates;

  function onChange(value: any, event: React.MouseEvent<HTMLButtonElement>) {
    if (!value) {
      return;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to the start of the current day

    if (Array.isArray(value)) {
      if (value.length === 2 && value[0] instanceof Date && value[1] instanceof Date) {
        // Handle date range selection
        const startDate = value[0];
        const endDate = value[1];

        if (startDate >= today && endDate >= today) {
          const formattedStartDate = formatDate(startDate.toISOString());
          const formattedEndDate = formatDate(endDate.toISOString());

          dispatch(setDates([formattedStartDate, formattedEndDate]));
          dispatch(setWeekNumber({ weekNumber: getWeek(startDate, { weekStartsOn: 1 }) }));
        }
      } else {
        // Handle multiple single date selections
        const validDates = value.filter((date) => date instanceof Date && date >= today);
        if (validDates.length > 0) {
          dispatch(setDates(validDates.map((date) => formatDate(date.toISOString()))));
        }
      }
    } else if (value instanceof Date) {
      // Handle single date selection
      if (value >= today) {
        const formattedDate = formatDate(value.toISOString());
        dispatch(addDate(formattedDate));
        if (contractType === "day") {
          dispatch(setWeekNumber({ weekNumber: getWeek(value, { weekStartsOn: 1 }) }));
        } else if (contractType === "week" && !dates.includes(formatDateTime(value))) {
          let start = startOfWeek(value, { weekStartsOn: 1 });
          let end = endOfWeek(value, { weekStartsOn: 1 });

          const weekDates: string[] = [];
          let currentDate = start;
          while (currentDate <= end) {
            if (currentDate >= today) {
              weekDates.push(formatDate(currentDate.toISOString()));
            }
            currentDate.setDate(currentDate.getDate() + 1);
          }

          if (weekDates.length > 0) {
            dispatch(setDates(weekDates));
            let schedule = generateSchedule(weekDates, locationId);
            dispatch(setWeekNumber({ weekNumber: getWeek(value, { weekStartsOn: 1 }), schedule: schedule }));
          }
        } else if (contractType === "week" && dates.includes(formatDateTime(value))) {
          // Remove the date from selected dates
          const updatedDates = dates.filter((date: string) => date !== formatDateTime(value));
          dispatch(setDates(updatedDates));
          let schedule = generateSchedule(updatedDates, locationId);
          dispatch(setWeekNumber({ weekNumber: getWeek(value, { weekStartsOn: 1 }), schedule: schedule }));
        }
      }
    }
  }


  function onClickWeekNumber(weekNumber: number, date: Date, event: React.MouseEvent<HTMLButtonElement>) {
    if (!(date instanceof Date)) {
      return;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to the start of the current day

    // Calculate the start and end dates of the week
    const start = startOfWeek(date, { weekStartsOn: 1 });
    const end = endOfWeek(date, { weekStartsOn: 1 });

    if (contractType === "week") {
      const weekDates: string[] = [];
      let currentDate = start;

      while (currentDate <= end) {
        if (currentDate >= today) {
          weekDates.push(formatDate(currentDate.toISOString()));
        }
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }

      if (weekDates.length > 0) {
        dispatch(setDates(weekDates));
        let schedule = generateSchedule(weekDates, locationId);
        dispatch(setWeekNumber({ weekNumber: weekNumber, schedule: schedule }));
      }
    }

    if (contractType === "day") {
      if (start >= today) {
        dispatch(setDates([formatDate(start.toISOString())]));
        dispatch(setWeekNumber({ weekNumber: getWeek(new Date(start), { weekStartsOn: 1 }) }));
      }
    }
  }



  return (
    <div className={`calendarWrapper ${customClassName}`}>
      <div>
        {
          iscalendarSeperator
          &&
          <CalendarIcon isCalendarIcon={true} />
        }

        {isReactCalendar && (
          <ReactCalendar
            onChange={(value, event) => onChange(value, event)}
            value={calendarValue}
            className={`mx-auto`}
            minDate={new Date()}
            showWeekNumbers={true}
            onClickWeekNumber={(weekNumber, date, event) =>
              contractType === "week"
                ? onClickWeekNumber(weekNumber, date, event)
                : undefined
            } tileClassName={tileClassName}
          />
        )}
      </div>
    </div>
  );
};

export default PlanningCalendar;
