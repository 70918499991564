import React from 'react';
import Select, { components } from 'react-select';
import { t } from '../../translation/Translation';

const customStyles = {
    control: base => ({
        ...base,
        height: 35,
        minHeight: 35
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'lightgray' : null,
        color: 'black',
    }),
};

// Override the IndicatorSeparator to hide it
const customComponents = {
    IndicatorSeparator: () => null
};

export default function MultiSelect({
    handleChange,
    standards,
    options,
    disabled,
    isMulti = false,
    name = '',
    placeholder = t("Select"),
    className = "pc-single-select",
    customStyle = {},
    classNamePrefix = '',
    isSearchable = true,
    noOption = "No option"
}) {
    const customFilter = (option, searchText) => {
        if (option.label !== undefined && option.label !== null && option.label.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
        }
        return false;
    };

    return (
        <div className={className || "col-md-3 mt-2 mb-3 p-0"} style={customStyle}>
            <Select
                placeholder={placeholder}
                value={standards}
                onChange={handleChange}
                isDisabled={disabled}
                options={options}
                filterOption={customFilter}
                isSearchable={isSearchable}
                isMulti={isMulti}
                styles={customStyles}
                name={name}
                classNamePrefix={classNamePrefix || 'react-select-prefix'}
                noOptionsMessage={() => noOption}
                components={customComponents} 
            />
        </div>
    );
}
