interface Shifts {
    start_time: string;
    end_time: string;
    sequence: number;
}

interface Schedule {
    location_id: number;
    project_id?: number;
    cost_center?: number;
    shifts: Shifts[];
    error?: string;
    warning?: string[];
}

interface ScheduleMap {
    [date: string]: Schedule;
}

// Format the time input (e.g., from "HHMM" to "HH:MM")
export const formatTimeInput = (input: string): string => {
    const sanitizedInput = input.replace(/[^0-9]/g, "");
    if (sanitizedInput.length === 4) {
        const hours = sanitizedInput.slice(0, 2);
        const minutes = sanitizedInput.slice(2);
        return `${hours}:${minutes}`;
    }
    return input;
};
export const sanitizedInputLength = (input: string): number => {
    const sanitizedInput = input.replace(/[^0-9]/g, "");
    return sanitizedInput.length;
};


// Update the schedule with the new data
export const updateSchedule = (
    date: string,
    newSchedule: Schedule,
    schedule: ScheduleMap,
    setSchedule: React.Dispatch<React.SetStateAction<ScheduleMap>>,
    employee: number,
    dispatch: any,
    updateEmployeeSchedule: any
) => {
    const updatedSchedule: ScheduleMap = { ...schedule, [date]: newSchedule };
    setSchedule(updatedSchedule);

    if (employee) {
        dispatch(
            updateEmployeeSchedule({
                employee_id: employee,
                scheduleMap: updatedSchedule,
            })
        );
    }
};

export const calculateShiftsTotalTime = (shifts: Shifts[], type: number = 1): any => {
    let totalMinutes = 0;

    shifts.forEach((shift) => {
        const [startHours, startMinutes] = shift.start_time.split(":").map(Number);
        const [endHours, endMinutes] = shift.end_time.split(":").map(Number);

        if (!isNaN(startHours) && !isNaN(startMinutes) && !isNaN(endHours) && !isNaN(endMinutes)) {
            let startTime = startHours * 60 + startMinutes;
            let endTime = endHours * 60 + endMinutes;

            // If end time is less than start time, it means the shift crosses into the next day
            if (endTime < startTime) {
                endTime += 24 * 60; // Add 24 hours to end time to account for next day
            }

            // Add the time difference between start and end to total minutes
            totalMinutes += endTime - startTime;
        }
    });

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (type === 2) {
        return (totalMinutes / 60).toFixed(2); // Decimal format for total hours
    } else {
        return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`; // Return in HH:MM format
    }
};



export const formatDateTimeInputToUTC = (inputDate: string, inputTime: string): string => {
    // Sanitize the input to remove any non-numeric characters from the time
    const sanitizedTime = inputTime.replace(/[^0-9]/g, "");

    if (sanitizedTime.length === 4) {
        const hours = sanitizedTime.slice(0, 2);
        const minutes = sanitizedTime.slice(2);

        // Parse the input date and create a Date object
        const date = new Date(`${inputDate}T${hours}:${minutes}:00`);

        // Convert to UTC
        const utcDate = new Date(Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            0
        ));

        const utcYear = utcDate.getUTCFullYear();
        const utcMonth = (utcDate.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
        const utcDay = utcDate.getUTCDate().toString().padStart(2, "0");
        const utcHours = utcDate.getUTCHours().toString().padStart(2, "0");
        const utcMinutes = utcDate.getUTCMinutes().toString().padStart(2, "0");

        return `${utcYear}-${utcMonth}-${utcDay} ${utcHours}:${utcMinutes} UTC`;
    }

    return `${inputTime}`;
};
export const extractTimeFromUTCString = (utcString: string) => {
    // Match the time part (HH:mm) from the UTC string using a regular expression
    const match = utcString.match(/\b(\d{2}:\d{2})\b/);

    // If a match is found, return the time part
    if (match) {
        return match[0]; // This will return the "HH:mm" part
    }
};
export const getWeekDays = (startDate: Date) => {
    const days = [];
    const currentDate = new Date(startDate);

    // Find the Monday of the week
    const dayOfWeek = currentDate.getDay();
    const mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // If Sunday, offset to previous Monday
    currentDate.setDate(currentDate.getDate() + mondayOffset);

    // Fill the week from Monday to Sunday
    for (let i = 0; i < 7; i++) {
        const weekDay = new Date(currentDate);
        weekDay.setDate(currentDate.getDate() + i);
        days.push(weekDay);
    }

    return days;
};



