import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ApiCall } from "../../../../services/ApiServices";
import { t } from "../../../CentralDataMangement/translation/Translation";
import {
  ADD_COEFFICIENT_TYPES,
  EDIT_COEFFICIENT_TYPES,
} from "../../../../routes/ApiEndpoints";
import Header from "../../../common/layout/Header";
import BackLink from "../../../common/atoms/BackLink";
import LabelField from "../../../common/atoms/LabelField";
import Button from "../../../common/atoms/Button";
import Icon from "../../../../Icon";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";
import OverFlowWrapper from "../../../common/OverFlowWrapper";

const OperationCoefficientTypes = ({ rows }) => {
  // console.log(rows, "rows");
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Extract id from query parameters
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  // Determine if the operation is an edit or an add based on the URL
  const isEditing = location.pathname.includes("edit");

  const initialRow = rows.find((row) => row.id == id) || {};
  const [state, setState] = useState({
    name: isEditing ? initialRow.name : "",
    rows,
    editFlow: isEditing,
    editUrl: EDIT_COEFFICIENT_TYPES,
    createUrl: ADD_COEFFICIENT_TYPES,
    newItems: isEditing ? [initialRow] : [{ id: Date.now(), name: "" }],
    nameWarning: false,
    typeName: t("coefficient"),
    uniqueError: false,
    duplicates: [],
  });

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    setState((prevState) => {
      const newItems = [...prevState.newItems];
      newItems[index].name = value;
      return {
        ...prevState,
        newItems,
        nameWarning: false,
        uniqueError: false,
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { createUrl, editUrl, newItems, editFlow } = state;

    // Get list of existing names, ignoring the name of the current row being edited (if editFlow is true)
    const existingNames = rows
      .filter((row) => !editFlow || row.id !== initialRow.id) // Exclude current item if in edit mode
      .map((row) => row.name.trim().toLowerCase());

    // Check for duplicates in newItems
    const duplicateItems = newItems.filter((item) => {
      const trimmedName = item.name.trim().toLowerCase();
      return existingNames.includes(trimmedName);
    });

    if (duplicateItems.length > 0) {
      // If there are duplicates, show an error and prevent submission
      setState((prevState) => ({
        ...prevState,
        uniqueError: true,
        duplicates: duplicateItems.map((item) => item.name), // Store duplicate names to show in error
      }));
      return;
    }

    // Check for empty names in newItems
    const hasEmptyName = newItems.some((item) => !item.name.trim());

    if (hasEmptyName) {
      setState((prevState) => ({ ...prevState, nameWarning: true }));
      if (inputRef.current) {
        inputRef.current.focus(); // Only call focus if inputRef is not null
      }
      return;
    }

    // Prepare items to submit
    const itemsToSubmit = newItems.filter((item) => item.name.trim());

    try {
      if (editFlow) {
        // Update single coefficient type
        const apiUrl = `${editUrl}/${newItems[0].id}`;
        const result = await ApiCall.service(apiUrl, "PUT", {
          name: newItems[0].name,
        });
        if (result.success) {
          window.location.reload();
        }
      } else {
        // Add multiple new coefficient types
        const result = await ApiCall.service(createUrl, "POST", {
          coefficients: itemsToSubmit,
        });
        if (result.success) {
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const addCoefficientType = () => {
    setState((prevState) => ({
      ...prevState,
      newItems: [...prevState.newItems, { id: Date.now(), name: "" }], // Add a new empty row
    }));
  };

  const removeCoefficientType = (id) => {
    setState((prevState) => ({
      ...prevState,
      newItems: prevState.newItems.filter((item) => item.id !== id),
    }));
  };

  return (
    <>
      <Header
        headerName={
          state.editFlow
            ? t("Edit coefficient type")
            : t("Add coefficient types")
        }
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-2">
        <div className="row">
          <BackLink backLink="/manage/coefficient-types" />
          <div className="col-6 text-end"></div>
        </div>
      </div>
      <div className="createpagesBg">
        <form onSubmit={handleSubmit} className="needs-validation" noValidate>
        <OverFlowWrapper className="addEmployeeTypeClass container-fluid ps-0">
          {state.newItems.map((item, index) => (
            <div className="row " key={item.id}>
              <>
                <div className={`${!state.editFlow ? "col-11" : "col-12"} mb-2`}>
                  {/* <LabelField title={`${t("Coefficient name")} ${index + 1}`} /> */}
                  <LabelWithInputField
                    label={t("Coefficient name")}
                    handleChange={(event) => handleInputChange(index, event)}
                    placeholder={t("Coefficient Name")}
                    ref={index === 0 ? inputRef : null}
                    type="text"
                    value={item.name}
                    className={` ${
                      state.nameWarning && !item.name ? "border-danger" : ""
                    } ps-2 me-2`}
                  />
                </div>
                {!state.editFlow && (
                  <div className="col-1 seachBtnWrapper">
                    {index > 0 && (
                      <span
                        onClick={() => removeCoefficientType(item.id)}
                        title={t("Remove")}
                        className="secondaryColorHoverEffect removeUploadedFile me-2"
                      >
                        <Icon
                          isIcon={true}
                          width="1vw"
                          height="1vw"
                          name="RemoveIcon"
                        />
                      </span>
                    )}
                    {index === state.newItems.length - 1 && (
                      <span
                        onClick={addCoefficientType}
                        className="secondaryColorHoverEffect"
                        title={t("Add")}
                      >
                        <Icon
                          isIcon={true}
                          width="1vw"
                          height="1vw"
                          name="add"
                        />
                      </span>
                    )}
                  </div>
                )}
              </>
            </div>
          ))}
          {state.nameWarning && (
            <small className="text-danger">
              {t("Please provide a name for all coefficient types")}
            </small>
          )}
          {state.uniqueError && (
            <div className="text-danger">
              {t("The following coefficient names already exist:")}{" "}
              {state.duplicates.join(", ")}
            </div>
          )}
          </OverFlowWrapper>
          <div className="col-12 mt-3">
            <Button
              className="searchBtn float-end"
              type="submit"
              title={state.editFlow ? t("Update") : t("Save")}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default OperationCoefficientTypes;
