import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import Icon from "../../../Icon";

interface props {
  children?: React.ReactNode;
  variant?: string;
  iconName?: string;
  className?: string;
}
const CustomAlert: React.FC<props> = ({
  children,
  variant,
  iconName,
  className,
}) => {
  const [show, setShow] = useState(true);

  return (
    <Alert
      variant={variant}
      onClose={() => setShow(false)}
      dismissible
      className={`mb-1 text-danger  ${className}`}
    >
      {iconName && (
        <span className="me-1">
          <Icon name={iconName} width="1vw" height="1vw" isIcon={true} />
        </span>
      )}
      {children}
    </Alert>
  );
};

export default CustomAlert;
