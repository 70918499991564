import { validateEmail, validatePassword } from "../../../services/ValidationService";
import { Address, AuthState, DefaultFormState, DropdownOptions, FormErrors, FormValues } from "./CompanyInterface";

export const populateCompanyForm = (selectedCompany: FormValues) => {
    if (selectedCompany?.id) {
        return {
            id: selectedCompany?.id,
            vat_number: selectedCompany?.vat_number || "",
            official_name: selectedCompany?.official_name || "",
            search_name: selectedCompany?.search_name || "",
            first_name: selectedCompany?.first_name || "",
            last_name: selectedCompany?.last_name || "",
            email: selectedCompany?.email || "",
            enterprise: selectedCompany?.enterprise || "",
            phone_number: selectedCompany?.phone_number || "",
            security: {
                password: "",
                confirm_password: "",
            },
            profile_picture_url: selectedCompany?.profile_picture_url || "",
            profile_picture_id: selectedCompany?.profile_picture_id || null,
            profile_path: selectedCompany?.profile_path || "",
            is_invoice_address: selectedCompany?.is_invoice_address,
            is_active:
                selectedCompany?.is_active !== undefined
                    ? selectedCompany.is_active
                    : true,
            role: selectedCompany?.role || "employer",
            address: {
                official: {
                    id: selectedCompany?.address?.official?.id || null,
                    name: selectedCompany?.address?.official?.name || "",
                    street: selectedCompany?.address?.official?.street || "",
                    number: selectedCompany?.address?.official?.number || "",
                    box: selectedCompany?.address?.official?.box || "",
                    addition: selectedCompany?.address?.official?.addition || null,
                    postal_code: selectedCompany?.address?.official?.postal_code || "",
                    city: selectedCompany?.address?.official?.city || "",
                    country: selectedCompany?.address?.official?.country,
                },
                invoice: {
                    id: selectedCompany?.address?.invoice?.id || null,
                    name: selectedCompany?.address?.invoice?.name || "",
                    street: selectedCompany?.address?.invoice?.street || "",
                    number: selectedCompany?.address?.invoice?.number || "",
                    box: selectedCompany?.address?.invoice?.box || "",
                    addition: selectedCompany?.address?.invoice?.addition || null,
                    postal_code: selectedCompany?.address?.invoice?.postal_code || "",
                    city: selectedCompany?.address?.invoice?.city || "",
                    country: selectedCompany?.address?.invoice?.country,
                },
            },
            pc: selectedCompany?.pc || [],
            iban_code: selectedCompany?.iban_code || "BE",
            source: selectedCompany?.source || "",
            extrar_ref: selectedCompany?.extrar_ref || "",
            pcvalues: selectedCompany?.pc || [],
            timezone: selectedCompany?.timezone || 34
        };
    }
    return null;
};



export const isRequired = (value: string | number | boolean | null | {}): boolean => {
    return !value || value === null || value === '' || value === undefined;
};

export const validateAddressFields = (
    address: Record<string, any>,
    errors: FormErrors = {},
    addressType: "official" | "invoice" | "residence",
    fields: string[]
): void => {
    const requiredMessage = 'This field is required.';
    const validRegex = /^[a-zA-Z0-9 .]+$/;

    if (!address?.country || address?.country === "Select") {
        errors[`address_${addressType}_country`] = requiredMessage;
    }

    // Validate other address fields
    fields.forEach((field) => {
        const value = address?.[field];
        if (!value) {
            errors[`address_${addressType}_${field}`] = requiredMessage;
        } else if ((field === 'number' || field === 'postal_code') && !validRegex.test(value)) {
            errors[`address_${addressType}_${field}`] = "This field is invalid";
        }
    });
};

export const validatePcValues = (
    pcValues: any,
    previousPcValues: string[],
    errors: FormErrors,
    isEditing: boolean
): void => {
    if (!pcValues || pcValues?.length === 0) {
        errors.pc = 'This field is required.';
    }
    if (isEditing) {
        const removedValues = previousPcValues?.filter(prevValue => !pcValues?.includes(prevValue));
        if (removedValues?.length > 0) {
            errors.pc = 'Removal of Paritair committee is not allowed.\nYou can only add new Paritair committees to the company.';
        }
    }
};

const validateVAT_number = (value: string): string | null => {
    if (!value || value?.trim() === "") {
        return "This field is required.";
    }
    if (!/^[a-zA-Z0-9]+$/.test(value)) {
        return "VAT number must contain only alphanumeric characters.";
    }
    if (value.length < 8) {
        return "VAT number must be at least 8 characters long.";
    }
    if (value.length > 15) {
        return "VAT number must be at most 15 characters long.";
    }
    return null;
};


export const formValidation = (
    formState: DefaultFormState,
    user?: AuthState,
    isEditing: boolean = false,
    previousPcValues?: string[]
): FormErrors => {
    const errors: FormErrors = {};

    const fields: {
        string: (keyof FormValues)[];
        number: (keyof FormValues)[];
        address: (keyof Address)[];
    } = {
        string: !user?.token ? ["vat_number", "official_name", "search_name", "first_name", "last_name"] : ["vat_number", "official_name", "search_name"],
        number: !user?.token ? ["phone_number"] : [],
        address: ["street", "city", "postal_code", "number", "country"],
    };

    // Validate string fields
    fields.string.forEach((field) => {
        const value = formState?.formValues[field];
        if (isRequired(value) && (field !== 'vat_number' && isEditing)) {
            errors[field] = 'This field is required.';
        } else {
            if (isRequired(value))
                errors[field] = 'This field is required.';
        }
    });

    const phoneNumberRegex = /^[0-9\s./()+]+$/;
    // Validate number fields
    fields.number.forEach((field) => {
        const value: any = formState?.formValues[field];
        if (isRequired(value)) {
            errors[field] = 'This field is required.';
        } else if (!phoneNumberRegex.test(value)) {
            errors[field] = 'Please enter a valid phone number.';
        }
    });


    // Validate email
    if (!user?.token && !isEditing) {
        const email = formState?.formValues?.email || "";
        const emailError = validateEmail(email);
        if (emailError) {
            errors.email = emailError;
        }
    }

    // Validate box number
    if (formState?.formValues?.address) {
        const officialBox = formState.formValues.address.official.box;
        if (officialBox) {
            if (!/^[0-9a-zA-Z .]+$/.test(officialBox)) {
                errors.address_official_box = 'This field is invalid';
            }
        }

        const invoiceBox = formState.formValues.address.invoice.box;
        if (invoiceBox && formState.formValues.is_invoice_address === false) {
            if (!/^[0-9a-zA-Z .]+$/.test(invoiceBox)) {
                errors.address_invoice_box = 'This field is invalid';
            }
        }
    }

    // Validate passwords
    if (!user?.token && !isEditing) {
        const passwordError = validatePassword(formState?.formValues?.security?.password);
        if (passwordError) {
            errors.password = passwordError;
        }

        const confirmPasswordError = validatePassword(formState?.formValues?.security?.confirm_password);
        if (confirmPasswordError) {
            errors.confirm_password = confirmPasswordError;
        }

        if (formState.formValues.security.confirm_password !== formState?.formValues?.security?.password) {
            errors.confirm_password = "The two password fields didn't match.";
        }
    }

    // Validate VAT number
    const vatNumber = formState?.formValues?.vat_number;
    const vatNumberError = validateVAT_number(vatNumber);
    if (vatNumberError) {
        errors.vat_number = vatNumberError;
    }
    validateAddressFields(formState?.formValues?.address?.official, errors, 'official', fields?.address);

    const invoiceAddress = formState?.formValues?.address?.invoice;
    const isInvoiceAddressFilled = fields?.address?.some(field => invoiceAddress[field]);

    if (isInvoiceAddressFilled && formState?.formValues?.is_invoice_address === false) {
        validateAddressFields(invoiceAddress, errors, 'invoice', fields?.address);
    }
    if (isRequired(formState?.formValues?.timezone)) {
        errors.timezone = 'This field is required.';
    }
    validatePcValues(formState?.formValues?.pc, previousPcValues || [], errors, isEditing);

    return errors;
};

export const formatVatResponse = (response: any, formState: any, user: AuthState) => {
    const enterpriseData = response?.['enterprise'] || {};

    const matchedCountry = formState?.countryOptions?.find(
        (country: DropdownOptions) => country?.label === enterpriseData?.country
    );
    const matchedIban = formState?.ibancountries?.find(
        (iban: DropdownOptions) => iban?.value === response?.vatcountry_iso
    );

    // Return the formatted data
    return {
        verifiedVatnumber: `${formState?.formValues?.iban_code || ''}${enterpriseData?.vatnumber || ''}`,
        formValues: {
            ...formState.formValues,
            vat_number: enterpriseData?.vatnumber || "",
            official_name: enterpriseData?.official_name || "",
            search_name: enterpriseData?.name || "",
            first_name: user?.token ? user?.first_name : "",
            last_name: user?.token ? user?.last_name : "",
            email: user?.token ? user?.email : enterpriseData?.mail,
            phone_number: user?.token ? user?.phone_number : enterpriseData?.phone,
            address: {
                ...formState.formValues?.address,
                official: {
                    ...formState?.formValues?.address?.official,
                    name: enterpriseData?.name || "",
                    street: enterpriseData?.street || "",
                    number: enterpriseData?.street_nr || "",
                    box: enterpriseData?.bus || "",
                    postal_code: enterpriseData?.postal_code || "",
                    city: enterpriseData?.city || "",
                    country: matchedCountry ? matchedCountry.value : null,
                    addition: formState?.formValues?.address?.official?.addition,
                },
                invoice: {
                    ...formState.formValues?.address?.invoice,
                    name: formState?.formValues?.is_invoice_address ? enterpriseData?.name : "",
                    street: formState?.formValues?.is_invoice_address ? enterpriseData?.street : "",
                    number: formState?.formValues?.is_invoice_address ? enterpriseData?.street_nr : "",
                    box: formState?.formValues?.is_invoice_address ? enterpriseData?.bus : "",
                    postal_code: formState?.formValues?.is_invoice_address ? enterpriseData?.postal_code : "",
                    city: formState?.formValues?.is_invoice_address ? enterpriseData?.city : "",
                    country: formState?.formValues?.is_invoice_address && matchedCountry ? matchedCountry.value : null,
                    addition: formState?.formValues?.address?.official?.addition,
                },
            },
            iban_code: matchedIban ? matchedIban.value : formState?.formValues?.iban_code,
            profile_picture_url: formState?.formValues?.profile_picture_url,
            is_invoice_address: formState?.formValues?.is_invoice_address,
            is_active: formState?.formValues?.is_active,
            role: formState?.formValues?.role,
            pc: response?.pc_ids,
            source: formState?.formValues?.source,
            extrar_ref: formState?.formValues?.extrar_ref,
        },
    };
};