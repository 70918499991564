import React from 'react'

interface props {
  headerLength?: any;
  className?:string;
}
const TableHeaderSpace: React.FC<props> = ({ headerLength, className }) => {
  return (
    <tr>
      <th
        className={`${className} emptyRow p-0 border-0`}
        style={{ height: '0.7vw' }}
        colSpan={headerLength}
      ></th>
    </tr>
  )
}

export default TableHeaderSpace
