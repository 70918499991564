import { MyAccount } from "./MyAccountInterface";

export const initialState: MyAccount = {
    formValues: {
        id: null,
        is_national: false,
        nationality_id: null,
        registry_number: "",
        work_permit: {
            number: "",
            end_date: ""
        },
        title: null,
        first_name: "",
        last_name: "",
        email: "",
        confirm_email: "",
        phone_number: "",
        education: null,
        security: {
            password: "",
            confirm_password: ""
        },
        gender: null,
        date_of_birth: "",
        place_of_birth: "",
        country_of_birth: null,
        civil_status: null,
        desired_employee_type: null,
        language_id: null,
        bank_account: {
            type: "iban",
            iban: "",
            bic: "",
            iban_country_code: ""
        },
        address: {
            domicile: {
                name: null,
                street: "",
                number: "",
                addition: null,
                postal_code: "",
                box: null,
                city: "",
                country: null,
                latitude: "",
                longitude: ""
            },
            residence: {
                name: null,
                street: "",
                number: "",
                addition: null,
                postal_code: "",
                box: null,
                city: "",
                country: null,
                latitude: "",
                longitude: ""
            }
        },
        driving_license: null,
        transport_type: null,
        dependencies: {
            dependent_spouce_id: 0,
            dependent_children: "0"
        },
        emergency_contact: {
            name: "",
            contact_number: "",
            email: "",
            relation: ""
        },
        profile_picture_url: "",
        profile_picture_id: null,
        profile_picture: {},
        front_pic: null,
        back_pic: null,
        front_pic_url: "",
        front_pic_id: null,
        back_pic_url: "",
        back_pic_id: null,
        front_pic_name: null,
        back_pic_name: null,
        work_permit_file_name: null,
        work_permit_file_id: null,
        work_permit_file_url: "",
        user_terms: {
            is_term_condition: false,
            is_contract_sign: false,
            is_temp_employement: false,
            is_gdpr: false
        },
        default_emp_legal: [],
        companies: [],
        role: "employer",
        status: "",
        source: "web",
        signature: "",
        extra_ref: "",
        profile: true,
        contactRole: null,
        start_date: undefined,
        follow_up_type: null
    },
    showPassword: false,
    showConfirmPassword: false,
    loading: false,
    editing: false,
    errors: {},
    ibancountries:[] as any,
};
