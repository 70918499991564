import React, { useState, useEffect } from "react";
import Calendar from "../common/atoms/Calendar";
import { useDispatch } from "react-redux";
import { selectContractType, selectEmployeeScheduleKeysById, selectLocationId, setDates, updateEmployeeDates, updateEmployeeSchedule } from "../../features/planning/planningSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface Shifts {
    start_time: string;
    end_time: string;
    sequence: number;
}
interface Schedule {
    location_id: number;
    project_id?: number;
    cost_center?: number;
    shifts: Shifts[];
}
interface ScheduleMap {
    [date: string]: Schedule;
}
interface Props {
    employee: number;
    planningSchedule: ScheduleMap | undefined;
}
const MonthlySchedule: React.FC<Props> = ({ employee, planningSchedule }) => {
    const [selectedDates, setSelectedDates] = useState<string[]>([]);
    const [schedule, setSchedule] = useState<ScheduleMap>({});

    const monthView = useSelector(selectContractType);
    const locationId = useSelector(selectLocationId);
    const dispatch = useDispatch();

    const defaultShift = { location_id: locationId, shifts: [{ start_time: "", end_time: "", sequence: 1 }] };

    // Get the scheduled dates for the specified employee from the Redux store
    const scheduledDates = useSelector((state: RootState) =>
        selectEmployeeScheduleKeysById(state, employee)
    );

    // Reset schedule and selectedDates when employee or planningSchedule changes
    useEffect(() => {
        if (planningSchedule) {
            setSchedule(planningSchedule);
            setSelectedDates(scheduledDates);
        }
    }, [employee,planningSchedule]);

    const handleDateChange = (dates: string[]) => {
        const sortedDates = dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
        setSelectedDates(sortedDates);
        updateScheduleWithNewDates(sortedDates);
    };

    const updateScheduleWithNewDates = (dates: string[]) => {
        setSchedule(prevSchedule => {
            const updatedSchedule = { ...prevSchedule };
            // Find removed dates (dates present in the schedule but not in the new selected dates)
            const removedDates = Object.keys(updatedSchedule).filter(date => !dates.includes(date));

            // Remove the dates from the schedule that are no longer selected
            removedDates.forEach(date => {
                delete updatedSchedule[date];
            });
            // Add new dates to the schedule for the current employee
            dates.forEach(date => {
                if (!updatedSchedule[date]) {
                    updatedSchedule[date] = defaultShift;
                }
            });
            dispatch(setDates(dates))
            // Dispatch the updated schedule for this specific employee
            dispatch(updateEmployeeDates({
                employee_id: employee,
                start_date: dates[0],
                end_date: dates[dates.length - 1],
            }));

            dispatch(updateEmployeeSchedule({
                employee_id: employee,
                scheduleMap: updatedSchedule,
            }));

            return updatedSchedule;
        });

    };
    return (
        <>
            <Calendar
                dates={selectedDates}
                onDateChange={handleDateChange}
                minDate={new Date()}
                calendarType={monthView}
            />
        </>
    );
};

export default MonthlySchedule;
