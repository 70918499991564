import React, {
  ChangeEvent,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState,
} from "react";

interface CheckBoxFieldProps {
  name: string;
  label?: string;
  customStyle?: React.CSSProperties;
  className?: string;
  id?: string;
  isChecked?: boolean;
  onChangeHandler?: (event: ChangeEvent<HTMLInputElement>) => void;
  isClicked?: MouseEventHandler<HTMLInputElement>;
  disable?: boolean;
  value?: string | number;
  lineHeight?: string | number;
  checkBoxWrapper?: string;
  wrapperClass?: string;
  children?: ReactNode;
  isChildren?: boolean;
  pcViewType?: string; // Added to handle view type
  fontSize?: string;
}

const CheckBoxField: React.FC<CheckBoxFieldProps> = ({
  name,
  label,
  customStyle = {},
  className,
  id = "",
  value,
  isChecked = false,
  onChangeHandler,
  isClicked,
  disable = false,
  lineHeight,
  checkBoxWrapper = "",
  wrapperClass = "d-inline-block",
  children,
  isChildren = false,
  pcViewType, // Added to handle view type
  fontSize,
}) => {
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]); // Added to synchronize the checked state with the prop isChecked

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onChangeHandler?.(event); // Updated to use actual event instead of synthetic
  };

  const handleToggle = () => {
    const newChecked = !checked;
    setChecked(newChecked);

    if (onChangeHandler) {
      const event = {
        target: { checked: newChecked },
      } as React.ChangeEvent<HTMLInputElement>;
      onChangeHandler(event);
    }
  };

  // Determine CSS classes based on view type
  const checkBoxClass =
    pcViewType === "addpc"
      ? "form-check-input rounded-0"
      : "form-check-input rounded-0";

  return (
    <div className={`${checkBoxWrapper} form-check ${wrapperClass}`}>
      <input
        name={name}
        type="checkbox"
        checked={checked}
        id={id}
        value={value}
        disabled={disable}
        onChange={handleChange} // Updated to use handleChange function for better state management
        onClick={isClicked}
        className={`${checkBoxClass} shadow-none cursor-pointer ${className}`}
      />

      {isChildren && (
        <div
          onClick={handleToggle as any}
          className="clickable-container cursor-pointer"
        >
          {children}
        </div>
      )}
      <label
        style={{
          ...customStyle, lineHeight, fontSize: window.innerWidth <= 767
            ? "3.5vw"
            : window.innerWidth <= 1023
              ? "1.95vw"
              : "0.9vw"
        }}
        className={`${disable ? "" : "cursor-pointer"}`}
        htmlFor={id}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setChecked(!checked);
        }}
      >
        {label}
      </label>
    </div>
  );
};

export default CheckBoxField;
