import React from "react";
// Make sure the import statements are like this:
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import LabelField from "../atoms/LabelField";

interface LabelWithCKEditorFieldProps {
  id?:string;
  label?: string;
  name: string;
  value?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isMandatory?: boolean;
  handleChange?: (event: any, editor: any) => void; // CKEditor passes the editor object too
  error?: string;
  className? :string;
}

const LabelWithCKEditorField: React.FC<LabelWithCKEditorFieldProps> = ({
  label,
  id,
  name,
  value,
  placeholder = "",
  isDisabled = false,
  isMandatory,
  handleChange,
  error,
  className,
}) => {
  const config = {
    toolbar: {
      items: [
        'heading',
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subScript',
        'superScript',
        '|',
        'highlight',
        'link',
        '|',
        'blockQuote',
        'alignment',
        '|',
        'outdent',
        'indent',
        'bulletedList',
        'numberedList',
        '|',
        'insertTable',
        '|',
        'undo',
        'redo'
      ],
      placeholder: placeholder,
    },
  };
  return (
    <div>
      <LabelField title={label} isMandatory={isMandatory} />
      <CKEditor
        editor={ClassicEditor as any}
        id={id}
        data={value}
        disabled={isDisabled}
        config={config}
        onChange={handleChange}
      />
      <div className='mt-2 mb-2'>
        {error && <div className='text-danger'>{error}</div>}
      </div>
    </div>
  );
};

export default LabelWithCKEditorField;