import {
  FETCH_FLEX_SALARY,
  UPDATE_FLEX_SALARY,
} from "../../../../routes/ApiEndpoints";
import { ApiCall } from "../../../../services/ApiServices";
import BackLink from "../../../common/atoms/BackLink";
import Button from "../../../common/atoms/Button";
import LabelField from "../../../common/atoms/LabelField";
import Header from "../../../common/layout/Header";
import { t, translate } from "../../translation/Translation";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FlexSalary = () => {
  const [state, setState] = useState({
    data: {
      id: 0,
      min_salary: "",
    },
    regexp: /^[0-9,.]*$/,
    warning: false,
    valueError: false,
  });
  const navigate = useNavigate();

  const loadData = () => {
    ApiCall.getService(FETCH_FLEX_SALARY, "GET")
      .then((response) => {
        if (response.status == "success") {
          let data = response.data
            ? {
                ...response.data,
                min_salary: response.data["min_salary"] || "",
              }
            : { id: 0, min_salary: "" };
          setState({ ...state, data });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateBeforeSave()) return;
    let data = {
      ...state.data,
    };
    await ApiCall.service(UPDATE_FLEX_SALARY, "POST", { data })
      .then((response) => {
        if (response.status == "success") {
          navigate("/config/settings");
        }
      })
      .catch((error) => console.error(error));
  };

  const validateBeforeSave = () => {
    let status = false;
    if (!state.data["min_salary"].trim().length) {
      status = true;
      setState({ ...state, warning: true });
    }
    if (state.warning || state.valueError) status = true;
    return status;
  };

  const handleChange = ({ target: { value } }) => {
    let trimmedValue = value.trim();
    let stateObj = { ...state };
    if (checkDecimalPointError(trimmedValue)) return;
    let inputVal = Number(trimmedValue.replaceAll(",", "."));
    stateObj["warning"] = !trimmedValue.length;
    if (trimmedValue.match(stateObj["regexp"])) {
      stateObj["data"]["min_salary"] = trimmedValue;
      stateObj["valueError"] = trimmedValue.length
        ? inputVal <= 100 && inputVal > 0
          ? false
          : true
        : false;
    }
    setState(stateObj);
  };

  const checkDecimalPointError = (value) => {
    let status = false;
    if (value) {
      let inputVal = value.replace(",", ".");
      let decimals = inputVal.split(".")[1];
      status = decimals && decimals.length > 4 ? true : false;
    }
    return status;
  };

  return (
    <>
      <Header
        headerName={t("Minimum flex salary")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-1">
        <div className="row mb-2">
          <BackLink backLink="/config/settings" />
        </div>
      </div>
      <div className="createpagesBg">
        <LabelField
          title={`Add minimum salary of flex worker here`}
          className="tab-subtitle mb-3"
        />
        <form className="needs-validation" noValidate onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <LabelField
                title={t("Minimum salary of flex worker")}
                isMandatory={true}
              />

              <input
                type="text"
                className={`form-control inputFieldColor ps-2 me-1`}
                value={state.data["min_salary"]}
                placeholder={t("Enter minimum salary of flex worker (0-100)")}
                onChange={(event) => handleChange(event)}
              />

              {state.warning && (
                <small className="text-danger">
                  {t("This field is required")}
                </small>
              )}

              {state.valueError && (
                <div className="text-danger">
                  {t(
                    "Value should be greater than 0 and less than or equal to 100."
                  )}
                </div>
              )}
            </div>

            <div className="col-12 mt-3">
              <Button
                className="searchBtn float-end"
                type="submit"
                title={t("Save")}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default translate(FlexSalary);
