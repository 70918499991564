import React, { useState, useRef, useEffect } from "react";
import { AutoComplete } from "primereact/autocomplete";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import LabelField from "./LabelField";

interface AutocompleteProps {
	label?: string;
	labelClassName?: string;
	isMandatory?: boolean;
	id?: string;
	placeholder?: string;
	isMultiSelect?: boolean;
	onChange: (value: string) => void;
	value: any;
	name?: string;
	isDisabled?: boolean;
	className?: string;
	suggestions: string[];
	handleOptionClick?: (value: string) => void
}
interface ItemsState {
	original: string[]; // Original suggestions
	filtered: string[]; // Filtered suggestions
}


const AutoCompleteDropdown: React.FC<AutocompleteProps> = ({
	label,
	labelClassName,
	isMandatory,
	id,
	placeholder,
	isMultiSelect = false,
	onChange,
	value,
	name,
	isDisabled = false,
	className,
	suggestions,
	handleOptionClick = () => { }
}) => {

	const [items, setItems] = useState<ItemsState>({
		original: suggestions, // Initial suggestions
		filtered: [], // Start with an empty filtered array
	});
	
	useEffect(() => {
		setItems({
			original: suggestions,
			filtered: items.filtered
		})
	}, [suggestions]);

	const autoCompleteRef = useRef<HTMLDivElement>(null);

	const handleSearch = (event: { query: string }) => { // onchange of the input filtering the suggestions
		let filtered = items?.original?.filter((option: string) =>
			option.toLowerCase().includes(event.query.toLowerCase())
		);
		
		setItems((prevState: ItemsState) => ({
			...prevState,
			filtered: filtered
		}));
	};

	return (
		<div className="p-fluid">
			{label && (
				<LabelField
					title={label}
					className={labelClassName}
					isMandatory={isMandatory}
				/>
			)}
			<div ref={autoCompleteRef}>
				<AutoComplete
					name={name}
					value={value}
					suggestions={items.filtered}
					completeMethod={handleSearch}
					onChange={(e) => onChange(e.value)}
					onSelect={(e) => handleOptionClick(e.value)}
					multiple={isMultiSelect}
					placeholder={placeholder}
					inputId={id}
					inputClassName={`autoCompleteInput ${className}`}
					disabled={isDisabled}
				/>
			</div>
		</div>
	);
};

export default AutoCompleteDropdown;
