export const UserStatus = ['Not yet activated', 'Active', 'Inactive'];
export const vacancyStatus = ['Open', 'Closed'];

export const reCaptchaSiteKey = "6Lf-YWAnAAAAAMQdpyIuyzbF65oE1hjGzihyVFWW";
export const ABSOLUTE_JOBS_APP = 'absolute_jobs';
export const ABSOLUTE_CORE_APP = 'absolute-core-web';
export const ABSOLUTE_CORE_MOBILE_APP = 'absolute-core-mobile-app';
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
export const CENTRAL_DATA_MANAGEMENT_MICROSERVICE =  process.env.REACT_APP_MICROSERVICE ?? 'api';
export const PLANNING_MICROSERVICE = 'planning-pcp';
export const COMPANY_MICROSERVICE = 'company-creation';
export const CANDIDATE_MICROSERVICE = 'candidate-creation';
export const IDENTITY_MANAGER_MICROSERVICE = 'identity-manager';
export const WEBCONNECTOR = 'webconnector';
export const REACT_APP_CDM_SERVICE = process.env.REACT_APP_CDM_SERVICE;
export const STUDENT_EMPLOYEE_TYPES = [55, 57];
export const FLEX_EMPLOYEE_TYPES = [58, 59];
export const HORECA_EMPLOYEE_TYPES = [60, 61, 62, 63];
export const MAX_HOURS_FOR_BREAK = 390; //minutes => 6.5 hours 
export const FLEX_MAX_PERCENTAGE = 150; //minutes => 6.5 hours 

export const CandidateStatus = ['Not yet active', 'Active'];
export const formStatus = ['Drafted form', 'Completed form'];

export const CREATE = "create";
export const UPDATE = "update";
export const DELETE = "delete";
export const READ = "read";

export const COMPANY = "company";
export const VACANCY = "vacancies"
export const EMAIL_TEMPLATES = "email_templates";
export const QUESTIONS = "questions";
export const ROLES = "roles";
export const USERS = "users";

export const ADMIN = "ADMIN";
export const SUPER_ADMIN = "SUPER_ADMIN";
export const C_LEVEL = 'C_LEVEL';
export const CANDIDATE_ROLE = 'CANDIDATE';
export const EMPLOYER = 'EMPLOYER';
export const THREECX = 'THREECX';
export const RECRUITER = 'RECRUITER';
export const AREA_MANAGER = "AREA_MANAGER";
export const OFFICE_MANAGER = "OFFICE_MANAGER";

export const SUCCESS = "success";
export const DRAFT = "draft";
export const SUBMIT = "submit";
export const DRAFT_SUCCESS_MSG = "Draft saved successfully";
export const CertificateStatus = ['Inactive', 'Active'];
