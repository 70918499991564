import { startOfWeek, endOfWeek, getWeek } from "date-fns";
import { generateSchedule } from "../../Planning/Services/PlanningHelper"; // Adjust the import path accordingly
import { setDates, setWeekNumber } from "../../../features/planning/planningSlice"; // Adjust import path
import { AppDispatch } from "../../../store";

/**
 * Formats a Date object to a YYYY-MM-DD string.
 * @param date - The date string to format.
 * @returns A string in YYYY-MM-DD format.
 */
export const formatDate = (date: string): string => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
export const formatDateTime = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatDateTimeAny = (date: Date): any => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

/**
 * Generates an array of dates between startDate and endDate in YYYY-MM-DD format.
 * @param startDate - The start date in YYYY-MM-DD format.
 * @param endDate - The end date in YYYY-MM-DD format.
 * @returns An array of date strings in YYYY-MM-DD format.
 */
export const generateDateRange = (startDate: string, endDate: string): string[] => {
  const dates: string[] = [];
  let currentDate = new Date(startDate);
  const end = new Date(endDate);

  while (currentDate <= end) {
    dates.push(formatDate(currentDate.toISOString())); // Pass ISO string to formatDate
    currentDate.setDate(currentDate.getDate() + 1); // Increment date by one day
  }

  return dates;
};


export const updateDates = (
  dispatch: AppDispatch,
  contractType: string,
  dates: string[],
  locationId: number
) => {
  if (contractType && dates.length > 0) {
    console.log("current_Date=> ", dates);
    const date = new Date(dates[0]);

    if (contractType === "week") {
      const start = startOfWeek(date, { weekStartsOn: 1 });
      const end = endOfWeek(date, { weekStartsOn: 1 });

      // Dispatch the start and end dates for the week
      dispatch(setDates([formatDateTime(start), formatDateTime(end)]));

      // Generate and dispatch the schedule
      const schedule = generateSchedule([formatDateTime(start), formatDateTime(end)], locationId);
      dispatch(setWeekNumber({ weekNumber: getWeek(date, { weekStartsOn: 1 }), schedule: schedule }));
    }

    if (contractType === "day") {
      // Dispatch the selected date as the start and end for 'day' contract type
      dispatch(setDates([formatDateTime(date), formatDateTime(date)]));

      // Generate and dispatch the schedule for the day
      const schedule = generateSchedule([formatDateTime(date), formatDateTime(date)], locationId);
      dispatch(setWeekNumber({ weekNumber: getWeek(date, { weekStartsOn: 1 }), schedule: schedule }));
    }
  }
};
// Function to convert decimal hours to HH:mm format
export function convertDecimalToTime(decimalHours: string): string {
  const hours = Math.floor(parseFloat(decimalHours)); // Get the whole number part for hours
  const minutes = Math.round((parseFloat(decimalHours) - hours) * 60); // Get the decimal part for minutes
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`; // Return formatted time
}


// Function to convert decimal hours to HH:mm format
export function addConvertDecimalToTime(shift1: string, shift2: string): string {
  const totalTiming = parseFloat(shift1) + parseFloat(shift2);
  if (totalTiming > 0) {
    const hours = Math.floor(totalTiming); // Get the whole number part for hours
    const minutes = Math.round(((totalTiming) - hours) * 60); // Get the decimal part for minutes
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`; // Return formatted time
  } else {
    return "00:00";
  }
}
