import React, { useEffect, useRef, useState } from "react";
import Header from "../../common/layout/Header";
import { t, translate } from "../translation/Translation";
import { InputText } from "primereact/inputtext";
import InputTextFieldWithLabel from "../../common/molecules/InputTextFieldWithLabel";
import FileUpload from "../../common/molecules/FileUpload";
import {
  LocationFormData,
  fileData,
  formatHouseNumber,
  formatPostalCode,
  formatStreet,
  inputFields,
  intialFileData,
} from "./LocationUtils";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import {
  FILE_URL,
  GET_COMPANIES_BY_UID,
  GET_COUNTRIES,
  LOCATIONS,
  SHIFTS,
} from "../../../routes/ApiEndpoints";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import CustomNotify from "../../common/atoms/CustomNotify";
import MultiSelectField from "../../common/atoms/MultiSelectField";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import BackLink from "../../common/atoms/BackLink";
import Button from "../../common/atoms/Button";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../features/auth/AuthSlice";
import RadioField from "../../common/atoms/RadioField";
import Image from "../../common/atoms/Image";
import LoadImage from "../../../static/images/DummyImage.jpg";
import LabelField from "../../common/atoms/LabelField";
import Icon from "../../../Icon";
import CompanySelectCard from "../../common/atoms/CompanyCards";
import defaultCompanyIcon from "../../../static/images/emptyCompany.png"
import { FullScreenLoading } from "../../common/utlis/FullScreenLoading";


interface Country {
  iso: string;
  value: number;
  label: string;
}

interface CompanyObj {
  value: number;
  label: string;
  url: string;
}

const EditLocation: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const user = useSelector(selectAuth).userId;
  const initialFormData: LocationFormData = {
    location_pic: "",
    location_name: "",
    street: "",
    number: "",
    postal_code: "",
    box: "",
    city: "",
    country: "",
    company_id: [],
  };

  const locationinputFields = inputFields;

  const navigate = useNavigate();
  const [formData, setFormData] = useState<LocationFormData>(initialFormData);
  const [fileDetails, setFileDetails] = useState<fileData>(intialFileData);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [countries, setCountries] = useState<Country[]>([]);
  // const [companies, setCompanies] = useState([]);
  // const [companies, setCompanies] = useState<CompanyObj>();
  const [companies, setCompanies] = useState<CompanyObj>({
    value: 0,
    label: "",
    url: "",
  });
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);
  const [relatedCompanies, setRelatedCompanies] = useState<CompanyObj[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // First, load the countries and then fetch the location
    const loadData = async () => {
      await getCompanies();
      await getCountries();
    };
    loadData();
  }, []);

  // Fetch countries
  const getCountries = async () => {
    try {
      const response = await ApiCall.getService(
        `${GET_COUNTRIES}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == 'success') {
        const countryList = response.data;
        setCountries(countryList); // Set the countries

        // Once countries are set, fetch the location
        fetchLocationById(countryList);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const getCompanies = async () => {
    const response = await ApiCall.getService(
      `get-companies-by-user-id/${user}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status == 'success') {
      // Convert the data to match the CompanyObj interface
      const formattedCompanies = response.data.map((company: any) => ({
        value: company.id,
        label: company.name,
        url: defaultCompanyIcon,
        // url: company.profile_picture_url,
      }));
      setRelatedCompanies(formattedCompanies);
    }
  };

  // Fetch the location by ID
  const fetchLocationById = async (countryList: Country[]) => {
    setLoading(true);
    try {
      const response = await ApiCall.getService(
        `${LOCATIONS}/${id}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 'success') {
        const locationData = response.data;
        console.log(locationData, "loc");
        setFormData({
          location_pic: locationData.location_image_id || "",
          location_name: locationData.name || "",
          street: locationData.address?.street || "",
          number: locationData.address?.number || "",
          postal_code: locationData.address?.postal_code || "",
          box: locationData.address?.box || "",
          city: locationData.address?.city || "",
          country: locationData.address?.country?.id || "",
          company_id: locationData.company_id ? [locationData.company_id] : [],
        });

        setCompanies({
          value: locationData.company_id,
          label: locationData.company.official_name,
          url: defaultCompanyIcon,
        });

        // Pre-fill selected country and companies once data is loaded
        const selectedCountry = countryList.find(
          (country: Country) =>
            country.value === locationData.address?.country?.id
        );

        setSelectedCountry(selectedCountry || null); // Set the selected country
        setFileDetails({
          fileId: locationData.location_image.id,
          fileName: locationData.location_image.file_name,
          filePath: locationData.location_image.file_path,
          fileUrl: locationData.location_image.file_url,    // Please check ths
        });
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleFileUploadResponse = (uploadResponse: any) => {
    if (uploadResponse?.response) {
      setFileDetails({
        fileId: uploadResponse.response.data.fileId,
        fileName: uploadResponse.response.data.fileName,
        filePath: uploadResponse.response.data.filePath,
        fileUrl: uploadResponse.response.data.fileUrl,
      });
      //const fileUrl = uploadResponse.response["file_url"];
      setFormData((prevData) => ({
        ...prevData,
        location_pic: uploadResponse.response.data.fileId || "",
      }));
    }
    else {
      CustomNotify({
        type: "error",
        message: t(uploadResponse),
      });
    }
  };

  // In the JSX, the `SelectWithSearch` component will be populated with the countries and selected country value.
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
    fieldKey: keyof LocationFormData
  ) => {
    // Handle changes for each field
    let value: any = "";
    switch (fieldKey) {
      case "country":
        value = e?.value || "";
        setSelectedCountry(e);
        break;
      case "company_id":
        if (e && e.target) {
          var ids = JSON.parse(e.target.value); // Extract the value from the event (radio button click)
        } else {
          var ids = e; // Use value directly if passed explicitly (e.g., from onClick)
        }
        value = ids ? [ids] : [];
        break;
      default:
        value = e.target.value;
        break;
    }
    setFormData((prevData) => ({ ...prevData, [fieldKey]: value }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldKey]: value ? "" : prevErrors[fieldKey],
    }));
  };

  const handleCompanySelect = (companyId: number | null) => {
    handleInputChange({ target: { value: companyId } }, 'company_id');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    console.log(formData);
    e.preventDefault();
    // Validation and API call to update the location
    try {
      let data = {
        data: {
          formData,
        },
      };
      const response = await ApiCall.service(
        `${LOCATIONS}/${id}`,
        "PUT",
        formData,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == 'success') {
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
        setTimeout(() => {
          navigate("/manage-location");
        }, 1000);
        // }
      } else if (response.status == 'validation-error' || response.status == 'error') {
        const errorData = response.data; // Assuming the error data comes in response.data
        let updatedErrors: Record<string, string> = {};

        // Iterate through the error object and set errors for each field
        Object.keys(errorData).forEach((field) => {
          updatedErrors[field] = errorData[field][0]; // Assign the first error message
        });

        // Update form errors state
        setFormErrors(updatedErrors);

        CustomNotify({
          type: "error",
          message: t(response.message) || t(response.msg),
        });
      }
    } catch (error) {
      console.error("Error saving locations:", error);
    }
  };


  const handleFileDeleteResponse = (deleteResponse: any, index: number) => {
    console.log(deleteResponse);
    setFileDetails({
      fileId: 0,
      fileName: "",
      filePath: "",
      fileUrl: "",
    });
    setFormData((prevData) => ({
      ...prevData,
      location_pic: "",
    }));
  };

  return (
    <>
      <Header
        headerName={t("Edit location")}
        headerClassName="myAccountCardTitle"
      />
      {loading ? (
        <div className="col-12 text-center my-5">
          <FullScreenLoading />
        </div>
      ) : (
        <>
          <div className="search-bar">
            <div className="row">
              <BackLink backLink="/manage-location" />
            </div>
          </div>
          <div className="createpagesBg">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div
                  className={`col-6 position-relative mb-3 ${fileDetails.filePath ? "locationImageUploaded" : "locationImageUpload"
                    }`}
                >
                  <div style={{ width: "fit-content", position: "relative" }}>
                    <FileUpload
                      id="locations"
                      name="location_picture"
                      placeholder={t("Upload file")}
                      label="Upload"
                      file_type={1}
                      isMandatory={false}
                      edit={false}
                      fileId={fileDetails.fileId}
                      fileName={fileDetails.fileName}
                      filePath={fileDetails.filePath}
                      fileUrl={fileDetails.fileUrl}
                      multiple={false}
                      uploadPath="locations"
                      formats=".jpg,.jpeg,.png,.gif"
                      maxFileSize={2}
                      returnFileUploadErrors={(errorInfo) => {
                        console.error('File upload error:', errorInfo)
                      }}
                      fileUploadResponse={handleFileUploadResponse}
                      deleteResponse={handleFileDeleteResponse}
                    />
                    {fileDetails.fileUrl && (
                      <img
                        src={fileDetails.fileUrl}
                        alt={t("Uploaded")}
                        style={{
                          backgroundColor: "var(--color-white)",
                          borderRadius: "50%",
                          boxShadow: "rgb(133, 113, 185) 0.052vw 0vw 0.32vw 0.02vw",
                          width: "4.5vw",
                          height: "4.5vw",
                          objectFit: "cover",
                        }}
                        width="50%"
                        height="50%"
                      />
                    )}
                  </div>
                </div>
                {locationinputFields.map((field, index) =>
                  field.type === "select" ? (
                    <div key={index} className="col-lg-4 col-md-6 multiSelectWrapper">
                      <SelectWithSearch
                        search={true}
                        options={countries}
                        value={selectedCountry}
                        onChange={(e) => handleInputChange(e, field.name)}
                        placeHolder={field.placeholder}
                        isMulti={false}
                        className="country-select"
                        isTranslate={false}
                        isMandatory={field.isMandatory}
                        fieldLabel={t("Country")}
                        title={t("Country")}
                        error={formErrors[field.name]}
                      />
                    </div>
                  ) : (
                    <div key={index} className={`${field.columnSize} mb-3`}>
                      <InputTextFieldWithLabel
                        type="text"
                        className="ps-2"
                        placeholder={t(field.placeholder)}
                        label={t(field.label)}
                        value={formData[field.name]}
                        name={field.name}
                        id={field.id}
                        handleChange={(e) =>
                          handleInputChange(e, field.name as keyof LocationFormData)
                        }
                        isMandatory={field.isMandatory}
                        labelClassName="form-label"
                        colClassName="col-12"
                        error={formErrors[field.name]}
                        isTop={true}
                      />
                    </div>
                  )
                )}
              </div>
              <LabelField
                title={t("To which company would you like to add this location:")}
                className="mb-2"
              />
              <CompanySelectCard
                relatedCompanies={relatedCompanies}
                onSelect={handleCompanySelect}
                initialSelectedCompanyId={formData.company_id[0]}
              />
              <div className="row py-4">
                <div className="col-12">
                  <Button
                    type="submit"
                    className="searchBtn px-3 float-end"
                    title={t("Update location")}
                  />
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );

};

export default translate(EditLocation);
