import React, { useEffect, useState } from "react";

interface AlertProps {
  alertClassName?: string;
  alertBody?: string;
  duration?: number | null; // Optional duration, can be null for indefinite
  onClose?: () => void; // Optional onClose callback
}

const ErrorComponent: React.FC<AlertProps> = ({
  alertClassName,
  alertBody,
  duration = null, // Default to null for indefinite
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Only set a timeout if the duration is a positive number
    if (duration && duration > 0) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose?.(); // Call onClose when the alert is hidden automatically
      }, duration);

      // Cleanup the timer if the component is unmounted
      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  if (!isVisible) {
    return null; // Don't render the component if it's not visible
  }

  return (
    <div
      className={`alert alert-dismissible fade show ${alertClassName}`}
      role="alert"
    >
      {alertBody}
      <button
        type="button"
        className="btn-close shadow-none"
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={() => {
          setIsVisible(false);
          onClose?.(); // Call onClose when manually dismissed
        }}
      ></button>
    </div>
  );
};

export default ErrorComponent;
