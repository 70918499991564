import React, { useState } from "react";
import { t } from "../common/atoms/Constants";
import LabelField from "../common/atoms/LabelField";
import Header from "../common/layout/Header";
import ContractCalendar from "../common/molecules/ContractCalendar";
import OverFlowWrapper from "../common/OverFlowWrapper";
import InvoicesEnquiry from "../Invoies/InvoicesEnquiry";
import InvoiceSettings from "../Invoies/InvoiceSettings";
import ContractTable from "./ContractTable";
import DateAndTimeContract from "../common/atoms/DataWithTimeContract";
import PlanningCalendar from "../common/molecules/PlanningCalendar";

const EmployeeContracts = () => {

  const [selectedDate, setSelectedDate] = useState<Date | [Date, Date]>(new Date());

  // Action
  const handleClick = (actionType: "mail" | "contact") => {
    if (actionType === "mail") {
      const recipientEmail = "example@gmail.com";
      const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipientEmail}`;
      window.open(gmailUrl, "_blank"); // Open the Gmail compose window in a new tab
    } else if (actionType === "contact") {
      // Implement the logic to contact
      alert("Contact icon clicked");
    }
  };

  return (
    <>
      <Header headerName={t("Contracts")} headerClassName="myAccountCardTitle" />

      <div className="row equal-cols">
        <div className="col-8">
          <div className="contentBg ">
            <div className="row">
              <div className="col-12 mb-3">
                <LabelField
                  title={t("Consult your employees' contracts") + ":"}
                />
              </div>
              <div className="col-4">
                <PlanningCalendar
                  isReactCalendar={true}
                />
              </div>
              <div className="col-8 ps-5">
                <DateAndTimeContract
                  isDate={true} 
                  isDay={true}
                  dayClassName="me-2 currentDay"
                  isIcon={true}
                  dataTimeWrapperClassName="d-flex align-items-center justify-content-between dayDateWithIcon text-white"
                  iconFillColor="#fff"
                  weekFormat="long"
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  />
                <OverFlowWrapper className="ContractsLeftWrapper mt-4">
                  <ContractTable />
                </OverFlowWrapper>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="contentBg">
            <LabelField
              title={t("Settings")}
              className="inVoiceHeader colorPrimary poppins-semibold mb-3"
            />
            <InvoiceSettings invoiceTitle={t("Manage contract settings")} />

            <InvoicesEnquiry
              documentsTitle={
                t("Do you have questions about your invoice") + "?"
              }
              handleClick={handleClick} // Pass the handleClick function as a prop
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeContracts;
