import { validateDefEmpLegal, validateEmail, validateMultiChoiceAnswer, validatePhoneNumberWithRegex, validateRequired, validatesLinkedCompanies } from "../../../services/ValidationService";
import { FormErrors } from "../registration/Interfaces";
import { EmployeeInviteState } from "./Interfaces";

export const validate = (state: EmployeeInviteState) => {
	let errors: FormErrors = {};
	errors = validateDefEmpLegal(state.formData.default_emp_legal, errors);
	errors['email'] = validateEmail(state.formData.email);
	errors['phone_number'] = validatePhoneNumberWithRegex(state.formData.phone_number);
	errors['companies'] = validateMultiChoiceAnswer(state.formData.companies);
	if(errors['companies'] === undefined){
        const missingCompanies = validatesLinkedCompanies(state.formData.companies, state.formData.default_emp_legal);
        errors['companies'] = missingCompanies?.length > 0
        ? `Please ensure the selected company has at least one 'Function' and 'Hourly Wage' details.`
        : null;
        state.missing_companies = missingCompanies;
    }
	return {
		validated: Object.values(errors).every(error => error === null || error === '' || error === undefined),
		errors: errors
	};
};