import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Shift,
  ShiftRow,
  Fields,
  ShiftRowType,
  defaultFields,
  fieldLabels,
  initialShiftSates,
  formatTotalValue,
  formatTime,
  calculateTotalTime,
  isValidTime,
  formatName,
  isValidShiftName,
} from "./shifthelper";
import { t, translate } from "../translation/Translation";
import InputTextfield from "../../common/atoms/InputTextField";
import Header from "../../common/layout/Header";
import { time } from "console";
import { ApiCall } from "../../../services/ApiServices";
import { SHIFTS } from "../../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import CustomNotify from "../../common/atoms/CustomNotify";
import "./shifts.css";
import Title from "../../common/atoms/Title";
import Icon from "../../../Icon";
import Button from "../../common/atoms/Button";
import FileUpload from "../../common/molecules/FileUpload";
import LabelField from "../../common/atoms/LabelField";
import { useSelector } from "react-redux";

interface ShiftProps {
  fields?: Fields[];
  onChange?: (shifts: ShiftRowType) => void;
}

const ManageShifts: React.FC<ShiftProps> = ({
  fields = defaultFields,
  onChange,
}) => {

  const companyId = useSelector((state: any) => state?.auth.currentCompany?.id);
  const [shifts, setShifts] = useState<ShiftRowType[]>([initialShiftSates]);
  const [validationErrors, setValidationErrors] = useState<boolean[]>([]);
  const [highlightedErrors, setHighlightedErrors] = useState<boolean[]>([]);

  useEffect(() => {
    getShiftDataByCompanyId();
  }, []);

  const getShiftDataByCompanyId = async () => {

    const response = await ApiCall.getService(
      `${SHIFTS}/${companyId}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status == 'success') {
      if (response.data.shifts.length > 0) {
        setShifts(response.data.shifts);
      }
    }
  };

  const handleShiftChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>,
    shiftType: Fields,
    timeKey?: keyof Shift
  ) => {
    const currentValue =
      shiftType === "total"
        ? formatTotalValue(e.target.value)
        : formatTime(e.target.value);
    const newShifts = [...shifts];

    if (shiftType === "shift_name") {
      newShifts[index] = {
        ...newShifts[index],
        shift_name: formatName(e.target.value), // Update only the shift name at the specific index
      };
      setHighlightedErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = false;
        return newErrors;
      });
    } else {
      // Create a new copy of the shift object for time updates
      const updatedShift = { ...(newShifts[index][shiftType] as Shift) };
      updatedShift[timeKey!] = currentValue;
      // Calculate total time based on shifts
      const otherShift =
        shiftType === "shift1"
          ? newShifts[index].shift2
          : newShifts[index].shift1;
      const totalTime = calculateTotalTime(
        shiftType === "shift1" ? updatedShift : otherShift,
        shiftType === "shift1" ? otherShift : updatedShift
      );
      // Update the newShifts array with the updated shift
      newShifts[index] = {
        ...newShifts[index],
        [shiftType]: updatedShift,
        total: totalTime,
      };
    }
    setShifts(newShifts);
  };

  const addShift = () => {
    if (shifts.length < 10) {
      setShifts([...shifts, initialShiftSates]);
    }
  };
  const validateShifts = () => {
    const errors = shifts.map((shift) => shift.shift_name.trim() === "");
    setValidationErrors(errors);
    setHighlightedErrors(errors);
    return !errors.includes(true);
  };
  const removeShift = (index: number) => {
    const newShifts = shifts.filter((_, i) => i !== index);
    setShifts(newShifts);
  };
  const getCellStyleForInvalidTime = (time: string, field: Fields) => {
    if (field === "total") return {}; // No validation styling for total field
    return !isValidTime(time) && time ? "error-border" : "";
  };
  const getCellStyleForInvalidShiftName = (name: string, field: Fields) => {
    return isValidShiftName(name) ? "" : "error-border";
  };

  const saveShifts = async () => {
    if(shifts.length < 1){
      setShifts([initialShiftSates]);
      return;
    }
    if (!validateShifts()) {
      CustomNotify({
        type: "error",
        message: t("Please fill in all shift names before saving."),
      });
      return;
    }
    try {
      let data = {
        companyId: companyId,
        shiftData: {
          ["shifts"]: shifts,
        },
      };
      const response = await ApiCall.service(
        SHIFTS,
        "POST",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == 'success') {
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
      }
    } catch (error) {
      console.error("Error saving shifts:", error);
    }
  };

  return (
    <>
      <Header
        headerName={t("Manage Shifts")}
        headerClassName="myAccountCardTitle"
      />
      <div className="manageContentBg">
        <div className="row mb-3">
          <div className="col">
            <Title
              title={t("Hourly schedules")}
              className="poppins-semibold colorPrimary mb-3"
            />
            <div className="mb-3">
              <LabelField
                title={t("You can add upto 10 different schedules")}
                className="tab-subtitle"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid manageShiftTable">
          <div className="row">
            {fields.map((day) => (
              <div
                className={`${day === "total" ? "col-2" : "col-3"
                  } text-center shiftNumber`}
              >
                {t(fieldLabels[day])}
              </div>
            ))}
          </div>
          {shifts.map((shift, index) => (
            <div key={index} className="row align-items-center my-2">
              <div className="col-3 d-flex align-items-center">
                <InputTextfield
                  value={shift.shift_name}
                  className={`form-control me-2 ps-2 ${highlightedErrors[index] ? "error-border" : ""
                    }`}
                  handleChange={(e) =>
                    handleShiftChange(index, e, "shift_name")
                  }
                  inputClassWrapper="w-100"
                />
              </div>

              <div className="col-3 d-flex align-items-center">
                <InputTextfield
                  value={shift.shift1.startTime}
                  className={`form-control ps-2 ${getCellStyleForInvalidTime(
                    shift.shift1.startTime,
                    "shift1"
                  )}`}
                  handleChange={(e) =>
                    handleShiftChange(index, e, "shift1", "startTime")
                  }
                />
                <div className="mx-2">{" - "}</div>
                <InputTextfield
                  value={shift.shift1.endTime}
                  className={`form-control ps-2 ${getCellStyleForInvalidTime(
                    shift.shift1.endTime,
                    "shift1"
                  )}`}
                  handleChange={(e) =>
                    handleShiftChange(index, e, "shift1", "endTime")
                  }
                />
              </div>

              <div className="col-3 d-flex align-items-center">
                <InputTextfield
                  value={shift.shift2.startTime}
                  className={`form-control ps-2 ${getCellStyleForInvalidTime(
                    shift.shift2.startTime,
                    "shift2"
                  )}`}
                  handleChange={(e) =>
                    handleShiftChange(index, e, "shift2", "startTime")
                  }
                />
                <div className="mx-2">{" - "}</div>
                <InputTextfield
                  value={shift.shift2.endTime}
                  className={`form-control ps-2 ${getCellStyleForInvalidTime(
                    shift.shift2.endTime,
                    "shift2"
                  )}`}
                  handleChange={(e) =>
                    handleShiftChange(index, e, "shift2", "endTime")
                  }
                />
              </div>

              <div className="col-2 text-center">
                <span className="totalHours">{shift.total}</span>
              </div>

              <div className="col-1 text-start">
                <span
                  onClick={() => removeShift(index)}
                  title={t("Delete")}
                  className="secondaryColorHoverEffect"
                >
                  <Icon isIcon={true} width="1vw" height="1vw" name="delete" />
                </span>
                {index === shifts.length - 1 && shifts.length < 10 && (
                  <span
                    className="secondaryColorHoverEffect ms-2"
                    onClick={addShift}
                    title={t("Add shift")}
                  >
                    <Icon isIcon={true} width="1vw" height="1vw" name="add" />
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="row mt-4">
          <div className="col text-end">
            <Button
              className="searchBtn"
              handleClick={saveShifts}
              title={t("Save shifts")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(ManageShifts);
