import { useEffect, useState } from 'react';

const useWindowWidth = (threshold: number = 1024, onResize?: (isDesktop: boolean) => void) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= threshold);

  useEffect(() => {
    const handleResize = () => {
      const isNowDesktop = window.innerWidth >= threshold;
      setIsDesktop(isNowDesktop);
      if (onResize) onResize(isNowDesktop);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [threshold, onResize]);

  return isDesktop;
};

export default useWindowWidth;
