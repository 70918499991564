import React from "react";
import Icon from "../../../Icon";
import AutoCompleteDropdown from "../atoms/AutoCompleteDropdown";

interface AutoCompleteWithSearchProps {
	placeholder?: string;
	suggestions?: string[];
	value: string;
	handleChange: (value: string) => void;
	handleOptionClick?: (value: string) => void;
	handleIconClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
	className?: string;
}
const AutoCompleteWithSearch: React.FC<AutoCompleteWithSearchProps> = ({
	placeholder,
	suggestions,
	value,
	handleChange,
	handleOptionClick,
	handleIconClick,
	className
}) => {
	return (
		<div className="d-flex align-items-center autoCompleteWithSearchWrapper">
			<AutoCompleteDropdown
				onChange={handleChange}
				handleOptionClick={handleOptionClick}
				suggestions={suggestions || []}
				value={value}
				placeholder={placeholder}
				className={className}
			/>
			<Icon
				name="searchIcon"
				fillColor="#8571b9"
				width="1vw"
				height="1vw"
				className="ms-3"
				isIcon={true}
				onClick={handleIconClick}
			/>
		</div>
	);
};

export default AutoCompleteWithSearch;
