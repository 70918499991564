import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../Icon";
import Button from "../common/atoms/Button";
import Link from "../common/atoms/Link";
import Header from "../common/layout/Header";
import AutoCompleteWithSearch from "../common/molecules/AutoCompleteWithSearch";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import * as ROUTE from "../../routes/RouteConstants";
import {
  selectPlanningShow,
  setShow,
} from "../../features/planning/planningSlice";
import PlanningTabs from "../Planning/PlanningTabs";
import { useDispatch, useSelector } from "react-redux";
import { t } from "../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../services/ApiServices";
import OverFlowWrapper from "../common/OverFlowWrapper";
import BackLink from "../common/atoms/BackLink";
import { COMPANY_EMPLOYEES } from "../../routes/ApiEndpoints";
import NoRecords from "../common/atoms/NoRecords";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";

const EmployeeOverview = () => {
  const navigate = useNavigate();
  const show = useSelector(selectPlanningShow);

  interface DataItem {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    is_fav: boolean;
    profile_picture_url: string | null;
    employee_type: string;
    employee_type_id: number;
    employee_type_category: string;
    employee_type_category_id: number;
    paritaire_commitee: string;
    paritaire_commitee_id: number;
    functional_salary: string;
    function_id: number;
    function_name: string;
  }

  interface State {
    data: DataItem[];
    searchTerm: string;
    suggestions: string[];
    companyId: number;
  }

  const [state, setState] = useState<State>({
    data: [],
    searchTerm: "",
    suggestions: [],
    companyId: 0,
  });

  const companyId = useSelector(
    (state: any) => state?.auth?.currentCompany?.id
  );
  const dispatch = useDispatch();

  // Fetch all employee data once
  const fetchEmployees = async () => {
    const baseUrl = `${COMPANY_EMPLOYEES}`;
    const searchParams = new URLSearchParams({
      company_id: companyId,
    });
    const url = `${baseUrl}?${searchParams.toString()}`;

    try {
      const res = await ApiCall?.getService(
        url,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );
      if (res?.status !== "success") {
        throw new Error("Network response was not ok");
      }

      setState((prevState) => ({
        ...prevState,
        data: res?.data || [],
        companyId,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchEmployees();
    }
  }, [companyId]);

  const handleEmployeeClick = (employee: DataItem) => {
    navigate(`${ROUTE.EMPLOYEE_EDIT.replace(":id", employee.id.toString())}`);
  };

  const toggleFavorite = async (employeeId: number, is_fav: boolean) => {
    try {
      const dataObj = {
        employee_id: employeeId,
        company_id: companyId,
        is_favourite: !is_fav,
      };
      const res = await ApiCall.service(
        "employee-favourite",
        "POST",
        dataObj,
        true
      );
      if (res.status === "success") {
        const updatedData = state.data.map((employee) =>
          employee.id === employeeId
            ? { ...employee, is_fav: !is_fav }
            : employee
        );
        setState((prevState) => ({
          ...prevState,
          data: updatedData,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearchChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      searchTerm: value,
    }));
  };

  // Filter data based on search term
  const filteredData = state.data.filter((employee) => {
    const searchTerm = state.searchTerm?.toLowerCase() || "";
    const firstName = employee?.first_name?.toLowerCase() || "";
    const lastName = employee?.last_name?.toLowerCase() || "";
    const email = employee?.email?.toLowerCase() || "";
    const fullName = `${firstName} ${lastName}`;

   return (
        firstName.includes(searchTerm) ||
        lastName.includes(searchTerm) ||
        email.includes(searchTerm) ||
        fullName.includes(searchTerm) ||
        (employee?.email && employee.email.toLowerCase().includes(searchTerm))
    );
  });

  return (
    <>
      <Header
        headerName={t("Your pool")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-2">
        <div className="row">
          <BackLink backLink="/" />
        </div>
      </div>
      <div className="createpagesBg">
        <div className="row mb-3">
          <div className="col-4 d-flex">
            <Link className="me-3" href="">
              <span title={t("Home")}>
                <Icon
                  isIcon={true}
                  className="secondaryColorHoverEffect"
                  name="houseIcon"
                  width="1.3vw"
                  height="1.3vw"
                />
              </span>
            </Link>
            <AutoCompleteWithSearch
              placeholder={t("Search employee")}
              value={state.searchTerm}
              handleChange={handleSearchChange}
            />
          </div>
          <div className="col-6">
            <Button
              title={t("Employee scheduling")}
              className="saveBtn me-3"
              handleClick={() => dispatch(setShow(true))}
            />
            <Button
              title={t("Add employee")}
              className="saveBtn me-3"
              handleClick={() => navigate(ROUTE.EMPLOYEE_INVITE)}
            />
            <Button
              title={t("Register employee")}
              className="saveBtn"
              handleClick={() => navigate(ROUTE.EMPLOYEE_REGISTRATION)}
            />
          </div>
          <div className="col-2 text-end">
            <div className="d-inline-block me-4 poppins-medium colorPrimary">
              {t("Total") + ":"}
              <span className="ms-1 poppins-semibold">
                {state?.data?.length}
              </span>
            </div>
            <span onClick={() => {}}>
              <Icon name="FilterIcon" width="1vw" height="1vw" />
            </span>
          </div>
        </div>
        <OverFlowWrapper className="yourPoolWrapper">
          <div className="container-fluid ps-0">
            <div className="row">
              {filteredData.length > 0 ? (
                filteredData.map((employee_data) => (
                  <div className="col-3 mb-4" key={employee_data.id}>
                    <EmployeeDetailsCard
                      employee_data={employee_data}
                      cardConfig={{
                        cardClassName:
                          "text-break text-start d-flex align-items-center justify-content-center",
                        employeeInformationClass: "planningEmployeeDetails",
                        cardMainClassName:
                          "employeeDetailsCardPlanning colorPrimary position-relative h-100",
                        iconClassName:
                          "text-end cardEditIcon position-absolute top-0 end-0 cursor-pointer",
                        isCardIcon: true,
                        iconName: "EditIcon",
                        favouriteIconName: "starIcon",
                        noFavouriteIconName: "whiteStarIcon",
                        isFavourite: employee_data.is_fav,
                      }}
                      handleClick={() => handleEmployeeClick(employee_data)}
                      handleFavIcon={() =>
                        toggleFavorite(employee_data.id, employee_data.is_fav)
                      }
                    />
                  </div>
                ))
              ) : (
                <NoRecords isDiv={true} message={t("No employees available")} className="text-danger" />
              )}
            </div>
          </div>
        </OverFlowWrapper>
      </div>
      {show && <PlanningTabs />}
    </>
  );
};

export default EmployeeOverview;
