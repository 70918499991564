import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LabelField from "../atoms/LabelField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import React from "react";
import MaskedTextInput from "react-text-mask";

interface CalenderProps {
  onChange: (date: Date | null, name: string) => void;
  selectedDate?: Date | null;
  dateFormat?: string;
  label?: string;
  isMandatory?: boolean;
  name?: string;
  error?: string | undefined | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  isDisabled?: boolean;
  placeHolder?: string;
  filterDate?: (date: Date) => boolean;
}

const Calender: React.FC<CalenderProps> = ({
  onChange,
  selectedDate,
  label,
  dateFormat = "dd-MM-yyyy",
  isMandatory = false,
  name = "",
  error,
  minDate = null,
  maxDate = null,
  isDisabled = false,
  placeHolder = "dd-mm-yyyy",
  filterDate,
}) => {
  const datePickerRef = useRef<ReactDatePicker | null>(null);

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  return (
    <>
      <LabelField title={label} isMandatory={isMandatory} />
      <ReactDatePicker
        selected={selectedDate}
        onChange={(date) => onChange(date, name || "")}
        showMonthDropdown={false}
        showYearDropdown={false}
        dropdownMode="select"
        dateFormat={dateFormat}
        className="form-control field-shadow rounded-3 ps-2 position-relative inputFieldColor"
        placeholderText={placeHolder}
        minDate={minDate ? new Date(minDate.getTime()) : null}
        maxDate={maxDate ? new Date(maxDate.getTime()) : null}
        calendarStartDay={1}
        name={name}
        customInput={
          <MaskedTextInput
            type="text"
            mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
          />
        }
        closeOnScroll={true}
        fixedHeight
        autoComplete="off"
        ref={datePickerRef}
        disabled={isDisabled}
        filterDate={filterDate}
      />
      <div className="input-group-append calender-icon position-absolute ">
        <span
          className="input-group-text bg-transparent border-0"
          onClick={handleIconClick}
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="fa-lg" />
        </span>
      </div>
      <div style={{lineHeight:"1.2vw"}}>
        {error && <span className="text-danger">{error}</span>}
      </div>
    </>
  );
};

export default Calender;