import React, { Children, ReactNode, useState } from "react";
import Icon from "../../Icon";
import Button from "../common/atoms/Button";
// import { t } from "../common/atoms/translation";
import DateAndTime from "../common/atoms/DateWithTime";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setCompanyId,
  setLocationId,
  setShow,
} from "../../features/planning/planningSlice";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { useSelector } from "react-redux";
import {
  selectAuth,
  selectCurrentCompany,
} from "../../features/auth/AuthSlice";

interface Location {
  id: number;
  location: string;
}
interface PlanningFilterSectionProps {
  children?: ReactNode;
  type?: string;
  page?: string;
  location?: Location | undefined;
}

const PlanningFilterSection: React.FC<PlanningFilterSectionProps> = ({
  children,
  type = "day",
  page = "default",
  location,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentCompanyId = useSelector(selectCurrentCompany)?.id;

  const IconProps = {
    width: "1vw",
    height: "1vw",
    isIcon: true,
  };
  const dateIconProps = {
    iconFillColor: "#8571b9",
    outline: "0.2vw solid #8571b9",
    outlineOffset: "0vw",
    borderRadius: "50%",
  };
  const handleClickDispatch = () => {
    dispatch(setShow(true));
    dispatch(setCompanyId(currentCompanyId));
    if (location != undefined) {
      dispatch(setLocationId(location.id));
    }
  };
  return (
    <div className="col-12 my-3">
      <div className="row">
        <div className="col-2">
          <div>
            <span data-bs-toggle="tooltip" title={t("Filter")}>
              <Icon
                name="FilterIcon"
                className="me-2"
                {...IconProps}
                fillColor="#8571b9"
              />
            </span>
            {page != "dashboard" && (
              <span data-bs-toggle="tooltip" title={t("Print")}>
                <Icon
                  name="printIcon"
                  className="me-2"
                  {...IconProps}
                  fillColor="#8571b9"
                />
              </span>
            )}
            <span data-bs-toggle="tooltip" title={t("QR Code")}>
              <Icon
                name="qrCode"
                className="me-2"
                {...IconProps}
                fillColor="#8571b9"
              />
            </span>
          </div>
        </div>
        <div className="col-5">
          <div className="text-end">{children}</div>
        </div>
        <div className="col-5">
          <div className="d-flex align-items-center justify-content-end">
            <Button
              title={t("Employee scheduling")}
              className="saveBtn"
              handleClick={handleClickDispatch}
            />
            {page != "dashboard" && (
              <div className="ms-3">
                <span
                  data-bs-toggle="tooltip"
                  title={t("Day")}
                  onClick={() => navigate("/day-planning-overview")}
                >
                  <Icon
                    name="dayPlanning"
                    className={
                      "me-2 cursor-pointer " + (type == "day" && "active-icon")
                    }
                    {...IconProps}
                    fillColor="#8571b9"
                  />
                </span>
                <span
                  data-bs-toggle="tooltip"
                  title={t("Weekly")}
                  onClick={() => navigate("/weekly-planning-overview")}
                >
                  <Icon
                    name="weeklyPlanning"
                    className={
                      "me-2 cursor-pointer " + (type == "week" && "active-icon")
                    }
                    {...IconProps}
                    fillColor="#8571b9"
                  />
                </span>
                <span
                  data-bs-toggle="tooltip"
                  title={t("Monthly")}
                  onClick={() => navigate("/monthly-planning-overview")}
                >
                  <Icon
                    name="MonthlyPlanning"
                    className={
                      "me-2 cursor-pointer " +
                      (type == "month" && "active-icon")
                    }
                    {...IconProps}
                    fillColor="#8571b9"
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={"d-flex align-items-center justify-content-between"}
      ></div>
    </div>
  );
};

export default translate(PlanningFilterSection);
