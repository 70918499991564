import React, { useEffect, useRef, useState } from "react";
import {
  ADD_LOCATION_MANAGERS,
  COMPANY_EMPLOYEES,
  GET_LOCATION_MANAGERS,
  LOCATIONS,
  LOCATIONS_BY_COMPANY,
  REMOVE_LOCATION_MANAGER,
} from "../../routes/ApiEndpoints";
import { useSelector } from "react-redux";
import { ApiCall } from "../../services/ApiServices";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import { CompanyUsers, LocationObj } from "./locationManagerUtils";
import Header from "../common/layout/Header";
import BackLink from "../common/atoms/BackLink";
import Button from "../common/atoms/Button";
import { useParams } from "react-router-dom";
import LocationCards from "../common/atoms/LocationSelectCards";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import LocationSelectCards from "../common/atoms/LocationSelectCards";
import LabelField from "../common/atoms/LabelField";
import LocationManagersList from "../common/atoms/LocationManagersList";
import NoRecords from "../common/atoms/NoRecords";

interface LocationManagers {
  id: number;
  name: string;
  email: string;
  profile_pic: string | null;
  since: string;
}

const ManageLocationManagers: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const companyId = useSelector((state: any) => state?.auth.currentCompany?.id);
  const [companyusers, setCompanyUsers] = useState<CompanyUsers[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<CompanyUsers[]>([]);
  const [locations, setLocations] = useState<LocationObj[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<number[] | null>(
    null
  );
  const [locationManagers, setLocationManagers] = useState<LocationManagers[]>(
    []
  );

  const dum = [
    {
      id: 1,
      name: "Alice Johnson",
      email: "alice.johnson@example.com",
      profile_pic: "https://example.com/profiles/alice.jpg",
      since: "2023-01-15",
    },
    {
      id: 2,
      name: "Bob Smith",
      email: "bob.smith@example.com",
      profile_pic: null,
      since: "2022-05-21",
    },
    {
      id: 3,
      name: "Carol Williams",
      email: "carol.williams@example.com",
      profile_pic: "https://example.com/profiles/carol.jpg",
      since: "2021-11-03",
    },
    {
      id: 4,
      name: "David Brown",
      email: "david.brown@example.com",
      profile_pic: "https://example.com/profiles/david.jpg",
      since: "2020-07-19",
    },
    {
      id: 5,
      name: "Eve Davis",
      email: "eve.davis@example.com",
      profile_pic: null,
      since: "2019-02-28",
    },
  ];
  

  useEffect(() => {
    if (companyId) {
      getLocationManagers(id);
      getAllEmp();
    }
  }, [companyId]);

  const getLocationManagers = async (id: any) => {
    const response = await ApiCall.getService(
      `${GET_LOCATION_MANAGERS}?location_id=${id}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status == "success") {
      setLocationManagers(response.data);
      filterEmployees(response.data);
    }
  };

  const getAllEmp = async (suggest: Boolean = false) => {
    const url = `${COMPANY_EMPLOYEES}?company_id=${companyId}`;
    try {
      let response = await ApiCall.getService(url, "GET", "api", true);
      if (response.status == "success") {
        console.log(response.data);
        const users: CompanyUsers[] = response.data.map((employee: any) => ({
          value: employee.id,
          label: `${employee.first_name} ${employee.last_name}`,
        }));
        console.log(users);
        setCompanyUsers(users);
        filterEmployees(locationManagers);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const filterEmployees = (managers: LocationManagers[]) => {
    const managerIds = managers?.map((manager) => manager.id);
    setCompanyUsers((prevUsers) =>
      prevUsers.filter((user) => !managerIds.includes(user.value))
    );
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any
  ) => {
    console.log(e);
    setSelectedUsers(e);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const users = selectedUsers.map((employee) => employee.value);
    const payload = {
      company_id: companyId,
      location_id: id,
      user_ids: users,
    };
    try {
      const response = await ApiCall.service(
        ADD_LOCATION_MANAGERS,
        "POST",
        payload,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === "success") {
        getLocationManagers(id);
        getAllEmp();
        setSelectedUsers([]);
      }
    } catch (error) {
      console.error("Error removing manager:", error);
    }
  };

  const handleRemoveManager = async (managerId: number) => {
    try {
      let payload = {
        user_ids: [managerId],
        company_id: companyId,
        location_id: id,
      };
      const response = await ApiCall.service(
        REMOVE_LOCATION_MANAGER,
        "POST",
        payload,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === "success") {
        getLocationManagers(id);
        getAllEmp();
      }
    } catch (error) {
      console.error("Error removing manager:", error);
    }
  };

  return (
    <>
      <Header
        headerName={t("Manage location managers")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar">
        <div className="row">
          <BackLink backLink="/manage-location" />
        </div>
      </div>
      <div className="managelocationBg createpagesBg">
        {locationManagers?.length === 0 ? (
          <NoRecords
            isDiv={true}
            message={t("No manager found, please add one")}
          />
        ) : (
          <LocationManagersList
            managers={dum}
            onRemove={handleRemoveManager}
          />
        )}

        <div className="multiSelectWrapper">
          <SelectWithSearch
            key={1}
            search={true}
            options={companyusers}
            value={selectedUsers}
            onChange={(e) => handleInputChange(e)}
            placeHolder={t("Select employees")}
            isMulti={true}
            className="country-select"
            isTranslate={false}
            isMandatory={true}
            fieldLabel={t("Select employees")}
            title={t("Select employees")}
          />
        </div>

        <div className="row py-4">
          <div className="col-12">
            <Button
              title="Save"
              handleClick={handleSubmit}
              className="searchBtn float-end px-3"
              toolTipName="Click to save"
              disabled={false} // Enable or disable based on your logic
              type="submit" // Submit type is common for save actions in forms
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default translate(ManageLocationManagers);
