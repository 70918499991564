import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { ApiCall } from '../../../services/ApiServices';
import { ABSOLUTE_CORE_APP, ABSOLUTE_CORE_MOBILE_APP, ABSOLUTE_JOBS_APP, CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from '../../../Constants';
import { LOCALE_SOURCES } from '../../../routes/ApiEndpoints';
import { Debounce } from '../../common/utlis/Debounce';

let language = 'nl';
let subscriptionId = 0;
const subscriptions = {};
let translations = {};
let languageChangeCallback = null; // Callback to trigger translation fetch

export function subscribe(callback) {
  const newSubscriptionId = subscriptionId++;
  subscriptions[newSubscriptionId] = callback;
  return newSubscriptionId;
}

export function unsubscribe(subscriptionId) {
  delete subscriptions[subscriptionId];
}

function triggerSubscriptions() {
  Object.values(subscriptions).forEach(callback => callback());
}

export function setDefaultLanguage(lang) {
  language = lang;
}

export function getLanguage() {
  return language;
}

export function setDefaultTranslations(userTranslations) {
  if (Object.keys(translations).length === 0) {
    setTranslations(userTranslations);
  }
}

export function setTranslations(userTranslations) {

  translations = userTranslations;

  triggerSubscriptions();
}

export function setLanguage(lang) {
  language = lang;
  //
  triggerSubscriptions();
  if (languageChangeCallback) {
    languageChangeCallback(lang); // Notify the callback about the language change
  }
}

export function setLanguageChangeCallback(callback) {
  languageChangeCallback = callback;
}

let allNewStrings = [];
export function t(path, args) {

  if (path) {

    const translationKeys = [path.trim()];
    let translation = '';
    let translationObj = translations?.[language];

    if (language === 'en') {

      let translatedData = localStorage.translationStrings ?? JSON.stringify([]);
      let text1 = path.trim(); // remove white spaces before and after the string
      let text2 = text1.replace(/"/g, '\\"'); // add escape sequence \ for double quotes
      let existOrNot = ':"' + text2 + '"';

      var isOnline = typeof window.navigator !== "undefined" && typeof window.navigator.onLine === "boolean" ? window.navigator.onLine : true;
      if (path && translatedData !== undefined && translatedData.includes(existOrNot) !== true && isOnline && localStorage.translationStrings !== undefined) {

       let translationStrings = JSON.parse(localStorage.translationStrings);
      // let translationStrings = [];
        let newString = {
          source: text1 // user added t string
        }
        translationStrings.push(newString);
        localStorage.translationStrings = JSON.stringify(translationStrings);
        allNewStrings.push(newString);

        debounceStoreNewStrings(); // Trigger the debounced API call
      }
    }

    translationKeys.forEach(key => {
      const temp = translationObj && translationObj[key];

      if (temp && typeof temp === 'string') {
        translation = temp;
      } else if (temp && typeof temp === 'object') {
        translationObj = temp;
      }
    });



    if (translation) {
      if (args) {
        Object.keys(args).forEach(key => {
          translation = translation.replace(`{${key}}`, args[key] || '');
        });
      }
    } else {
      return path;
    }
    return translation;
  }
}

function storeNewStringsForComponent() {
  if (!allNewStrings || allNewStrings.length === 0) {
    return; // No new strings to store
  }

  const data = allNewStrings.map(string => ({
    text: string.source,
  }));
  data.application = ABSOLUTE_CORE_APP;


  ApiCall.service(LOCALE_SOURCES, 'POST', data, false, CENTRAL_DATA_MANAGEMENT_MICROSERVICE)
  .then(res => {
    allNewStrings.length = 0; // Clear the global array after successful storage
    return;
  })
  .catch(error => {

  });
}

// Create a debounced version of storeNewStringsForComponent
const debounceStoreNewStrings = Debounce(storeNewStringsForComponent, 5000);

// Translate function
export function translate(Component) {
  const componentName = Component.displayName || Component.name || 'Component';

  class TranslatedComponent extends React.Component {
    subscriptionId = null;

    componentDidMount() {
      this.subscriptionId = subscribe(() => this.forceUpdate());
    }

    componentWillUnmount() {
      if (this.subscriptionId !== null) {
        unsubscribe(this.subscriptionId);
      }
      debounceStoreNewStrings(); // Ensure any remaining strings are stored when the component unmounts
    }

    render() {
      return React.createElement(Component, { ...this.props, t });
    }
  }

  return hoistStatics(TranslatedComponent, Component);
}

export default {
  setDefaultLanguage,
  getLanguage,
  setDefaultTranslations,
  setTranslations,
  setLanguage,
  translate,
  subscribe,
  unsubscribe,
  t,
};
