import React, { useEffect, useState } from "react";
import Icon from "../../../Icon";

interface DateAndTimeContractProps {
  dataTimeWrapperClassName?: string;
  dataTimeClassName?: string;
  isDay?: boolean;
  isDate?: boolean;
  isTime?: boolean;
  isPipe?: boolean;
  dayClassName?: string;
  isIcon?: boolean;
  showYear?: boolean;
  monthFormat?: "short" | "long";
  weekFormat?: "short" | "long";
  showMonth?: boolean;
  showDate?: boolean;
  iconFillColor?: string;
  isMonth?: boolean;
  isWeek?: boolean;
  onDateChange?: (newDate: Date | Date[]) => void;
  isWeekDate?: boolean;
  selectedDate: Date | Date[];
  setSelectedDate?: (dates: Date | [Date, Date]) => void;
}

const DateAndTimeContract: React.FC<DateAndTimeContractProps> = ({
  dataTimeClassName,
  dataTimeWrapperClassName,
  isDate = false,
  isDay = false,
  isTime = false,
  isPipe = false,
  dayClassName,
  isIcon = false,
  showYear = false,
  monthFormat = "short",
  weekFormat = "short",
  showMonth,
  showDate,
  iconFillColor,
  isMonth = false,
  isWeek = false,
  onDateChange,
  isWeekDate = false,
  selectedDate,
  setSelectedDate,
}) => {
  const dateTimeIconProps = {
    isIcon: true,
    width: "0.8vw",
    height: "0.8vw",
    outline: "0.2vw solid #fff",
    outlineOffset: "0vw",
    borderRadius: "50%",
  };

  // Determine if selectedDate is an array or a single date
  const isRange = Array.isArray(selectedDate);
  const [dateTime, setDateTime] = useState(
    isRange ? selectedDate[0] : selectedDate
  );

  useEffect(() => {
    setDateTime(isRange ? selectedDate[0] : selectedDate);
  }, [selectedDate, isRange]);

  const formatDate = (options: Intl.DateTimeFormatOptions) =>
    dateTime.toLocaleDateString("en-GB", options);

  const formattedDay = formatDate({ weekday: weekFormat });

  const formattedDate = formatDate({
    ...(showDate && { day: "2-digit" }),
    ...(showMonth && { month: monthFormat }),
    ...(showYear && { year: "numeric" }),
  });

  const handleDateChange = (amount: number) => {
    const updatedDate = new Date(dateTime);
    if (isRange) {
      isDay = false;
      isWeek = true;
    } else {
      isDay = true;
      isWeek = false;
    }

    let start: Date = new Date(updatedDate);
    let end: Date;

    // alert(
    //   ", isDay :" +
    //     isDay +
    //     ", isWeek :" +
    //     isWeek +
    //     " , isMonth :" +
    //     isMonth +
    //     " , isRange :" +
    //     isRange
    // );

    if (isDay) {
      updatedDate.setDate(updatedDate.getDate() + amount);
      if (setSelectedDate) {
        setSelectedDate(updatedDate);
      }
      start = updatedDate; // Set start for consistency
    } else if (isWeek) {
      start = new Date(updatedDate);
      end = new Date(updatedDate);
      start.setDate(updatedDate.getDate() + amount * 7);
      end.setDate(updatedDate.getDate() + (amount * 7) + 6); // Assuming range spans a full week
  
      // Ensure setSelectedDate is defined before calling it
      if (setSelectedDate) {
        setSelectedDate([start, end]);
      }
    }
    setDateTime(start);
  };

  return (
    <div className={dataTimeWrapperClassName}>
      {isIcon && (
        <span
          className="backIconWrapper cursor-pointer"
          onClick={() => handleDateChange(-1)}
        >
          <Icon
            name="backIcon"
            {...dateTimeIconProps}
            className="mx-2 prevIcon"
            fillColor={iconFillColor}
          />
        </span>
      )}

      <div className={dataTimeClassName}>
        {isRange ? (
          <>
            <span className={dayClassName}>
              {selectedDate[0].toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </span>
            to
            <span className={dayClassName}>
              {" "}
              {selectedDate[1].toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </span>
          </>
        ) : (
          <>
            {isDay && <span className={dayClassName}>{formattedDay}</span>}
            {isDate && <>{formattedDate}</>}
            {isPipe && <span className="mx-2">|</span>}
            {isTime && (
              <>
                {dateTime.toLocaleTimeString("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })}
              </>
            )}
          </>
        )}
      </div>

      {isIcon && (
        <span
          className="nextIconWrapper cursor-pointer"
          onClick={() => handleDateChange(1)}
        >
          <Icon
            name="nextIcon"
            {...dateTimeIconProps}
            className="mx-2 nextIcon"
            fillColor={iconFillColor}
          />
        </span>
      )}
    </div>
  );
};

export default DateAndTimeContract;
