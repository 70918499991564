import React from "react";
import RegisterEmployeeTemplate from "../../components/Employee/registration/templates/RegisterEmployeeTemplate";

const RegisterEmployee: React.FC = () => {
	return (
		<RegisterEmployeeTemplate />
	);
};

export default RegisterEmployee;
