import React, { useEffect, useRef, useState } from "react";
import LabelField from "../common/atoms/LabelField";
import { t } from "../common/atoms/translation";
import Header from "../common/layout/Header";
import OverFlowWrapper from "../common/OverFlowWrapper";
import InvoicesEnquiry from "../Invoies/InvoicesEnquiry";
import InvoiceSettings from "../Invoies/InvoiceSettings";
import DocumentsTable from "./DocumentsTable";
import "./css/Documents.css";
import NoRecords from "../common/atoms/NoRecords";
interface Document {
  id: number;
  name: string;
  isUnread: boolean;
  viewLink: string;
  downloadLink: string;
}

const DocumentsOverview = () => {
  const documents: Document[] = [
    {
      id: 1,
      name: "Employee Contract.pdf",
      isUnread: true,
      viewLink: "/documents/1/view",
      downloadLink: "/documents/1/download",
    },
    {
      id: 2,
      name: "Project Plan.docx",
      isUnread: false,
      viewLink: "/documents/2/view",
      downloadLink: "/documents/2/download",
    },
    {
      id: 3,
      name: "Meeting Notes.txt",
      isUnread: true,
      viewLink: "/documents/3/view",
      downloadLink: "/documents/3/download",
    },
    {
      id: 4,
      name: "Annual Report.pdf",
      isUnread: false,
      viewLink: "/documents/4/view",
      downloadLink: "/documents/4/download",
    },
    {
      id: 1,
      name: "Employee Contract.pdf",
      isUnread: true,
      viewLink: "/documents/1/view",
      downloadLink: "/documents/1/download",
    },
    {
      id: 2,
      name: "Project Plan.docx",
      isUnread: false,
      viewLink: "/documents/2/view",
      downloadLink: "/documents/2/download",
    },
    {
      id: 3,
      name: "Meeting Notes.txt",
      isUnread: true,
      viewLink: "/documents/3/view",
      downloadLink: "/documents/3/download",
    },
    {
      id: 4,
      name: "Annual Report.pdf",
      isUnread: false,
      viewLink: "/documents/4/view",
      downloadLink: "/documents/4/download",
    },
  ];

  const unreadDocuments = documents.filter((doc) => doc.isUnread);
  const readDocuments = documents.filter((doc) => !doc.isUnread);

  // Action

  const handleClick = (actionType: "mail" | "contact") => {
    if (actionType === "mail") {
      const recipientEmail = "example@gmail.com";
      const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipientEmail}`;
      window.open(gmailUrl, "_blank"); // Open the Gmail compose window in a new tab
    } else if (actionType === "contact") {
      // Implement the logic to contact
      alert("Contact icon clicked");
    }
  };

  return (
    <>
      <Header headerName={t("Documents")} headerClassName="myAccountCardTitle" />
      <div className="row">
        <div className="col-8">
          <div className="contentBg">
            <LabelField
              title={t("Unread documents")}
              className="inVoiceHeader colorPrimary poppins-semibold mb-3"
              customStyle={{ paddingLeft: "11%" }}
            />
            <OverFlowWrapper className="DocumentsLeftWRapper scrollBar">
              {unreadDocuments.length > 0 && (
                <>
                  <div className="mb-3">
                    <DocumentsTable documents={unreadDocuments} />
                  </div>
                </>
              )}
              {readDocuments.length > 0 && (
                <>
                  <LabelField
                    title={t("Read documents")}
                    className="tab-title mb-3"
                    customStyle={{ paddingLeft: "11%" }}
                  />
                  <div className="mb-3">
                    <DocumentsTable documents={readDocuments} />
                  </div>
                </>
              )}
              {unreadDocuments.length === 0 && readDocuments.length === 0 && (
                <NoRecords isDiv={true} className="mt-5" message={t("No documents available")} />
              )}
            </OverFlowWrapper>
          </div>
        </div>
        <div className="col-4">
          <div className="contentBg">
            <LabelField
              title={t("Settings")}
              className="inVoiceHeader colorPrimary poppins-semibold mb-3"
            />
            <InvoiceSettings invoiceTitle={t("Manage settings")} />
            <InvoicesEnquiry
              documentsTitle={
                t("Do you have questions about your documents") + "?"
              }
              handleClick={handleClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentsOverview;
