import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ApiCall } from "../../../../services/ApiServices";
import { t } from "../../../CentralDataMangement/translation/Translation";


import Header from "../../../common/layout/Header";
import BackLink from "../../../common/atoms/BackLink";
import LabelField from "../../../common/atoms/LabelField";
import Button from "../../../common/atoms/Button";
import Icon from "../../../../Icon";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";
import OverFlowWrapper from "../../../common/OverFlowWrapper";
import { ADD_HOLIDAY_OCCASION, UPDATE_HOLIDAY_OCCASION } from "../../../../routes/ApiEndpoints";

const OperationHolidays = ({ rows }) => {

    const inputRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    // Extract id from query parameters
    const query = new URLSearchParams(location.search);
    const id = query.get("id");

    // Determine if the operation is an edit or an add based on the URL
    const isEditing = location.pathname.includes("edit");

    const initialRow = rows.find((row) => row.id == id) || {};
    const [state, setState] = useState({
        occasion: isEditing ? initialRow.occasion : "",
        rows,
        editFlow: isEditing,
        editUrl: UPDATE_HOLIDAY_OCCASION,
        createUrl: ADD_HOLIDAY_OCCASION,
        newItems: isEditing ? [initialRow] : [{ id: Date.now(), occasion: "" }],
        nameWarning: false,
        typeName: t("holidays"),
        uniqueError: false,
        duplicates: [],
    });

    const handleInputChange = (index, event) => {
        const { value } = event.target;
        setState((prevState) => {
            const newItems = [...prevState.newItems];
            newItems[index].occasion = value;
            return {
                ...prevState,
                newItems,
                nameWarning: false,
                uniqueError: false,
            };
        });
    };

    const addHoliday = () => {
        setState((prevState) => ({
            ...prevState,
            newItems: [...prevState.newItems, { id: Date.now(), occasion: "" }], // Add a new empty row
        }));
    };

    const removeHoliday = (id) => {
        setState((prevState) => ({
            ...prevState,
            newItems: prevState.newItems.filter((item) => item.id !== id),
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { createUrl, editUrl, newItems, editFlow } = state;

        // Get list of existing names, ignoring the name of the current row being edited (if editFlow is true)
        const existingNames = rows
            .filter((row) => !editFlow || row.id !== initialRow.id) // Exclude current item if in edit mode
            .map((row) => row.occasion.trim().toLowerCase());

        // Check for duplicates in newItems
        const duplicateItems = newItems.filter((item) => {
            const trimmedName = item.occasion.trim().toLowerCase();
            return existingNames.includes(trimmedName);
        });

        if (duplicateItems.length > 0) {
            // If there are duplicates, show an error and prevent submission
            setState((prevState) => ({
                ...prevState,
                uniqueError: true,
                duplicates: duplicateItems.map((item) => item.occasion), // Store duplicate names to show in error
            }));
            return;
        }

        // Check for empty names in newItems
        const hasEmptyName = newItems.some((item) => !item.occasion.trim());

        if (hasEmptyName) {
            setState((prevState) => ({ ...prevState, nameWarning: true }));
            if (inputRef.current) {
                inputRef.current.focus(); // Only call focus if inputRef is not null
            }
            return;
        }

        // Prepare items to submit
        const itemsToSubmit = newItems.filter((item) => item.occasion.trim());

        try {
            if (editFlow) {
                // Update single  holiday occasion
                const apiUrl = `${editUrl}/${newItems[0].id}`;
                console.log(apiUrl, "URL");
                const result = await ApiCall.service(apiUrl, "PUT", {
                    occasion: newItems[0].occasion,
                });
                if (result.status) {
                    navigate('/manage/holidays')
                }
            } else {
                // Add multiple new holidays
                const result = await ApiCall.service(createUrl, "POST", {
                    holidayOccasions: itemsToSubmit,
                });
                if (result.status) {
                    // navigate('/manage/holidays')
                    window.location.reload();
                }
            }
        } catch (error) {
            console.error("Error occurred:", error);
        }
    };
    return (
        <>

            <Header
                headerName={
                    state.editFlow
                        ? t("Edit holiday")
                        : t("Add holiday")
                }
                headerClassName="myAccountCardTitle"
            />

            <div className="search-bar pb-2">
                <div className="row">
                    <BackLink backLink="/manage/holidays" />
                    <div className="col-6 text-end"></div>
                </div>
            </div>

            <div className="createpagesBg">
                <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                    <OverFlowWrapper className="addEmployeeTypeClass container-fluid ps-0">
                        {state.newItems.map((item, index) => (
                            <div className="row " key={item.id}>
                                <>
                                    <div className={`${!state.editFlow ? "col-11" : "col-12"} mb-2`}>
                                        <LabelWithInputField
                                            label={t("Holiday")}
                                            handleChange={(event) => handleInputChange(index, event)}
                                            placeholder={t("Holiday")}
                                            ref={index === 0 ? inputRef : null}
                                            type="text"
                                            value={item.occasion}
                                            className={` ${state.nameWarning && !item.occasion ? "border-danger" : ""
                                                } ps-2 me-2`}
                                        />
                                    </div>
                                    {!state.editFlow && (
                                        <div className="col-1 seachBtnWrapper">
                                            {index > 0 && (
                                                <span
                                                    onClick={() => removeHoliday(item.id)}
                                                    title={t("Remove")}
                                                    className="secondaryColorHoverEffect removeUploadedFile me-2"
                                                >
                                                    <Icon
                                                        isIcon={true}
                                                        width="1vw"
                                                        height="1vw"
                                                        name="RemoveIcon"
                                                    />
                                                </span>
                                            )}
                                            {index === state.newItems.length - 1 && (
                                                <span
                                                    onClick={addHoliday}
                                                    className="secondaryColorHoverEffect"
                                                    title={t("Add")}
                                                >
                                                    <Icon
                                                        isIcon={true}
                                                        width="1vw"
                                                        height="1vw"
                                                        name="add"
                                                    />
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </>
                            </div>
                        ))}
                        {state.nameWarning && (
                            <small className="text-danger">
                                {t("Please provide a name for all holidays")}
                            </small>
                        )}
                        {state.uniqueError && (
                            <div className="text-danger">
                                {t("The following holiday name already exist:")}{" "}
                                {state.duplicates.join(", ")}
                            </div>
                        )}
                    </OverFlowWrapper>
                    <div className="col-12 mt-3">
                        <Button
                            className="searchBtn float-end"
                            type="submit"
                            title={state.editFlow ? t("Update") : t("Save")}
                        />
                    </div>
                </form>
            </div>

        </>
    )
}

export default OperationHolidays;