import React from "react";
import Icon from "../../../Icon";

interface TableHeaderWithIconProps {
  title?: string;
  TableHeaderWithIconClassName?: string;
  TableHeaderTitle?: string;
  onPreviousClick?: () => void; // New prop for previous click
  onNextClick?: () => void; // New prop for next click
}

const TableHeaderWithIcon: React.FC<TableHeaderWithIconProps> = ({
  title,
  TableHeaderWithIconClassName,
  TableHeaderTitle,
  onPreviousClick,
  onNextClick,
}) => {
  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
    fillColor: "#fff",
  };

  return (
    <div className={`d-flex align-items-center justify-content-between ${TableHeaderWithIconClassName}`}>
      <div className="prevWhiteIconWrapper">
        <Icon name="backIcon" {...IconProps} onClick={onPreviousClick} /> {/* Call onPreviousClick */}
      </div>
      <div className={`${TableHeaderTitle}`}>{title}</div>
      <div className="nextWhiteIconWrapper">
        <Icon name="nextIcon" {...IconProps} onClick={onNextClick} /> {/* Call onNextClick */}
      </div>
    </div>
  );
};

export default TableHeaderWithIcon;
