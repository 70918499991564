import { DefaultEmpLegal } from "../registration/Interfaces";
import { EmployeeInviteState } from "./Interfaces";

export const initialPCstate: DefaultEmpLegal = {
  id: null,
  paritaire_commitee_id: null,
  employee_type_id: null,
  function_id: null,
  minWage: "",
  actual_salary: "",
  is_actual_added: false,
  company_id: null,
};

export const initialstate: EmployeeInviteState = {
  formData: {
    user_id: null,
    first_name: "",
    last_name: "",
    email: "",
    verify_email: "",
    phone_number: "",
    companies: [] as number[],
    default_emp_legal: [initialPCstate],
  },
  errors: {},
  missing_companies:[],
};
