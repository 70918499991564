import React from "react";
import LinkCustom from "./Link";
import TableHeaderSpace from "./TableHeaderSpace";

interface TableHeaderProps {
  label: string;
  width: number;
}

interface ActionItem {
  label: string;
  icon: any;
  handleClick: (value: any) => void;
  iconClassName: string;
}
interface TableProps {
  headers: TableHeaderProps[];
  content: any;
  isSNo: boolean;
  actionlabel?: string;
  variables?: any;
  action?: ActionItem[];
}

const Table: React.FC<TableProps> = ({
  headers,
  content,
  actionlabel,
  variables,
  action,
  isSNo,
}) => {
  return (
    <>
      <table className="table table-hover">
        <thead className="headSticky">
          <tr className="TableHeader">
            {headers.map((header, index) => (
              <th key={index} style={{ width: `${header.width}%` }}>
                {header.label}
              </th>
            ))}
            {actionlabel && <th>{actionlabel}</th>}
          </tr>
          <TableHeaderSpace headerLength={headers.length} />
        </thead>
        <tbody>
          {content.map((row: any, index: number) => (
            <tr key={row?.id}>
              {/* {isSNo && <td>{index + 1}</td>} */}
              {variables.map((variable: any) => (
                <td key={variable}>
                  {row[variable]}
                  {row[variable] == true && "Active"}
                </td>
              ))}
              <td>
                {action?.map((item: any) => (
                  <LinkCustom
                    key={item.label}
                    className={item.iconClassName}
                    onClick={() => item.handleClick(row)}
                  >
                    <span title={item.label}>{item.icon}</span>
                  </LinkCustom>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Table;
