import React from "react";

interface ImageProps {
  src?: string;
  altName?: string;
  title?:string;
  imageBorderRadius?: string | number;
  imageWidth?: string | number;
  imageHeight?: string | number;
  imageBackgroundColor?: string;
}

const Image: React.FC<ImageProps> = ({
  src,
  altName,
  imageBorderRadius,
  imageWidth,
  title,
  imageHeight,
  imageBackgroundColor,
}) => {
  // Convert borderRadius, width, and height to style object
  const style: React.CSSProperties = {
    borderRadius:
      typeof imageBorderRadius === "number"
        ? `${imageBorderRadius}px`
        : imageBorderRadius,
    width: typeof imageWidth === "number" ? `${imageWidth}px` : imageWidth,
    height: typeof imageHeight === "number" ? `${imageHeight}px` : imageHeight,
    backgroundColor: imageBackgroundColor,
  };

  return (
    <img src={src} alt={altName} title={altName} className="img-fluid mx-auto" style={style} />
  );
};

export default Image;
