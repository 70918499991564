import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../../Icon';
import { t } from '../../atoms/translation';
import useWindowWidth from '../../atoms/WindowWidth';

interface Props {
  handleClick?: () => void; // For function calls
  title?: string;
  iconName?: string;
  buttonClass?: string;
}

const CustomActive: React.FC<Props> = ({ handleClick, title, iconName, buttonClass }) => {
  const isDesktop = useWindowWidth(1024);
  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };
  return (
    <>
      {isDesktop ? (
        <span
          className="secondaryColorHoverEffect cursor-pointer mb-1 text-center me-1"
          title={title}
          onClick={handleClick}
        >
          <Icon
            {...IconProps}
            name={iconName}
          />
        </span>

      ) : (
        <div
          className={`text-decoration-none w-75 d-flex align-items-center justify-content-center mx-auto ${buttonClass}`}
          onClick={handleClick}
        >
          {title}
        </div>
      )}
    </>
  );
};

export default CustomActive;
