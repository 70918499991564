import React, { useState, useContext, useEffect } from "react";
import { PcContext } from "./PcContext";
import {
  GET_PC_END_HOURS,
  STORE_PC_END_HOURS,
} from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import { TextField, FormHelperText } from "@mui/material";
import Title from "../../common/atoms/Title";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import Button from "../../common/atoms/Button";
import LabelField from "../../common/atoms/LabelField";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";

// Helper function to convert time string (HH:MM) to decimal hours
const timeToDecimal = (timeStr) => {
  const [hours, minutes] = timeStr.split(":").map(Number);
  return hours + minutes / 60;
};

// Helper function to format decimal hours as time string (HH:MM)
const decimalToTime = (decimal) => {
  const hours = Math.floor(decimal);
  const minutes = Math.round((decimal - hours) * 60);
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

const AgeTimeLimit = () => {
  const { pc_unique_key, pc_view_type } = useContext(PcContext);
  const [data, setData] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const [errors, setErrors] = useState({}); // State to store validation errors

  useEffect(() => {
    if (pc_unique_key !== "") {
      ApiCall.getService(
        `${GET_PC_END_HOURS}/${pc_unique_key}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      )
        .then((result) => {
          console.log(result.data);
          setData(result.data || []);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [pc_unique_key]);

  const handleTimeChange = (e, index) => {
    const timeStr = e.target.value;
    const newData = [...data];
    newData[index].max_hour = timeToDecimal(timeStr);
    setData(newData);

    // Validate if the time is set
    // const newErrors = { ...errors };
    // if (!timeStr) {
    //   newErrors[index] = "Time is required";
    // } else {
    //   delete newErrors[index];
    // }
    // setErrors(newErrors);

    // setDisableSave(Object.keys(newErrors).length > 0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate all fields before submitting
    const newErrors = {};
    data.forEach((item, index) => {
      if (!item.max_hour) {
        // newErrors[index] = "Time is required";
      }
    });
    // setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      console.log(data);
      ApiCall.service(
        `${STORE_PC_END_HOURS}/${pc_unique_key}`,
        "POST",
        { endHours: data },
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      )
        .then((result) => {
          if (result.status === 201) {
            window.location.reload();
          }
          setDisableSave(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setDisableSave(true);
    }
  };

  return (
    <div className="border p-3 rounded-3 mt-1">
      <OverFlowWrapper className="ageWrapper ">
        <Title
          title={t("Edit end time")}
          className="colorPrimary poppins-medium mb-3 pcTitle"
        />
        <form onSubmit={handleSubmit}>
          <div>
            {data.map((item, index) => (
              <div key={item.id} className="mb-4">
                <LabelField title={`Age ${item.age} years`} />
                <TextField
                  type="time"
                  onChange={(e) => handleTimeChange(e, index)}
                  value={decimalToTime(item.max_hour) || "00:00"}
                  fullWidth
                  error={Boolean(errors[index])}
                  className="endTimeTimeWrapper"
                  focused
                />
                {errors[index] && (
                  <FormHelperText error>{errors[index]}</FormHelperText>
                )}
              </div>
            ))}

            <div className="text-end">
              <Button
                type="submit"
                disabled={disableSave}
                className="searchBtn shadow-none"
              >
                {t("SAVE")}
              </Button>
            </div>
          </div>
        </form>
      </OverFlowWrapper>
    </div>
  );
};

export default translate(AgeTimeLimit);
