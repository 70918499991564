import { Outlet, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addUserDetails, selectAuth } from "../../../features/auth/AuthSlice";
import Header from "./Header";
import Sidebar from "../atoms/Sidebar";
import { ApiCall } from "../../../services/ApiServices";
import { GET_USER_PERMISSIONS } from "../../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";

export const NewRootLayout = () => {
  const authData = useSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const excludedPaths = ["/follow-up", "/add-fare", "/manage-fares", "/manage/follow-up","/dashboard/follow-up"];

  useEffect(() => {
    if (!authData.token) {
      navigate('/login');
    }
    if (authData.token != "") {
      getUserPermissions();
    }
  }, [authData.token])

  const getUserPermissions = async () => {
    const userId = { id: authData.userId };
    const currentRole = authData?.role[0] ?? "";
    await ApiCall.service(
      GET_USER_PERMISSIONS,
      "POST",
      userId && -999,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    ).then((response) => {
      if (response && response?.status === 200) {
        if (currentRole !== "" && response.data.length > 0) {
          const currentRoleData = response.data[currentRole];
          const userPermissions = {
            userPermissions: currentRoleData?.permission,
            isSuperAdmin: currentRoleData?.isSuperAdmin
          }
          dispatch(addUserDetails(userPermissions));
        }

      }
      else {
        console.error(response?.message);
      }
    });
  };

  const shouldApplyStyles = !excludedPaths.includes(location.pathname);

  return (
    <div style={{ display: "flex" }}>
      {authData.role[0] !== "sales_agent" && !excludedPaths.includes(location.pathname) && <Sidebar />}

      <div
        style={
          shouldApplyStyles
            ? { marginLeft: "9.8vw", width: "88.3vw", marginRight: "1.8vw" }
            : {}
        }
        className="container-fluid pageRightSideWrapper"
      >
        <Outlet />
      </div>
    </div>
  );
};

export default NewRootLayout;
