import { t } from "../../../CentralDataMangement/translation/Translation";
import { CODE_LENGTHS } from "../../registration/State";

// Function to validate IBAN
export function isValidIBANNumber(iban: any) {
  if (iban === "") {
    return null;
  }
  const formattedIban = formatIban(iban);
  const code = extractCountryCode(formattedIban);

  // Check if the IBAN length is valid for the country
  if(code!==null){
    if (!isValidLength(formattedIban, code)) {
      return t(`Incorrect length for country code ${code}.`);
    }
  }else {
    return t("Please enter a valid IBAN number.");
  }
  // Rearrange and check the checksum using mod97
  const rearrangedDigits = rearrangeIban(formattedIban, code);
  if (mod97(rearrangedDigits) !== 1) {
    return t("Please enter a valid IBAN number.");
  }
  return null;
}

// Helper function to format IBAN (keep only alphanumeric characters)
function formatIban(iban: any) {
  return String(iban)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, "");
}

// Helper function to extract the country code
function extractCountryCode(iban: any) {
  const match = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  return match ? match[1] : null;
}

// Helper function to validate IBAN length based on country code
function isValidLength(iban: any, countryCode: any) {
  return iban.length === CODE_LENGTHS[countryCode];
}

// Helper function to rearrange IBAN for validation
function rearrangeIban(iban: any, countryCode: any) {
  const rearranged = iban.slice(4) + countryCode + iban.slice(2, 4);
  return rearranged.replace(
    /[A-Z]/g,
    (letter: any) => letter.charCodeAt(0) - 55
  );
}

// Function to calculate mod97 checksum
function mod97(digits: any) {
  let checksum = digits.slice(0, 2);
  for (let offset = 2; offset < digits.length; offset += 7) {
    const fragment = String(checksum) + digits.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}
