import React, { ChangeEvent, ReactNode, useEffect, useRef } from "react";
import IMask, { FactoryOpts } from 'imask';
import LabelField from "../atoms/LabelField";

interface LabelWithMaskInputFieldProps {
  type?: string;
  className?: string;
  placeholder?: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  label?: string;
  value?: string | number | any;
  name?: string;
  id?: string;
  isDisabled?: boolean;
  customStyle?: React.CSSProperties;
  isMandatory?: boolean;
  labelClassName?: string;
  error?: string;
  min?: number;
  step?: number;
  max?: number;
  readOnly?: boolean;
  autoComplete?: string;
  multiLabel?: string;
  isTop?: boolean;
  colClassName?: string;
  labelWrapperClassName?: string;
  children?: ReactNode;
  disableCopyPaste?: boolean;
  mask?: string | FactoryOpts; // Updated type to use IMask's FactoryOpts
  lazy?: boolean;
  placeholderChar?: string;
}

export const LabelWithMaskInputField: React.FC<LabelWithMaskInputFieldProps> = ({
  type = "text",
  className = "",
  placeholder,
  handleBlur,
  label,
  value,
  name,
  id = "",
  isDisabled,
  customStyle,
  isMandatory,
  labelClassName,
  error,
  min,
  step,
  max,
  readOnly,
  autoComplete,
  handleChange,
  multiLabel,
  isTop = false,
  colClassName,
  labelWrapperClassName,
  children,
  disableCopyPaste,
  mask,
  lazy = false,
  placeholderChar = '_',
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const maskRef = useRef<ReturnType<typeof IMask> | null>(null);
  const shouldUseFullWidth = !label || (label && isTop);

  useEffect(() => {
    if (inputRef.current && mask) {
      // Initialize IMask with the provided mask options
      const maskOptions: FactoryOpts = typeof mask === 'string' ? {
        mask,
        lazy,
        placeholderChar
      } : {
        ...mask,
        lazy,
        placeholderChar
      };

      maskRef.current = IMask(inputRef.current, maskOptions);

      // Handle value changes
      maskRef.current.on('accept', () => {
        if (maskRef.current && handleChange) {
          // Create a synthetic event to maintain compatibility with existing handleChange
          const syntheticEvent = {
            target: {
              value: maskRef.current.value,
              name: name
            }
          } as ChangeEvent<HTMLInputElement>;
          
          handleChange(syntheticEvent);
        }
      });

      // Set initial value if provided
      if (value !== undefined) {
        maskRef.current.value = value.toString();
      }
    }

    return () => {
      if (maskRef.current) {
        maskRef.current.destroy();
      }
    };
  }, [mask, lazy, placeholderChar]); // Recreate mask when these props change

  // Update mask value when prop value changes
  useEffect(() => {
    if (maskRef.current && value !== undefined && value !== maskRef.current.value) {
      maskRef.current.value = value.toString();
    }
  }, [value]);

  const handleCopy = (e: React.ClipboardEvent) => {
    if (disableCopyPaste) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    if (disableCopyPaste) {
      e.preventDefault();
    }
  };

  return (
    <>
      {label && (
        <div
          className={`text-start ${
            isTop ? "col-12" : ` ${labelWrapperClassName}`
          }`}
        >
          <LabelField
            title={label}
            className={labelClassName}
            isMandatory={isMandatory}
            htmlfor={id}
            subtitle={multiLabel}
          />
        </div>
      )}
      <div
        className={`text-start ${
          shouldUseFullWidth ? "col-12" : `${colClassName}`
        }`}
      >
        <div>
          <input
            ref={inputRef}
            id={id}
            type={type}
            className={`form-control field-shadow rounded-3 inputFieldColor ${className}`}
            value={value}
            disabled={isDisabled}
            placeholder={placeholder}
            style={customStyle}
            onBlur={handleBlur}
            name={name}
            min={min}
            max={max}
            step={step}
            readOnly={readOnly}
            autoComplete={autoComplete}
            onCopy={handleCopy}
            onPaste={handlePaste}
          />
          {error && <div className="text-danger">{error}</div>}
        </div>
        {children}
      </div>
    </>
  );
};

export default LabelWithMaskInputField;