import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

// Define the interface for employee types
interface EmployeeType {
    label: string;
    value: number;
}

// Define the interface for pc ages
interface PCAge {
    id: number;
    pc_id: number;
    age: number;
    min_sal_percent: string;
    flex_salary: string;
    status: boolean;
    max_hour: string;
    created_at: string;
    updated_at: string;
    created_by: string | null;
    updated_by: string | null;
}

// Define the interface for pc categories and functions
interface FunctionDetails {
    label: string;
    value: number;
    salary: string;
}

interface Category {
    category_name: string;
    category_id: number;
    min_salary: string;
    functions: FunctionDetails[];
}

// Define the interface for pc data
interface PC {
    id: number;
    pc_number: string;
    pc_name: string;
    pc_max_age: number;
    max_timing_hrs: string;
    min_timing_hrs: string;
    type: number;
    employee_types: EmployeeType[];
    pc_ages: PCAge[];
    categories: Category;
}

// Define the interface for the employee data
interface Employee {
    id: number;
    first_name: string;
    last_name: string;
    is_fav: boolean;
    profile_picture_url: string | null;
    employee_type: string | null;
    employee_type_id: number | null;
    employee_type_category: string | null;
    employee_type_category_id: number | null;
    paritaire_commitee: string | null;
    paritaire_commitee_id: number | null;
    functional_salary: number | null;
    function_id: number | null;
}

// Define the initial state interface
interface EmployeeState {
    employees: Employee[];
    pcs: PC[];
}

// Initial state with empty employee array and pc array
const initialState: EmployeeState = {
    employees: [],
    pcs: []
};

// Create a slice for employee and PC data
const employeeSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        // Reducer to set employee data
        setEmployeeData: (state, action: PayloadAction<Employee[]>) => {
            state.employees = action.payload;
        },
        // Reducer to clear employee data
        clearEmployeeData: (state) => {
            state.employees = [];
        },
        // Reducer to set PC data
        setPCData: (state, action: PayloadAction<PC[]>) => {
            state.pcs = action.payload;
        },
        // Reducer to clear PC data
        clearPCData: (state) => {
            state.pcs = [];
        }
    }
});

// Export the actions
export const { setEmployeeData, clearEmployeeData, setPCData, clearPCData } = employeeSlice.actions;

// Selector to get the employee data from the state
export const selectCompanyEmployees = (state: RootState) => state.employees.employees;

// Selector to get the PC data from the state
export const selectPCData = (state: RootState) => state.employees.pcs;
// Selector to get details of a specific PC by its ID
export const selectPCById = (state: RootState, pcId: number) => {
    return state.employees.pcs.find((pc: PC) => pc.id === pcId);
};
// Selector to get a specific function's details within a PC
export const selectFunctionById = (state: RootState, pcId: number, functionId: number) => {
    const pc = state.employees.pcs.find((pc: PC) => pc.id === pcId);
    return pc?.categories.functions.find((func: FunctionDetails) => func.value === functionId);
};
// Selector to get flex salary for a given PC ID
export const selectFlexSalaryByPCId = (
    state: RootState,
    pcId: number
): string | null => {
    const pc = state.employees.pcs.find((pc: PC) => pc.id === pcId);
    return pc?.pc_ages.length ? pc.pc_ages[0].flex_salary : null;
};
// Export the reducer
export default employeeSlice.reducer;
