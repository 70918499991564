import React from "react";
import SvgSprite from "./SvgSprite";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name?: string;
  color?: string;
  width?: number | string;
  height?: number | string;
  size?: number;
  borderRadius?: string | number;
  iconBackgroundColor?: string;
  id?: string;
  fillColor?: string;
  isIcon?: boolean;
  onClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
}

const Icon: React.FC<IconProps> = ({
  name,
  color = "currentColor",
  width,
  height,
  size,
  borderRadius,
  iconBackgroundColor,
  id,
  fillColor,
  isIcon = false,
  onClick,
  ...props
}) => {
  // Convert borderRadius to a style object
  const style: React.CSSProperties = {
    borderRadius:
      typeof borderRadius === "number" ? `${borderRadius}px` : borderRadius,
    backgroundColor: iconBackgroundColor,
  };

  return (
    <>
      <SvgSprite fillColor={fillColor} />
      {isIcon && (
        <svg
          width={width || size}
          height={height || size}
          onClick={onClick && onClick}
          {...props}
          style={style}
          id={id}
        >
          <use xlinkHref={`#${name}`} fill={fillColor} />
        </svg>
      )}
    </>
  );
};

export default Icon;
