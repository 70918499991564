import React, { useState } from "react";
import Icon from "../../../Icon";
import { useDispatch } from "react-redux";
import { selectContractType, setContractType, clearAllSchedules, clearDates, setDates, setWeekNumber, selectLocationId, selectDates } from "../../../features/planning/planningSlice";
import { useSelector } from "react-redux";
import { formatDate } from "../utlis/HelperFunctions";
import {
  startOfWeek,
  endOfWeek,
  getWeek,
} from "date-fns";
import { generateSchedule } from "../../Planning/Services/PlanningHelper";
import { formatDateTime } from "../utlis/dateHelper";


interface calendarIconProps {
  isCalendarIcon?: boolean;
  page?: string
  handleStartDate?: ((date: Date) => void | undefined) | undefined
}
const CalendarIcon: React.FC<calendarIconProps> = ({
  isCalendarIcon = false,
  page,
  handleStartDate
}) => {
  const viewType = useSelector(selectContractType);
  const dispatch = useDispatch();
  const locationId = useSelector(selectLocationId);
  const dates = useSelector(selectDates);



  const handleIconClick = (viewType: "day" | "week" | "month") => {
    const newStartDate = new Date();
    console.log(viewType);

    if (viewType === "day") {
      newStartDate.setDate(newStartDate.getDate());
    } else if (viewType === "week") {
      const startOfWeek = newStartDate.getDate() - newStartDate.getDay();
      newStartDate.setDate(startOfWeek);
    } else if (viewType === "month") {
      newStartDate.setDate(1);
      if (page == "planning") {
        dispatch(clearAllSchedules());
        dispatch(clearDates());
      }
    }
    if (page == "planning") {
      dispatch(setContractType(viewType));
      updateDates(viewType, dates);
    }
    // setActiveStartDate(newStartDate);
    // setViewType(viewType);
  };
  const updateDates = (contractType: string, dates: string[]) => {
    if (contractType && dates.length > 0) {
      // Calculate the start and end dates of the week

      if (contractType === "week") {
        let date = new Date(dates[0]);
        let start = startOfWeek(date, { weekStartsOn: 1 });
        let end = endOfWeek(date, { weekStartsOn: 1 });
        const weekDates: string[] = [];
        let currentDate = start;

        // Loop through each day of the week
        while (currentDate <= end) {
          weekDates.push(formatDateTime(currentDate));
          currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }
        // Dispatch the start and end dates for the week
        dispatch(setDates(weekDates));

        // Generate and dispatch the schedule
        let schedule = generateSchedule(weekDates, locationId);
        dispatch(setWeekNumber({ weekNumber: getWeek(date, { weekStartsOn: 1 }), schedule: schedule }));

      }
      if (contractType === "day") {
        // Dispatch the selected date as the start and end for 'day' contract type
        let date = new Date(dates[0]);

        dispatch(setDates([formatDateTime(date), formatDateTime(date)]));

        // Generate and dispatch the schedule for the day
        let schedule = generateSchedule([formatDateTime(date), formatDateTime(date)], locationId);
        dispatch(setWeekNumber({ weekNumber: getWeek(date, { weekStartsOn: 1 }), schedule: schedule }));
      }
    }
  };

  const getClassName = (iconViewType: "day" | "week" | "month") => {
    return iconViewType === viewType
      ? "active-icon ms-2 cursor-pointer"
      : " nonActiveIcon ms-2 cursor-pointer";
  };

  const CalendarIconProps = {
    width: "1vw",
    height: "1vw",
    isIcon: true,
  };

  return (
    <>
      {isCalendarIcon && (
        <div className="row py-1">
          <div className="col-12">
            <div className="float-end">
              <Icon
                name="dayPlanning"
                {...CalendarIconProps}
                onClick={() => handleIconClick("day")}
                className={getClassName("day")}
              />
              <Icon
                name="weeklyPlanning"
                {...CalendarIconProps}
                onClick={() => handleIconClick("week")}
                className={getClassName("week")}
              />
              <Icon
                name="MonthlyPlanning"
                {...CalendarIconProps}
                onClick={() => handleIconClick("month")}
                className={getClassName("month")}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CalendarIcon;
