import { selectPCById, selectPCData } from "../../../features/planning/employeeSlice"
import { RootState } from "../../../store";
import { t } from "../../CentralDataMangement/translation/Translation";
import { MAX_HOURS_FOR_BREAK } from "../../../Constants";
interface Shifts {
    start_time: string;
    end_time: string;
    sequence: number;
}


export const pcMaxAndMinHrs = (state: RootState, pcId: number, totalHrs: number) => {
    const pcDetails = selectPCById(state, pcId);

    // If pcDetails is undefined or min/max timing hrs are missing, return empty string.
    if (!pcDetails || !pcDetails.min_timing_hrs || !pcDetails.max_timing_hrs) return "";

    const minHrs = parseFloat(pcDetails.min_timing_hrs);
    const maxHrs = parseFloat(pcDetails.max_timing_hrs);

    if (totalHrs < minHrs) {
        return `${t("Cannot plan less than")} ${minHrs} ${t(" hrs")}`;
    } else if (totalHrs > maxHrs) {
        return `${t("Cannot plan more than")} ${maxHrs} ${t(" hrs")}`;
    } else {
        return "";
    }
}

// Helper function to convert decimal hours to HH:mm format
function convertDecimalToTime(decimalHours: string): string {
    const hours = Math.floor(parseFloat(decimalHours));
    const minutes = Math.round((parseFloat(decimalHours) - hours) * 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}


export const ageLevelWarnings = (
    pcId: number,
    age: number,
    state: RootState,
    shifts: Shifts[]
): string[] => {

    // Fetch PC details using the selector
    const pcDetails = selectPCById(state, pcId);
    const warnings: string[] = []; // Array to store warning messages


    // Convert shift times to duration in minutes
    let totalDurationInMinutes = 0;

    // Loop through the shifts to calculate total duration
    for (const shift of shifts) {
        const [startHour, startMinute] = shift.start_time.split(':').map(Number);
        const [endHour, endMinute] = shift.end_time.split(':').map(Number);
        const shiftDuration = (endHour * 60 + endMinute) - (startHour * 60 + startMinute);

        totalDurationInMinutes += shiftDuration;
    }
    // Check for break warning if total duration is greater than or equal to 390 minutes (6.5 hours)
    if (shifts.length == 1 && totalDurationInMinutes >= MAX_HOURS_FOR_BREAK) {
        warnings.push(
            t("Please note that the employee is planned for 6.5 hours or more, which means a legally (not paid) mandatory break of 60 minutes for employees below 18 years old and 30 minutes for employees above 18 years old will be included.")
        );
    }
    // Check if pcDetails and pc_ages are available
    if (!pcDetails || !pcDetails.pc_ages) {
        return warnings;
    }

    // Loop through pc_ages to find if there are any violations based on age and time
    for (const pcAge of pcDetails.pc_ages) {
        if (age === pcAge.age && pcAge.status && pcAge.max_hour != '0') {
            const maxHourFormatted = convertDecimalToTime(pcAge.max_hour);
            // If the input time exceeds the max hour for the given age
            if (isEndTimeExceed(shifts, maxHourFormatted)) {

                // Add the warning message for exceeding max hour
                warnings.push(
                    t("You are planning for an employee") + " " +
                    age + " " +
                    t("years old after") + " " +
                    maxHourFormatted +
                    t(", which is legally not allowed. We suggest you replan.")
                );
            }
        }
    }
    // Return an array of warnings
    return warnings;
};
export const isEndTimeExceed = (shifts: Shifts[], pc_max_hour: string): boolean => {
    const [maxHour, maxMinute] = pc_max_hour.split(':').map(Number);
    const maxTimeInMinutes = maxHour * 60 + maxMinute;

    for (const shift of shifts) {
        let [startHour, startMinute] = shift.start_time.split(':').map(Number);
        let [endHour, endMinute] = shift.end_time.split(':').map(Number);

        // Adjust for shifts crossing midnight
        if (endHour < startHour || (endHour === startHour && endMinute < startMinute)) {
            endHour += 24;
        }

        const startTimeInMinutes = startHour * 60 + startMinute;
        const endTimeInMinutes = endHour * 60 + endMinute;

        // Check if the shift ends after the maximum allowed time
        if (endTimeInMinutes > maxTimeInMinutes) {
            return true;
        }

        // Check for shifts starting before midnight and ending after
        if (startTimeInMinutes <= maxTimeInMinutes && endTimeInMinutes > maxTimeInMinutes) {
            return true;
        }
    }

    return false;
};

