import React from 'react';
import { t, translate } from '../../CentralDataMangement/translation/Translation';
import { selectEmployeeScheduleKeysById, selectLocationId, updateEmployeeSchedule } from '../../../features/planning/planningSlice';
import { RootState } from '../../../store';
import { useSelector, useDispatch } from 'react-redux';

// Define types and interfaces
type DayType = string;

interface Shifts {
  start_time: string;
  end_time: string;
  sequence: number;
}
interface Schedule {
  location_id: number;
  project_id?: number;
  cost_center?: number;
  shifts: Shifts[];
}
interface ScheduleMap {
  [date: string]: Schedule;
}

interface WeeklyCalendarProps {
  startDate: string;
  employee: number;
  planningSchedule: ScheduleMap;
}

const WeeklyCalendar: React.FC<WeeklyCalendarProps> = ({ startDate, planningSchedule, employee }) => {
  const dispatch = useDispatch();

  // Days of the week to be displayed
  const days: DayType[] = [t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat'), t('Sun')];
  const locationId = useSelector(selectLocationId);

  // Get the scheduled dates for the specified employee from the Redux store
  const scheduledDates = useSelector((state: RootState) =>
    selectEmployeeScheduleKeysById(state, employee)
  );

  // Updated helper function to generate an array of dates for the current week
  const getDatesForWeek = (inputDate: string): string[] => {
    const dates: string[] = [];
    const currentDate = new Date(inputDate);
    const dayOfWeek = currentDate.getDay();
    const diff = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust when day is Sunday
    const monday = new Date(currentDate.setDate(diff));

    for (let i = 0; i < 7; i++) {
      const date = new Date(monday);
      date.setDate(monday.getDate() + i);
      dates.push(date.toISOString().slice(0, 10));
    }

    return dates;
  };
  // Generate the dates for the week starting from the provided startDate
  const dates: string[] = getDatesForWeek(startDate);

  // Get the month and year from the startDate
  const startDateObj = new Date(startDate);
  const monthYear = startDateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

  // Helper function to calculate the week number of a given date
  const weekNumber = (date: string): number => {
    const dateObj = new Date(date);
    const firstDayOfYear = new Date(dateObj.getFullYear(), 0, 1);
    const pastDaysOfYear = (dateObj.getTime() - firstDayOfYear.getTime()) / 86400000;
    // Calculate the week number
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  // Calculate the week number for the startDate
  const currentWeekNumber = weekNumber(startDate);

  // Helper function to check if a date is in the past
  const isPastDate = (date: string): boolean => {
    const currentDate = new Date();
    const inputDate = new Date(date);
    // Compare input date with today's date
    return inputDate < currentDate && inputDate.toDateString() !== currentDate.toDateString();
  };

  // Handler for when a date is clicked
  const handleDateClick = (date: string) => {
    if (employee && !isPastDate(date)) {
      // Create a copy of the current schedule
      const updatedSchedule: ScheduleMap = { ...planningSchedule };

      // Toggle the clicked date in the schedule
      if (updatedSchedule[date]) {
        // Remove the date if it already exists in the schedule
        delete updatedSchedule[date];
      } else {
        // Add a new empty schedule entry for the date if it does not exist
        updatedSchedule[date] = { location_id: locationId, shifts: [{ start_time: "", end_time: "", sequence: 1 }] };
      }

      // Dispatch the updated schedule to the Redux store
      dispatch(updateEmployeeSchedule({
        employee_id: employee,
        scheduleMap: updatedSchedule
      }));
    }
  };

  return (
    <div className="weeklyCalendarWrapper">
      <div className="weeklyCalendar">
        <div className="weeklyCalendar__navigation">
          <h2 className="weeklyCalendar__navigation__label m-3">
            <span>{t('Week')} {currentWeekNumber} - {monthYear}</span>
          </h2>
        </div>
        <div className="weeklyCalendar__days">
          <div className="weeklyDaysContianers">
            {days.map((day, index) => (
              <div
                key={day}
                className={`flex items-center weeklyCalendar__tile ${isPastDate(dates[index])
                  ? 'dates-disabled'
                  : scheduledDates.includes(dates[index])
                    ? 'weeklyCalendar__tile--active'
                    : ''}`}
                onClick={() => !isPastDate(dates[index]) && handleDateClick(dates[index])} // Disable click for past dates
              >
                <div className="weeklyCalendar__weekday">{day}</div>
                <div
                  className={`w-8 h-8 flex items-center justify-center rounded-full
                    transition-colors duration-200 cursor-pointer`}
                >
                  {new Date(dates[index]).getDate()}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default translate(WeeklyCalendar);
