import React from "react";
import InviteEmployeeTemplate from "../../components/Employee/invite/templates/InviteEmployeeTemplate";

const InviteEmployee: React.FC = () => {
	return (
		<InviteEmployeeTemplate />
	);
};

export default InviteEmployee;
