import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation here
import EditCoefficientDeviation from "./EditCoefficientDeviation";
import CoefficientOverview from "./CoefficientOverview";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../../../services/ApiServices";
import { FETCH_COEFFICIENT_DEVIATIONS } from "../../../../routes/ApiEndpoints";
import Header from "../../../common/layout/Header";
import { FullScreenLoading } from "../../../common/utlis/FullScreenLoading";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../../Constants";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ManageCoefficientDeviation = (props) => {
  const { entityType, entityId, action, editId } = props;

  const query = useQuery();
  const tab = query.get("tab") || 1;

  const [state, setState] = useState({
    overviewData: [],
    loaded: false,
    headers: [t("Code"), t("Name"), t("Actions")],
    action: action,
    ConstructedCoefficientData: [],
  });

  useEffect(() => {
    fetchData();
  }, [action]);

  const fetchData = async () => {
    if (state.action === 0) {
      try {
        const response = await ApiCall.getService(
          FETCH_COEFFICIENT_DEVIATIONS,
          "GET",
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
          true
        );
        setState((prevState) => ({
          ...prevState,
          overviewData: response.data ? response.data : [],
          loaded: true,
          ConstructedCoefficientData: response.data
            ? ConstructedCoefficientData(response.data)
            : [],
        }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const ConstructedCoefficientData = (data) => {
    let dataObj = {};
    data.forEach((eachObj) => (dataObj[eachObj.id] = eachObj));
    return dataObj;
  };

  return (
    <div>
      {console.log(state.overviewData)}
      {state.loaded || state.action === 1 ? (
        <>
          <Header
            headerName={t(
              state.action === 0
                ? `Manage coefficient deviation`
                : `Edit coefficient deviation`
            )}
            headerClassName="myAccountCardTitle"
          />
          <div className="row">
            <div className="col-md-12">
              {action !== 0 ? (
                <EditCoefficientDeviation
                  entityType={Number(entityType)}
                  entityId={Number(entityId)}
                  editId={Number(editId)}
                  coefficientDetails={
                    state.ConstructedCoefficientData[Number(editId)] || {}
                  }
                  action={action}
                />
              ) : (
                <CoefficientOverview
                  entityType={Number(entityType)}
                  entityId={Number(entityId)}
                  rows={state.overviewData}
                  headers={state.headers}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <FullScreenLoading />
      )}
    </div>
  );
};

export default translate(ManageCoefficientDeviation);
