import React, { useEffect, useState } from "react";
import Icon from "../../Icon";
import IconWithTitle from "../common/molecules/IconWithTitle";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import userIcon from "../../static/images/User.svg";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import AgeIcon from "../common/atoms/AgeIcon";
import { fetchSalary } from "./Services/PlanningSalaryHelper";
import { useDispatch } from "react-redux";
import { updateEmployeeDetails } from "../../features/planning/planningSlice";
import { validateSalary } from "./Validations/PlanningScheduleValidation";
import { FLEX_EMPLOYEE_TYPES } from "../../Constants";
import CustomAlert from "../common/atoms/CustomAlert";

interface Employee {
    company_id: number;
    cost_center_id: number;
    employee_id: number;
    employee_name: string;
    employee_type: string;
    employee_type_id: number;
    profile_picture_url?: string;
    function: string;
    function_id: number;
    location: string;
    location_id: number;
    age: number;
    pc_id: number;
    pc_max_age: number;
    pc: string;
    project_id: number;
    salary: string;
    newSalary: string;
    error: string;
    warning: string;
    salaryError: string;
    salaryWarning: string;
}

interface FunctionOptions {
    label: string;
    value: number;
    salary?: string;
}

interface PcAges {
    id: number;
    pc_id: number;
    age: number;
    min_sal_percent: string;
    status: boolean;
    max_hour: string;
}

interface CategoryOptions {
    category_name: string;
    category_id: number;
    min_salary: string;
    label: string;
    value: number;
    functions: FunctionOptions[];
}

interface PcOptions {
    id: number;
    pc_number: number;
    pc_name: string;
    student_cat: string;
    flex_salary: string;
    pc_max_age: number;
    label: string;
    value: number;
    employee_types: FunctionOptions[];
    pc_ages: PcAges[];
    categories: CategoryOptions[];
}
interface EmployeeData {
    pc?: {
        value: number; // Adjust based on your data types
        label: string;
        pc_max_age?: number;
    };
    employee_type?: {
        value: number; // Adjust based on your data types
        label: string;
    };
    function?: {
        value: number; // Adjust based on your data types
        label: string;
    };
    salary?: number | string; // Depending on how you handle salary
    newSalary?: string; // Keeping it as string to handle formats
}

interface SelectedEmployeesDetailsEditProps {
    onEmployeeSelected: Employee;
    onNextEmployee: () => void;
    onPreviousEmployee: () => void;
    pcs: PcOptions[];
    onUpdateEmployee: (updatedEmployee: EmployeeData) => void;
}

const SelectedEmployeesDetailsEdit: React.FC<
    SelectedEmployeesDetailsEditProps
> = ({
    onEmployeeSelected,
    onNextEmployee,
    onPreviousEmployee,
    pcs,
    onUpdateEmployee,
}) => {
        console.log(onEmployeeSelected.salary, onEmployeeSelected.salaryWarning);
        const [selectedPc, setSelectedPc] = useState<PcOptions | null>(null);
        const [loading, setLoading] = useState(false); // Add loading state
        const [newSalary, setNewSalary] = useState("");
        const [data, setData] = useState({
            error: "",
            warning: "",
            pcError: "",
            employeeTypeError: "",
            functionError: "",
        });
        const dispatch = useDispatch();

        useEffect(() => {
            if (onEmployeeSelected) {
                const defaultPc = pcs.find(
                    (pc: PcOptions) => pc.id === onEmployeeSelected.pc_id
                );
                setSelectedPc(defaultPc || null);
                setNewSalary(onEmployeeSelected.newSalary);
            }
        }, [onEmployeeSelected]);

        const IconProp = {
            width: "1vw",
            isIcon: true,
            height: "1vw",
            fillColor: "#8571b9",
            outline: "0.2vw solid #8571b9",
            outlineOffset: "0vw",
            borderRadius: "50%",
        };
        // Handle PC selection changes
        const handlePcChange = (value: any) => {
            const selected = pcs.find((pc) => pc.id === value.value);
            setSelectedPc(selected || null);
            dispatch(updateEmployeeDetails({
                employee_id: onEmployeeSelected.employee_id,
                pc: value.label,
                pc_id: value.value,
                salaryError: "",
                salaryWarning: "",
            }))
            // Clear PC error when user selects a PC
            setData((prev) => ({ ...prev, pcError: "" }));
        };

        // Handle general field changes
        const handleChange = async (
            field: keyof EmployeeData,
            value: { value: number; label: string }
        ) => {
            let currentSalary = onEmployeeSelected.salary;
            if (selectedPc) {
                setLoading(true); // Start loading when fetching salary
                try {
                    if (field === "employee_type") {
                        currentSalary = await fetchSalary(
                            selectedPc,
                            onEmployeeSelected.function_id,
                            value.value,
                            onEmployeeSelected
                        );
                        let { error, warning } = validateSalary(
                            newSalary,
                            currentSalary,
                            value.value,
                            selectedPc.flex_salary
                        );

                        dispatch(
                            updateEmployeeDetails({
                                employee_id: onEmployeeSelected.employee_id,
                                employee_type_name: value.label,
                                employee_type_id: value.value,
                                salary: currentSalary,
                                salaryError: error ?? "",
                                salaryWarning: warning ?? "",
                            })
                        );
                        setData((prev) => ({ ...prev, employeeTypeError: "" })); // Clear employee type error
                    } else if (field === "function") {
                        currentSalary = await fetchSalary(
                            selectedPc,
                            value.value,
                            onEmployeeSelected.employee_type_id,
                            onEmployeeSelected
                        );
                        let { error, warning } = validateSalary(
                            newSalary,
                            currentSalary,
                            onEmployeeSelected.employee_type_id,
                            selectedPc.flex_salary
                        );
                        dispatch(
                            updateEmployeeDetails({
                                employee_id: onEmployeeSelected.employee_id,
                                function_name: value.label,
                                function_id: value.value,
                                salary: currentSalary,
                                salaryError: error ?? "",
                                salaryWarning: warning ?? "",
                            })
                        );
                        setData((prev) => ({ ...prev, functionError: "" })); // Clear function error
                    }
                } finally {
                    setLoading(false); // Stop loading after fetching is done
                }
            }
        };
        // Handle salary changes
        const handleSalaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setNewSalary(value); // Update the new salary value in the state
            // Call validateSalary function to update the state

            if (selectedPc) {
                let { error, warning } = validateSalary(
                    value,
                    onEmployeeSelected.salary,
                    onEmployeeSelected.employee_type_id,
                    selectedPc.flex_salary
                );
                // console.log('error=>', error)
                // If no error exists in the current state, proceed to dispatch
                dispatch(
                    updateEmployeeDetails({
                        employee_id: onEmployeeSelected.employee_id,
                        newSalary: value,
                        salaryError: error ?? "",
                        salaryWarning: warning ?? "",
                    })
                );
            }
        };

        return (
            <div className="row">
                <div className="employeeSchedulingTable employeeSchedulingTableEdit col-12 px-0">
                    <table className="table mb-0">
                        <tbody>
                            <tr>
                                <td className="align-middle text-start backIconWrapper">
                                    <Icon
                                        name="backIcon"
                                        {...IconProp}
                                        onClick={onPreviousEmployee}
                                    />
                                </td>
                                <td>
                                    <div className="d-inline-flex align-items-center">
                                        <IconWithTitle
                                            src={onEmployeeSelected.profile_picture_url ?? userIcon}
                                            isImage={true}
                                            imageWidth="2vw"
                                            imageHeight="2vw"
                                            imageBorderRadius="50%"
                                            title={onEmployeeSelected.employee_name}
                                            isTitle={true}
                                            labelClassName="colorPrimary poppins-medium ms-2 employeeName"
                                        />
                                        <span className="ms-2">
                                            <AgeIcon
                                                age={onEmployeeSelected.age}
                                                pc_max_age={onEmployeeSelected.pc_max_age}
                                                type={1}
                                            />
                                        </span>
                                    </div>
                                </td>
                                <td className="multiSelectWrapper">
                                    <div className="row">
                                        <div className="col-5">
                                            {/* PC Selection */}
                                            <SelectWithSearch
                                                search={true}
                                                options={pcs.map((pc) => ({
                                                    label: `${pc.pc_name} (${pc.pc_number})`,
                                                    value: pc.id,
                                                }))}
                                                value={
                                                    selectedPc
                                                        ? {
                                                            label: `${selectedPc.pc_name} (${selectedPc.pc_number})`,
                                                            value: selectedPc.id,
                                                        }
                                                        : null
                                                }
                                                onChange={handlePcChange}
                                                placeHolder={t("Select PC")}
                                                name="pc"
                                                selectWrapperClassName="multiSelectBgWhite mb-2"
                                            />
                                            {data.pcError && (
                                                <div className="text-danger pb-1">{data.pcError}</div>
                                            )}
                                        </div>
                                        <div className="col-4">
                                            {/* Employee Type Selection */}
                                            {selectedPc && (
                                                <SelectWithSearch
                                                    search={true}
                                                    options={selectedPc.employee_types || []}
                                                    value={
                                                        selectedPc.employee_types.find(
                                                            (type) =>
                                                                type.value === onEmployeeSelected.employee_type_id
                                                        ) || {}
                                                    } // Default to an empty object if not found
                                                    onChange={(value: any) =>
                                                        handleChange("employee_type", value)
                                                    }
                                                    placeHolder={t("Select Employee Type")}
                                                    name="employee_type"
                                                    selectWrapperClassName="multiSelectBgWhite mb-2"
                                                />
                                            )}
                                            {data.employeeTypeError && (
                                                <div className="text-danger pb-1">
                                                    {data.employeeTypeError}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-3">
                                            {/* Loading spinner or salary display */}
                                            {loading ? (
                                                <div>Loading...</div>
                                            ) : (
                                                <input
                                                    type="text"
                                                    className="form-control border mb-2"
                                                    value={onEmployeeSelected.salary}
                                                    disabled
                                                />
                                            )}
                                        </div>
                                        <div className="col-9">
                                            {/* Function Selection */}
                                            {selectedPc && (
                                                <SelectWithSearch
                                                    search={true}
                                                    options={
                                                        selectedPc.categories?.flatMap(
                                                            (category) => category.functions
                                                        ) || []
                                                    }
                                                    value={
                                                        selectedPc.categories
                                                            ?.flatMap((category) => category.functions)
                                                            .find(
                                                                (func) =>
                                                                    func.value === onEmployeeSelected.function_id
                                                            ) || {}
                                                    } // Default to an empty object if not found
                                                    onChange={(value: any) =>
                                                        handleChange("function", value)
                                                    }
                                                    placeHolder={t("Select Function")}
                                                    name="function"
                                                    selectWrapperClassName="multiSelectBgWhite mb-2"
                                                />
                                            )}
                                            {data.functionError && (
                                                <div className="text-danger pb-1">
                                                    {data.functionError}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-3">
                                            <input
                                                type="text"
                                                className="form-control inputFieldColorWithBg"
                                                value={
                                                    newSalary != undefined && newSalary != ""
                                                        ? newSalary.replace(".", ",").replace(/\s/g, "")
                                                        : ""
                                                }
                                                onChange={handleSalaryChange}
                                                placeholder={t("New Salary")}
                                            />
                                        </div>
                                    </div>
                                </td>
                                {/* <td className="align-end" >
                <Icon name="sendIcon" width="0.8vw" height="0.8vw" fillColor="#8571b9" isIcon={true} onClick={handleSave} />
              </td> */}
                                <td className="align-middle text-end nextIconWrapper">
                                    <Icon name="nextIcon" {...IconProp} onClick={onNextEmployee} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {onEmployeeSelected.salaryError != undefined &&
                    onEmployeeSelected.salaryError != "" && (
                        <CustomAlert
                            variant="danger"
                            iconName="warningIcon"
                            className="warningAndErrorAlert"
                        >
                            {onEmployeeSelected.salaryError}
                        </CustomAlert>
                    )}
                {onEmployeeSelected.salaryWarning != undefined &&
                    onEmployeeSelected.salaryWarning != "" && (
                        <CustomAlert
                            variant="warning"
                            iconName="warningIcon"
                            className="warningAndErrorAlert"
                        >
                            {onEmployeeSelected.salaryWarning}
                        </CustomAlert>
                    )}
                {onEmployeeSelected.error != undefined &&
                    onEmployeeSelected.error != "" && (
                        <CustomAlert
                            variant="danger"
                            iconName="warningIcon"
                            className="warningAndErrorAlert"
                        >
                            {onEmployeeSelected.error}
                        </CustomAlert>
                    )}
                {onEmployeeSelected.warning != undefined &&
                    onEmployeeSelected.warning != "" && (
                        <CustomAlert
                            variant="warning"
                            iconName="warningIcon"
                            className="warningAndErrorAlert"
                        >
                            {onEmployeeSelected.warning}
                        </CustomAlert>
                    )}
            </div>
        );
    };

export default translate(SelectedEmployeesDetailsEdit);
