import React, { MouseEventHandler } from "react";
import Image from "../../common/atoms/Image";
import defaultCompanyIcon from "../../../static/images/emptyCompany.png";
import Icon from "../../../Icon";
import { t } from "../translation/Translation";
import LabelField from "../../common/atoms/LabelField";
import EditIcon from "../../common/molecules/Actions/CustomEdit";
import CustomActive from "../../common/molecules/Actions/CustomActive";
import CUstomActionIcon from "../../common/molecules/Actions/CUstomActionIcon";

interface locationCardDetails {
  isComapny?: boolean;
  companyName?: string;
  //handleEditClick?: MouseEventHandler<HTMLButtonElement>;
  handleEditClick?: () => void;
  handleManageLocation?: MouseEventHandler<HTMLButtonElement>;
  handleManageProject?: MouseEventHandler<HTMLButtonElement>;
  handleIsActive?: () => void;
  handleManageAdministrator?: () => void;
  isProject?: boolean;
  projectName?: string;
  isLocationManager?: boolean;
  locationManager?: string;
  is_active?: boolean;
}

const LocationCard: React.FC<locationCardDetails> = ({
  companyName,
  handleEditClick,
  handleManageLocation,
  handleManageProject,
  handleManageAdministrator,
  handleIsActive,
  isComapny,
  isProject,
  projectName,
  isLocationManager,
  locationManager,
  is_active,
}) => {
  return (
    <div className="employeeDetailsCardPlanning locationCard">
      <div className="row">
        <div className="col-lg-9 align-self-center">
          <Image src={defaultCompanyIcon} imageWidth="3vw" imageHeight="3vw" />
          <LabelField
            title={[
              isComapny ? companyName : "",
              isProject ? projectName : "",
              isLocationManager ? locationManager : "",
            ]
              .filter(Boolean)
              .join(" ")}
          />
        </div>
        <div className="col-lg-3 align-self-center">
          <div
            className="d-grid grid-3"
          >
            {/* Edit icon */}
            <EditIcon handleClick={handleEditClick} />
            {/* Location icon */}
            <CustomActive handleClick={handleIsActive}
              title={is_active ? t("Make inactive") : t("Make active")}
              iconName={is_active ? "InActiveIcon" : "ActiveIcon"}
              buttonClass={`${is_active ? "searchBtn" : "resetBtn"} mb-3`}
            />
            {/* Project */}
            {isProject && (
              <CUstomActionIcon handleClick={() => handleManageProject} title={t("Manage project")} buttonClass="searchBtn" iconName="ManageProject" />
            )}
            {isLocationManager && (
              <CUstomActionIcon handleClick={handleManageAdministrator} title={t("Manage location manager")} buttonClass="searchBtn" iconName="ManageAdmin" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationCard;
