import React from "react";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";
import { t } from "../../../CentralDataMangement/translation/Translation";
import { InviteFormProps } from "../Interfaces";

const InviteForm: React.FC<InviteFormProps> = ({
  formValues,
  formErrors,
  handleChange,
}) => {
  return (
    <div className="mb-3">
      <div className="row mt-3">
        <div className="col-6">
          <div className="row">
            <LabelWithInputField
              label={t("First name") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={formValues.first_name}
              handleChange={handleChange}
              name="first_name"
              id="first_name"
              labelWrapperClassName="col-4 align-self-center"
              autoComplete="off"
              error={formErrors["first_name"] ?? ""}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <LabelWithInputField
              label={t("Last name") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={formValues.last_name}
              handleChange={handleChange}
              name="last_name"
              id="last_name"
              labelWrapperClassName="col-4 align-self-center"
              autoComplete="off"
              error={formErrors["last_name"] ?? ""}
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <div className="row">
            <LabelWithInputField
              label={t("Email") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={formValues.email}
              handleChange={handleChange}
              name="email"
              isMandatory={true}
              id="email"
              labelWrapperClassName="col-4 align-self-center"
              autoComplete="off"
              error={formErrors["email"] ?? ""}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <LabelWithInputField
              label={t("Phone number") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={formValues.phone_number}
              handleChange={handleChange}
              name="phone_number"
              id="phone_number"
              labelWrapperClassName="col-4 align-self-center"
              autoComplete="off"
              error={formErrors["phone_number"] ?? ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteForm;
