import React, { MouseEventHandler } from 'react'
import Icon from '../../../../Icon';
import { t } from '../../atoms/translation';
import useWindowWidth from '../../atoms/WindowWidth';

interface props {
  handleDelete?: MouseEventHandler<HTMLButtonElement>;
  buttonClass?:string;
}
const CustomDelete: React.FC<props> = ({
  handleDelete,
  buttonClass
}) => {
  const isDesktop = useWindowWidth(1024);

  return (
    <>
      {isDesktop ? (
        <span
          title={t("Delete")}
          onClick={handleDelete}
          className="secondaryColorHoverEffect me-1"
        >
          <Icon
            isIcon={true}
            width="0.9vw"
            height="0.9vw"
            name="delete"
          />
        </span>
      ) : (
        <span
          onClick={handleDelete}
          className={`resetBtn text-decoration-none w-75 d-flex align-items-center justify-content-center mx-auto ${buttonClass}`}
        >
          {t("Delete")}
        </span>
      )}
    </>
  )
}

export default CustomDelete
