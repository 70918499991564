import React from "react"; // Add this line if it's missing
// import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import LoginForm from "../components/IdentityManager/authentication/Login/Login";
import WelcomeDashboard from "../components/Dashboard/WelcomeDashboard";
import CreatePermission from "../components/IdentityManager/permissions/CreatePermission";
import ManagePermissions from "../components/IdentityManager/permissions/ManagePermissions";
import RolePage from "../components/IdentityManager/roles/CreateRole";
import ManageRoles, {
  loader as rolesLoader,
} from "../components/IdentityManager/roles/ManageRoles";
// import ManageRoles, { loader as rolesLoader } from "../components/IdentityManager/roles/ManageRoles";
import LinkAppLanguages from "../components/CentralDataMangement/translation/LinkAppLanguages";
import CreateEmailTemplate from "../components/CentralDataMangement/email-templates/CreateEmailTemplate";
import EditEmailTemplate from "../components/CentralDataMangement/email-templates/EditEmailTemplate";
import ManageEmailTemplatesPage from "../components/CentralDataMangement/email-templates/ManageEmailTemplates";
import * as ROUTE from "../routes/RouteConstants";
// import PageNotFound from "../services/RolesAndPermissions/PageNotFound";
// import ManageTranslation from "../components/CentralDataMangement/translation/ManageTranslation";
// import ManageEmailLogs from "../components/CentralDataMangement/email-templates/ManageEmailLogs";
// import AdminDashboard from "../components/Dashboard/admin/AdminDashboard";
// import NewRootLayout from "../components/common/layout/RootLayout";

import EmployeeOverview from "../components/Employee/EmployeeOverview";
import ManageEmployeeType from "../components/CentralDataMangement/employee-types/ManageEmployeeType";
import EmployeeTypeEdit from "../components/CentralDataMangement/employee-types/EmployeeTypeEdit";
import EmployeeTypeAdd from "../components/CentralDataMangement/employee-types/EmployeeTypeAdd";
import EmployeeScheduling from "../components/Planning/EmployeeScheduling";
import WeeklyPlanning from "../components/Planning/WeeklyPlanning";
import PlanningOverview from "../components/Planning/PlanningOverview";
import RegisterEmployee from "../pages/Employee/RegisterEmployee";
import InvoiceOverview from "../components/Invoies/InvoiceOverview";
import EmployeeContracts from "../components/Contracts/EmployeeContracts";
import DocumentsOverview from "../components/Documents/DocumentsOverview";
import PlanningsByLocation from "../components/Planning/PlanningsByLocation";
import DayPlanningOverview from "../components/Planning/DayPlanningOverview";
import MonthlyPlanningOverview from "../components/Planning/MonthlyPlanningOverview";
import WeeklyPlanningOverview from "../components/Planning/WeeklyPlanningOverview";
import LocationDashboard from "../components/Dashboard/LocationDashboard";
import ProfilePage from "../components/IdentityManager/authentication/Login/ProfilePage";
import TimeRegistrationOverview from "../components/TimeRegistration/TimeRegistrationOverview";
import ProcessedTimeRecords from "../components/TimeRegistration/ProcessedTimeRecords";
import ManageTranslation from "../components/CentralDataMangement/translation/ManageTranslation";
import ManageEmailLogs from "../components/CentralDataMangement/email-templates/ManageEmailLogs";
import AdminDashboard from "../components/Dashboard/admin/AdminDashboard";
import PageNotFound from "../services/RolesAndPermissions/PageNotFound";
import NewRootLayout from "../components/common/layout/RootLayout";
// import ManageEmployeeType from '../components/CentralDataMangement/employee-types/ManageEmployeeType';
// import EmployeeTypeEdit from '../components/CentralDataMangement/employee-types/EmployeeTypeEdit';
// import EmployeeTypeAdd from '../components/CentralDataMangement/employee-types/EmployeeTypeAdd';

// Import your route constants
// import * as ROUTE from "../routes/RouteConstants";
import ManageFunctions from "../components/CentralDataMangement/manage-functions/ManageFunctions";
import ManageCategories from "../components/CentralDataMangement/manage-categories/ManageCategories";
import ManageSalaryBenefits from "../components/CentralDataMangement/manage-salary-benefits/ManageSalaryBenefits";
import ManagePc from "../components/CentralDataMangement/manage-pc/ManagePc";
import PcCommon from "../components/CentralDataMangement/manage-pc/PcCommon";
import Layout from "../components/common/layout/Layout";
// import Shifts from "../components/CentralDataMangement/shifts/ManageShifts";
import TimeTable from "../components/CentralDataMangement/shifts/shifts";
import { initialTimeStates } from "../components/common/utlis/PlanningUtility";
// import ShiftsGpt from "../components/CentralDataMangement/shifts/chatgpt";
import ManageShifts from "../components/CentralDataMangement/shifts/ManageShifts";
// import Shifts from "../components/CentralDataMangement/shifts/ManageShift";
import FileUpload from "../components/common/molecules/FileUpload";
import SalaryBenifits from "../components/CentralDataMangement/manage-pc/SalaryBenifits";
import ManageCoefficientPcs from "../components/CentralDataMangement/configurations/manageCoefficientPcs/ManageCoefficientPcs";
import LinkCoefficientEmployeetype from "../components/CentralDataMangement/configurations/manageCoefficientEmployeeTypes/LinkCoefficientEmployeetype";
import AgreementForm from "../components/CooperationAgreement/AgreementForm";
import ManageCooperationAgreement from "../components/CooperationAgreement/ManageCooperationAgreement";
import AddLocation from "../components/CentralDataMangement/locations/AddLocation";

import ManageLocations from "../components/CentralDataMangement/locations/ManageLocations";
import EditLocation from "../components/CentralDataMangement/locations/EditLocation";

import ManageCoefficientDeviationPcs from "../components/CentralDataMangement/configurations/manageCoefficientDeviationPcs/ManageCoefficientDeviationPcs";
import ManageCoefficientTypes from "../components/CentralDataMangement/configurations/manageCoefficients/ManageCoefficientTypes";
import ManageCoefficientDeviationIndex from "../components/CentralDataMangement/configurations/manageCoefficientDeviations/ManageCoefficientDeviationIndex";
import LinkCoefficientDeviationEmployeetype from "../components/CentralDataMangement/configurations/manageCoefficientDeviationEmployeeTypes/LinkCoefficientDeviationEmployeetype";
import ConfigurationDashboard from "../components/CentralDataMangement/configurations/ConfigurationDashboard";
import AddProject from "../components/CentralDataMangement/projects/AddProject";
import ManageProjects from "../components/CentralDataMangement/projects/ManageProjects";
import EditProject from "../components/CentralDataMangement/projects/EditProject";
import AddCostCenter from "../components/CentralDataMangement/costcenters/AddCostCenter";
import ManageCostCenters from "../components/CentralDataMangement/costcenters/ManageCostCenters";
import EditCostCenter from "../components/CentralDataMangement/costcenters/EditCostCenter";
import ReportIssue from "../components/ReportAnIssue/ReportIssue";
import PublicRoute from "./PublicRoute";
import PlanningOverviewNew from "../components/Planning/PlanningOverviewNew";
import InviteEmployee from "../pages/Employee/InviteEmployee";
import ManageSalaryBenefitsEdit from "../components/CentralDataMangement/manage-salary-benefits/ManageSalaryBenefitsEdit";
import ManageLocationNew from "../components/CentralDataMangement/locations/ManageLocationNew";
// import Shifts from "../components/CentralDataMangement/shifts/ManageShift";
import QRCodeDisplay from "../components/Qrcode/QRCodeDisplay";
import ManageAdministrator from "../components/ManageAdministrator/ManageAdministrator";
import AddAdministrator from "../components/ManageAdministrator/AddAdministrator";
import TradeForm from "../components/CrewPlanner/Form/TradeForm";
import FareForm from "../components/CrewPlanner/Form/FareForm";
import ManageLocationManagers from "../components/ManageLocationManager/ManageLocationManagers";
import ManageFares from "../components/CrewPlanner/ManageFares";
import AddTrade from "../components/CrewPlanner/Form/AddTrade";
import ForgotPassword from "../components/IdentityManager/authentication/Login/ForgotPassword";
import ResetPassword from "../components/IdentityManager/authentication/Login/ResetPassword";
import FlexSalary from "../components/CentralDataMangement/configurations/flexSalary/FlexSalary";
import ManageFollowUps from "../components/CrewPlanner/ManageFollowUps";
import Types from "../components/CrewPlanner/Types";
import ManageHolidays from "../components/CentralDataMangement/configurations/manageHolidays/ManageHolidays";
import Sales from "../components/CrewPlanner/Sales";

export const Routes = createBrowserRouter([
  { path: ROUTE.LOGIN, element: <PublicRoute element={<LoginForm />} /> },
  {
    path: ROUTE.EMPLOYEE_REGISTRATION2,
    element: <PublicRoute element={<RegisterEmployee />} />,
  },
  {
    path: ROUTE.FORGOT_PASSWORD,
    element: <PublicRoute element={<ForgotPassword />} />,
  },
  {
    path: ROUTE.RESET_PASSWORD,
    element: <PublicRoute element={<ResetPassword />} />,
  },
  {
    path: "/",
    element: <NewRootLayout />,
    children: [
      { path: "*", element: <PageNotFound /> },
      { path: ROUTE.DASHBOARD, element: <AdminDashboard /> },

      { path: ROUTE.MANAGE_EMPLOYEE_TYPES, element: <ManageEmployeeType /> },
      { path: ROUTE.MANAGE_EMPLOYEE_TYPES_EDIT, element: <EmployeeTypeEdit /> },
      { path: ROUTE.MANAGE_EMPLOYEE_TYPES_ADD, element: <EmployeeTypeAdd /> },

      // Coefficient Types Routes
      {
        path: ROUTE.MANAGE_COEFFICIENT_TYPES,
        element: <ManageCoefficientTypes />,
      },
      {
        path: ROUTE.MANAGE_COEFFICIENT_TYPE_ADD,
        element: <ManageCoefficientTypes />,
      },
      {
        path: ROUTE.MANAGE_COEFFICIENT_TYPE_EDIT,
        element: <ManageCoefficientTypes />,
      },

      // coefficient pcs
      { path: ROUTE.MANAGE_COEFFICIENT_PCS, element: <ManageCoefficientPcs /> },
      {
        path: ROUTE.MANAGE_COEFFICIENT_EMPLOYEE_TYPE_LINKING,
        element: <LinkCoefficientEmployeetype />,
      },

      // coefficient deviation pcs
      {
        path: ROUTE.MANAGE_COEFFICIENT_DEVIATION_PCS,
        element: <ManageCoefficientDeviationPcs />,
      },
      {
        path: ROUTE.MANAGE_COEFFICIENT_DEVIATION_EMPLOYEE_TYPE_LINKING,
        element: <LinkCoefficientDeviationEmployeetype />,
      },

      //coefficient deviations
      {
        path: ROUTE.MANAGE_COEFFICIENT_DEVIATION,
        element: <ManageCoefficientDeviationIndex />,
      },

      { path: ROUTE.MANAGE_CATEGORY, element: <ManageCategories /> },
      { path: ROUTE.MANAGE_FUNCTION, element: <ManageFunctions /> },

      { path: ROUTE.MANAGE_PC, element: <ManagePc /> },
      { path: ROUTE.EDIT_PC, element: <PcCommon type="edit" /> },
      { path: ROUTE.VIEW_PC, element: <PcCommon type="view" /> },

      // Manage salary benefits
      { path: ROUTE.MANAGE_SALARY_BENEFITS, element: <ManageSalaryBenefits /> },
      {
        path: ROUTE.MANAGE_SALARY_BENEFITS_EDIT,
        element: <ManageSalaryBenefitsEdit />,
      },

      // { path: ROUTE.SALARY_BENIFITS_EDIT_PC, element: <AddSalaryBenifits /> },
      { path: ROUTE.SALARY_BENIFITS_EDIT_PC, element: <SalaryBenifits /> },
      { path: ROUTE.SALARY_BENIFITS_VIEW_PC, element: <SalaryBenifits /> },

      // flex salary
      { path: ROUTE.FLEX_SALARY, element: <FlexSalary /> },

      // manage holidays
      { path: ROUTE.MANAGE_HOLIDAYS, element: <ManageHolidays /> },
      { path: ROUTE.MANAGE_HOLIDAYS_TYPE_ADD, element: <ManageHolidays /> },
      { path: ROUTE.MANAGE_HOLIDAYS_TYPE_EDIT, element: <ManageHolidays /> },

      { path: ROUTE.CONFIG_AND_SETTINGS, element: <ConfigurationDashboard /> },

      { path: ROUTE.PERMISSION_CREATE, element: <CreatePermission /> },
      { path: ROUTE.PERMISSION_EDIT, element: <CreatePermission /> },
      { path: ROUTE.PERMISSION_UPDATE, element: <CreatePermission /> },
      { path: ROUTE.PERMISSION_DELETE, element: <ManagePermissions /> },
      { path: ROUTE.MANAGE_PERMISSIONS, element: <ManagePermissions /> },
      {
        path: ROUTE.MANAGE_ROLES,
        element: <ManageRoles />,
        loader: rolesLoader,
      },
      { path: ROUTE.ROLE_CREATE, element: <RolePage /> },
      { path: ROUTE.ROLE_EDIT, element: <RolePage /> },
      {
        path: ROUTE.EMAIL_TEMPLATE_CREATE,
        element: <CreateEmailTemplate />,
      },
      {
        path: ROUTE.MANAGE_TRANSLATIONS,
        element: <ManageTranslation />,
      },
      {
        path: ROUTE.TRANSLATION_LINK_APP_LANGUAGE,
        element: <LinkAppLanguages />,
      },
      {
        path: ROUTE.EMPLOYEE_OVERVIEW,
        element: <EmployeeOverview />,
      },
      {
        path: ROUTE.WEEKLY_PLANNING,
        element: <WeeklyPlanning />,
      },
      {
        path: ROUTE.EMPLOYEE_REGISTRATION,
        element: <RegisterEmployee />,
      },
      {
        path: ROUTE.EMPLOYEE_EDIT,
        element: <RegisterEmployee />,
      },
      {
        path: ROUTE.EMPLOYEE_MY_ACCOUNT,
        element: <RegisterEmployee />,
      },
      {
        path: ROUTE.INVOICE_OVERVIEW,
        element: <InvoiceOverview />,
      },
      {
        path: ROUTE.EMPLOYEE_CONTRACTS,
        element: <EmployeeContracts />,
      },
      {
        path: ROUTE.DOCUMENTS_OVERVIEW,
        element: <DocumentsOverview />,
      },
      {
        path: ROUTE.WELCOME_DSHBOARD,
        element: <WelcomeDashboard />,
      },
      {
        path: "/planning-overview-new",
        element: <PlanningOverviewNew />,
      },
      {
        path: ROUTE.PLANNING_OVERVIEW,
        element: <PlanningOverview />,
      },
      {
        path: ROUTE.PLANNING_BY_LOCATION,
        element: <PlanningsByLocation />,
      },
      {
        path: ROUTE.DAY_PLANNING,
        element: <DayPlanningOverview />,
      },
      {
        path: ROUTE.WEEKLY_PLANNING_OVERVIEW,
        element: <WeeklyPlanningOverview />,
      },
      {
        path: ROUTE.MONTHLY_PLANNING,
        element: <MonthlyPlanningOverview />,
      },
      {
        path: ROUTE.LOCATION_DASHBOARD,
        element: <LocationDashboard />,
      },
      {
        path: ROUTE.MY_ACCOUNT,
        element: <ProfilePage />,
      },
      {
        path: ROUTE.EMAIL_TEMPLATE_EDIT,
        element: <EditEmailTemplate />,
      },
      {
        path: ROUTE.EMAIL_TEMPLATE_VIEW,
        element: <EditEmailTemplate />,
      },
      {
        path: ROUTE.MANAGE_EMAIL_TEMPLATES,
        element: <ManageEmailTemplatesPage />,
      },
      {
        path: ROUTE.MANAGE_EMAIL_LOGS,
        element: <ManageEmailLogs />,
      },
      {
        path: ROUTE.TIME_REGISTRATION,
        element: <TimeRegistrationOverview />,
      },
      {
        path: ROUTE.PROCESSES_TIME_RECORDS,
        element: <ProcessedTimeRecords />,
      },
      // {
      //   path: ROUTE.ref,
      //   element: <TimeTable  />,
      // },
      {
        path: ROUTE.ref,
        element: <TimeTable value={initialTimeStates} />,
      },
      {
        path: ROUTE.MANAGE_SHIFTS,
        element: <ManageShifts />,
      },
      {
        path: ROUTE.COOPERATION_AGREEMENT,
        element: <AgreementForm />,
      },
      {
        path: ROUTE.MANAGE_COOPERATION_AGREEMENT,
        element: <ManageCooperationAgreement />,
      },
      { path: ROUTE.EMAIL_TEMPLATE_CREATE, element: <CreateEmailTemplate /> },
      { path: ROUTE.EMAIL_TEMPLATE_EDIT, element: <EditEmailTemplate /> },
      { path: ROUTE.EMAIL_TEMPLATE_VIEW, element: <EditEmailTemplate /> },
      {
        path: ROUTE.MANAGE_EMAIL_TEMPLATES,
        element: <ManageEmailTemplatesPage />,
      },
      { path: ROUTE.MANAGE_EMAIL_LOGS, element: <ManageEmailLogs /> },
      { path: ROUTE.MANAGE_TRANSLATIONS, element: <ManageTranslation /> },
      {
        path: ROUTE.TRANSLATION_LINK_APP_LANGUAGE,
        element: <LinkAppLanguages />,
      },
      {
        path: ROUTE.ADD_LOCATION,
        element: <AddLocation />,
      },
      {
        path: ROUTE.MANAGE_LOCATIONS,
        element: <ManageLocations />,
      },
      {
        path: ROUTE.EDIT_LOCATION,
        element: <EditLocation />,
      },
      {
        path: ROUTE.ADD_PROJECT,
        element: <AddProject />,
      },
      {
        path: ROUTE.MANAGE_PROJECTS,
        element: <ManageProjects />,
      },
      {
        path: ROUTE.EDIT_PROJECT,
        element: <EditProject />,
      },
      {
        path: ROUTE.ADD_COSTCENTER,
        element: <AddCostCenter />,
      },
      {
        path: ROUTE.MANAGE_COSTCENTES,
        element: <ManageCostCenters />,
      },
      {
        path: ROUTE.EDIT_COSTCENTER,
        element: <EditCostCenter />,
      },
      {
        path: ROUTE.REPORT_ISSUE,
        element: <ReportIssue />,
      },
      {
        path: ROUTE.EMPLOYEE_INVITE,
        element: <InviteEmployee />,
      },
      {
        path: ROUTE.WELCOME,
        element: <WelcomeDashboard />,
      },
      {
        path: ROUTE.MANAGE_LOCATION,
        element: <ManageLocationNew />,
      },
      {
        path: ROUTE.QR_code,
        element: <QRCodeDisplay />,
      },
      {
        path: ROUTE.MANAGE_ADMINISTRATOR,
        element: <ManageAdministrator />,
      },
      {
        path: ROUTE.ADD_ADMINISTRATOR,
        element: <AddAdministrator />,
      },
      {
        path: "/follow-up",
        element: <TradeForm />,
      },
      {
        path: "/add-fare/:id?",
        element: <FareForm />,
      },
      {
        path: "/manage-fares",
        element: <ManageFares />,
      },
      {
        path: '/dashboard/follow-up',
        element: <AddTrade />,
      },
      {
        path: ROUTE.MANAGE_LOCATION_MANAGERS,
        element: <ManageLocationManagers />
      },
      {
        path: '/manage/follow-up',
        element: <ManageFollowUps />,
      },
      {
        path: '/types',
        element: <Types />,
      },
      {
        path: '/sales',
        element: <Sales/>,
      },
    ],
  },
]);
