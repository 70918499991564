import React, { useEffect, useState } from "react";
import Header from "../common/layout/Header";
import { t } from "../CentralDataMangement/translation/Translation";
import BackLink from "../common/atoms/BackLink";
import Button from "../common/atoms/Button";
import { useSelector } from "react-redux";
import OverFlowWrapper from "../common/OverFlowWrapper";
import Image from "../common/atoms/Image";
import UserImage from "../../static/images/User.svg";
import CheckBoxField from "../common/atoms/CheckBoxField";
import {
  COMPANY_EMPLOYEES,
  EMPLOYEE_ADD_ADMIN,
} from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import SearchBtn from "../common/atoms/SearchBtn";
import CustomNotify from "../common/atoms/CustomNotify";
import { useNavigate } from "react-router-dom";
import LabelField from "../common/atoms/LabelField";
import AutoCompleteWithSearch from "../common/molecules/AutoCompleteWithSearch";
import NoRecords from "../common/atoms/NoRecords";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import defaultCompanyIcon from "../../static/images/emptyCompany.png";

interface Company {
  id: number;
  name: string;
  official_name: string;
  profile_picture_url: string;
  locations: [];
  projects: [];
}

interface DataItem {
  id: number;
  locations: [];
  name: string;
  official_name: string;
  profile_picture_url: string | null;
  projects: [];
  email: string;
  first_name: string;
  companies: Company[];
  is_fav: boolean;
}
interface State {
  data: DataItem[]; // Store employee data
  searchTerm: string; // Store search input value
  selectedEmployees: number[]; // Track selected employees
  selectedCompanies: number[]; // Track selected companies
  suggestions: string[]; // Store suggestions
}

const AddAdministrator = () => {
  const companyData = useSelector((state: any) => state?.auth);
  const companies = companyData?.companies;
  const userId = companyData?.userId;

  const navigate = useNavigate();

  const [state, setState] = useState<State>({
    data: [], // Store employee data
    searchTerm: "", // Store search input value
    selectedEmployees: [], // Track selected employees
    selectedCompanies: [], // Track selected companies
    suggestions: [], // Store suggestions
  });

  useEffect(() => {
    getAllEmp(true);
  }, []);

  // Fetch employees based on search term
  const getAllEmp = async (suggest: Boolean = false) => {
    const url = `${COMPANY_EMPLOYEES}?employer_id=${userId}&q=${state.searchTerm}&suggestions=${suggest}`;
    try {
      const res = await ApiCall.getService(url, "GET", CENTRAL_DATA_MANAGEMENT_MICROSERVICE, true);
      if (res?.status !== "success") {
        throw new Error("Network response was not ok");
      }
      // Update both data and filteredData
      setState((prevState) => ({
        ...prevState,
        data: suggest == false ? res?.data : [],
        suggestions: suggest == true ? res?.data : [],
      }));
    } catch (err) {
      console.log(err);
    }
  };

  // Handle employee checkbox toggle
  const toggleEmployeeSelection = (employeeId: number) => {
    setState((prevState) => ({
      ...prevState,
      selectedEmployees: prevState?.selectedEmployees?.includes(employeeId)
        ? prevState?.selectedEmployees?.filter((id) => id !== employeeId) // Remove if already selected
        : [...prevState.selectedEmployees, employeeId], // Add if not selected
    }));
  };

  // Handle company checkbox toggle
  const toggleCompanySelection = (companyId: number) => {
    setState((prevState) => ({
      ...prevState,
      selectedCompanies: prevState?.selectedCompanies?.includes(companyId)
        ? prevState?.selectedCompanies?.filter((id) => id !== companyId)
        : [...prevState.selectedCompanies, companyId],
    }));
  };

  // Handle form submission (API call to add admin)
  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let url = `${EMPLOYEE_ADD_ADMIN}`;
    const dataObj = {
      users: state?.selectedEmployees,
      companies: state?.selectedCompanies,
    };

    if (dataObj?.companies?.length > 0 && dataObj?.users?.length > 0) {
        try {
          const res = await ApiCall.service(
            url,
            "POST",
            dataObj,
            true,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
          );
          if (res.status === "success") {
            CustomNotify({
              message: t("Admin added successfully"),
              type: "success",
              autoClose: 3500,
            });
            navigate("/manage-administrators");
          }
          if (res.status !== "success") {
            CustomNotify({
              message: t("Failed to add administrators"),
              type: "error",
              autoClose: 3500,
            });
            throw new Error("Failed to add administrators");
          }
        } catch (error) {
          console.error("Error:", error);
        }
    } else {
      CustomNotify({
        message: t("Employee or company is not selected"),
        type: "warning",
        autoClose: 3500,
      });
    }
  };
  return (
    <div>
      <Header
        headerName={t("Add administrators")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-2">
        <BackLink backLink={"/manage-administrators"} />
      </div>
      <div className="createpagesBg">
        <div className="row mb-4">
          <div className="col-6 addAdministratorSearch">
            {/* Search Input */}
            {/* <Search
              value={state?.searchTerm}
              placeholder={t("Search by name")}
              handleChange={(e) =>
                setState((prevState) => ({ ...prevState, searchTerm: e?.target?.value }))
              } // Update search term state
            /> */}
            <LabelField title={t("Add employee from pool") + ":"} />
            <AutoCompleteWithSearch
              placeholder={t("Search employee")}
              value={state?.searchTerm}
              suggestions={state?.suggestions}
              handleOptionClick={(value) => console.log(value)}
              handleChange={(value) =>
                setState((prevState: State) => ({
                  ...prevState,
                  searchTerm: value,
                }))
              }
              handleIconClick={() => getAllEmp()}
              className="inputFieldColor"
            />
          </div>
          {/* <div className="col-4">
            <div className="d-flex align-items-center">
              <SearchBtn handleSearchClick={() => getAllEmp()} />
            </div>
          </div> */}
        </div>
        <OverFlowWrapper className="manageAdministratorWrapper addAdministratorWrapper">
          <div className="container-fluid px-0">
            <div className="row">
              {/* Display filtered data */}
              {state?.data?.length > 0 &&
                state?.data?.map((employee) => (
                  <div
                    className="col-md-3 mb-4"
                    key={employee?.id}
                    onClick={() => toggleEmployeeSelection(employee?.id)} // Clicking on the card will toggle selection
                  >
                    <EmployeeDetailsCard
                      employee_data={employee}
                      cardConfig={{
                        cardClassName: `addEmployeeFromPoolCard d-flex align-items-center`,
                        employeeInformationClass: "planningEmployeeDetails",
                        cardMainClassName: ` ${state?.selectedEmployees?.includes(employee?.id)
                            ? "addedEmployeeFromPool"
                            : ""
                          } employeeDetailsCardPlanning colorPrimary position-relative h-100`,
                        favouriteIconName: "starIcon", // Star icon for favorite
                        noFavouriteIconName: "whiteStarIcon", // Empty star icon for not favorite
                        isFavourite: employee?.is_fav,
                      }}
                    />
                  </div>
                ))}
            </div>            
            <LabelField title={t("If you give an employee or another person access to manage the schedule, that person can see confidential information") + "."} />
            <LabelField title={t("Select the company to which you would like to add this employee as an administrator") + ":"} isMandatory={true} />
            
            <div className="mt-3" style={{paddingLeft:"0.08vw"}}>
              {companies?.length > 0 ? (
                <div
                  className="d-grid"
                  style={{ gridTemplateColumns: "repeat(7, 1fr)" }}
                >
                  {companies?.map((company: Company) => (
                    <div
                      className={`${state?.selectedCompanies?.includes(company?.id)
                          ? "selectedCompanies"
                          : ""
                        } radioButtonContainer checkBoxAdminContainer cursor-pointer`}
                      key={company?.id}
                      onClick={() => toggleCompanySelection(company?.id)} // Toggle selection on click
                    >
                      <CheckBoxField
                        name={`select_${company?.id}`}
                        className="my-checkbox"
                        id={`select_${company?.id}`}
                        lineHeight="1vw"
                        isChecked={state?.selectedCompanies?.includes(
                          company?.id
                        )}
                      />
                      <div className="mt-1">
                        <Image
                          src={company?.profile_picture_url || defaultCompanyIcon} // Default image if none provided
                          imageWidth="3vw"
                          imageHeight="3vw"
                          imageBorderRadius="50%"
                        />
                        <LabelField
                          title={company.name}
                          className="mt-2 d-block cursor-pointer"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <NoRecords message={t("No companies available")} />
              )}
            </div>
          </div>
        </OverFlowWrapper>
        <div className="row mt-3 pb-3">
          <div className="col-12">
            <Button
              title={t("Save")}
              className="searchBtn float-end"
              handleClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdministrator;
