import React from "react";
import Icon from "../../Icon";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";

interface InvoicesDataCardProps {
  isOutstanding: boolean;
  invoice: any;
  company: any;
  viewInvoice: (invoiceId: number) => void;
  downloadInvoice: (invoiceId: number) => void;
}

const getWeekNumber = (date: Date): number => {
  const startDate = new Date(date?.getFullYear(), 0, 1);
  const days = Math.floor(
    (date.getTime() - startDate?.getTime()) / (24 * 60 * 60 * 1000)
  );
  return Math.ceil((days + 1) / 7);
};

const InvoicesDataCard: React.FC<InvoicesDataCardProps> = ({
  isOutstanding,
  invoice,
  company,
  viewInvoice,
  downloadInvoice,
}) => {
  const IconProps = {
    isIcon: true,
  };

  const invoiceDate = new Date(invoice?.invoice_details?.invoiced_date);
  const weekNumber = getWeekNumber(invoiceDate);

  return (
    <>
      <table className="table table-borderless mb-0">
        <tbody>
          <tr className="position-relative">
            <td
              style={{
                width: "50%",
                borderTopLeftRadius: "0.8vw",
                borderBottomLeftRadius: "0.8vw",
              }}
              className="ps-4 text-break tableBg"
            >
              {isOutstanding ? (
                <div className="pendingInvoiceCircle"></div>
              ) : (
                ""
              )}
              <div className="invoiceData">
                {t("Week")} {weekNumber}
              </div>
              <div className="invoiceData">
                {t("Company")} "{company?.official_name}"
              </div>
              <div className="invoiceData">
                {t("Listing")}: {invoice?.invoice_number}
              </div>
            </td>
            <td
              style={{
                width: "35%",
                borderTopRightRadius: "0.8vw",
                borderBottomRightRadius: "0.8vw",
              }}
              className="align-middle tableBg"
            >
              <div className="d-flex align-items-center row">
                <div className="colorPrimary col-6">
                  <span className="invoiceAmount">
                    €
                    {new Intl.NumberFormat("de-DE").format(
                      invoice?.invoice_details?.total
                    )}
                  </span>
                  <span
                    className="poppins-semibold ms-1"
                    style={{ fontSize: "0.7vw" }}
                  >
                    incl btw
                  </span>
                </div>
                <div className="col-6">
                  <span
                    className="cursor-pointer"
                    title={isOutstanding ? t("Pay here") : t("Paid")}
                    onClick={() =>
                      isOutstanding && viewInvoice(invoice?.bright_id)
                    }
                  >
                    {isOutstanding ? (
                      ""
                    ) : (
                      <Icon
                        {...IconProps}
                        name="TickIcon"
                        width="1vw"
                        height="1vw"
                        className="secondaryColorHoverEffect cursor-pointer me-3"
                      />
                    )}
                  </span>
                  <span
                    className="me-3"
                    title={t("View")}
                    onClick={() => viewInvoice(invoice?.bright_id)}
                  >
                    <Icon
                      {...IconProps}
                      name="viewIcon"
                      width="1vw"
                      className="secondaryColorHoverEffect cursor-pointer"
                      height="1vw"
                    />
                  </span>
                  <span
                    className=""
                    title={t("Download")}
                    onClick={() => downloadInvoice(invoice?.bright_id)}
                  >
                    <Icon
                      {...IconProps}
                      name="downloadIcon"
                      width="0.8vw"
                      height="0.8vw"
                      className="secondaryColorHoverEffect cursor-pointer"
                    />
                  </span>
                </div>
              </div>
            </td>
          </tr>
          <TableHeaderSpace headerLength={5} />
        </tbody>
      </table>
    </>
  );
};

export default translate(InvoicesDataCard);
