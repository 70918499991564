import React from "react";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";
import { t } from "../../../CentralDataMangement/translation/Translation";
import Button from "../../../common/atoms/Button";
import { EmployeeDetails, SearchEmailProps } from "../Interfaces";
import { ApiCall } from "../../../../services/ApiServices";
import { GET_EMPLOYEE_BY_EMAIL } from "../../../../routes/ApiEndpoints";
import CustomNotify from "../../../common/atoms/CustomNotify";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../../Constants";

const SearchEmail: React.FC<SearchEmailProps> = ({
  email,
  handleChange,
  updateValues,
}) => {
  const handleVerify = async () => {
    if (email) {
      const response = await ApiCall.getService(
        `${GET_EMPLOYEE_BY_EMAIL}/${email}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );
  
      if (response.status === "success") {
        let data: EmployeeDetails = {
          user_id: response.data.id,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          phone_number: response.data.phone_number,
          email: response.data.email,
        };
  
        updateValues(data);
      } else {
        CustomNotify({
          type: "error",
          message: response.message,
        });
      }
    }
  };

  return (
    <div className="row">
      <div className="col-6">
        <LabelWithInputField
          label={t("Email") + ":"}
          className="inputFieldColor"
          value={email}
          handleChange={handleChange}
          name="verify_email"
          id="verify_email"
        />
      </div>
      <div className="col-1 ps-0 seachBtnWrapper">
        <Button
          title={t("Search")}
          className="saveBtn"
          handleClick={handleVerify}
        />
      </div>
    </div>
  );
};

export default SearchEmail;
