import React, { useEffect, useState } from "react";
import Icon from "../../../Icon";
import { valueContainerCSS } from "react-select/dist/declarations/src/components/containers";

interface DateAndTimeProps {
  dataTimeWrapperClassName?: string;
  dataTimeClassName?: string;
  isDay?: boolean;
  isDate?: boolean;
  isTime?: boolean;
  isPipe?: boolean;
  dayClassName?: string;
  isIcon?: boolean;
  showYear?: boolean;
  monthFormat?: "short" | "long";
  weekFormat?: "short" | "long";
  showMonth?: boolean;
  showDate?: boolean;
  iconFillColor?: string;
  isMonth?: boolean;
  isWeek?: boolean;
  onDateChange?: (newDate: Date) => void;
  isWeekDate?: boolean;
  value?: string;
  component?: string;
}

const DateAndTime: React.FC<DateAndTimeProps> = ({
  dataTimeClassName,
  dataTimeWrapperClassName,
  isDate = false,
  isDay = false,
  isTime = false,
  isPipe = false,
  dayClassName,
  isIcon = false,
  showYear = false,
  monthFormat = "short",
  weekFormat = "short",
  showMonth,
  showDate,
  iconFillColor,
  isMonth = false,
  isWeek = false,
  onDateChange,
  isWeekDate = false,
  value = "",
  component = "",
}) => {
  const dateTimeIconProps = {
    isIcon: true,
    width: "0.8vw",
    height: "0.8vw",
    outline: "0.2vw solid #fff",
    outlineOffset: "0vw",
    borderRadius: "50%",
  };

  const [dateTime, setDateTime] = useState(() => {
    return value ? new Date(value) : new Date();
  });
  const [isManualChange, setIsManualChange] = useState(false);

  // Update time every second
  useEffect(() => {
    if (!value && component == "header") {
      const intervalId = setInterval(() => {
        setDateTime((prevDateTime) => new Date());
      }, 1000); // Update every second

      return () => clearInterval(intervalId);
    }// Cleanup on component unmount
  }, [value]);

  const formatDate = (options: Intl.DateTimeFormatOptions) =>
    dateTime.toLocaleDateString("en-GB", options);

  const formattedDay = formatDate({ weekday: weekFormat });
  const formattedDate = formatDate({
    ...(showDate && { day: "2-digit" }),
    ...(showMonth && { month: monthFormat }),
    ...(showYear && { year: "numeric" }),
  });
  const formattedMonth = formatDate({
    month: monthFormat,
    ...(showYear && { year: "numeric" }),
  });

  const getWeekNumber = (date: Date): number => {
    const start = new Date(date.getFullYear(), 0, 1);
    const days = Math.floor(
      (date.getTime() - start.getTime()) / (24 * 60 * 60 * 1000)
    );
    const weekNumber = Math.ceil((days + start.getDay() + 1) / 7);
    return weekNumber;
  };

  const formattedWeek = `Week ${getWeekNumber(dateTime)}`;

  const handleDateChange = (amount: number) => {
    let updatedDate = new Date(dateTime);
    // console.log(weekNumber);
    if (isDay) {
      updatedDate.setDate(updatedDate.getDate() + amount);
    } else if (isMonth) {
      updatedDate.setMonth(updatedDate.getMonth() + amount);
    } else if (isWeek) {
      // let weekno = getWeekNumber(updatedDate) + amount;
      updatedDate.setDate(updatedDate.getDate() + amount * 7);
      let weekdates = getStartAndEndOfWeek(updatedDate);
      updatedDate = weekdates.start;
    }
    setDateTime(updatedDate);
    setIsManualChange(true);
    if (onDateChange) onDateChange(updatedDate);

  };
  const getStartAndEndOfWeek = (date: Date): { start: Date; end: Date } => {
    const start = new Date(date);
    const end = new Date(date);
    const day = start.getDay(); // Get the day of the week (0 for Sunday, 6 for Saturday)

    // If day is Sunday (0), set start to previous Monday (i.e., subtract 6 days)
    const diffToMonday = day === 0 ? -6 : 1 - day; // Adjust to start on Monday
    const diffToSunday = day === 0 ? 0 : 7 - day;  // Adjust to end on Sunday

    start.setDate(start.getDate() + diffToMonday);  // Move start to Monday
    end.setDate(end.getDate() + diffToSunday);      // Move end to Sunday
    return { start, end };
  };

  const { start, end } = getStartAndEndOfWeek(dateTime);

  const formattedStartWeekDate = start.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: monthFormat,
  });

  const formattedEndWeekDate = end.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: monthFormat,
  });

  return (
    <div className={dataTimeWrapperClassName}>
      {isIcon && (
        <span
          className="backIconWrapper cursor-pointer"
          onClick={() => handleDateChange(-1)}
        >
          <Icon
            name="backIcon"
            {...dateTimeIconProps}
            className="mx-2 prevIcon"
            fillColor={iconFillColor}
          />
        </span>
      )}

      <div className={dataTimeClassName}>
        {isDay && <span className={dayClassName}>{formattedDay}</span>}
        {isDate && <>{formattedDate}</>}
        {isPipe && <span className="mx-2">|</span>}
        {isTime && (
          <>
            {dateTime.toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              // second: "2-digit",
              hour12: false,
            })}
          </>
        )}
        {isMonth && <span className={dayClassName}>{formattedMonth}</span>}
        {isWeek && (
          <span className={dayClassName}>
            {formattedWeek}
            {isWeekDate && (
              <span className="ms-2">
                {formattedStartWeekDate} - {formattedEndWeekDate}
              </span>
            )}
          </span>
        )}
      </div>

      {isIcon && (
        <span
          className="nextIconWrapper cursor-pointer"
          onClick={() => handleDateChange(1)}
        >
          <Icon
            name="nextIcon"
            {...dateTimeIconProps}
            className="mx-2 nextIcon"
            fillColor={iconFillColor}
          />
        </span>
      )}
    </div>
  );
};

export default DateAndTime;
