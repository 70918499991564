import React, { ReactNode } from "react";

interface AccordionCustomProps {
  accordionTitle?: string;
  children?: ReactNode;
  accordionTitleClassName?: string;
  accordionButton?: string;
  id: string; // Added `id` to uniquely identify each accordion
  expanded: boolean; // Whether the accordion is expanded or not
  onToggle: (id: string) => void; // Function to handle the toggle action
  accordionBodyClassName?: string;
  ariaExpanded?: boolean;
}

const DashboardAccordion: React.FC<AccordionCustomProps> = ({
  accordionTitle,
  children,
  accordionTitleClassName,
  accordionButton,
  id,
  expanded,
  onToggle,
  accordionBodyClassName,
  ariaExpanded,
}) => {
  return (
    <>
      <div className="accordion" id={`accordionExample${id}`}>
        <div className="accordion-item bg-transparent border-0">
          <h2 className="accordion-header" id={`header${id}`}>
            <button
              className={`${accordionButton} accordion-button  ${ariaExpanded ? '' : 'collapsed'}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${id}`}
              aria-expanded={ariaExpanded}
              aria-controls={`collapse${id}`}
              onClick={(event) => {
                onToggle(id); // Call the toggle function with the id
              }}
            >
              <div className={accordionTitleClassName}>{accordionTitle}</div>
            </button>
          </h2>
          <div
            id={`collapse${id}`}
            className={`accordion-collapse collapse ${ariaExpanded ? 'show' : ''}`} // Conditional class addition
            aria-labelledby={`heading${id}`}
            data-bs-parent={`#accordionExample${id}`}
          >
            <div
              className={`accordion-body bg-white border-0 ${accordionBodyClassName}`}
            >
              <div className="accordion-body">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAccordion;
