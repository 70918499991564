// StatusCircle.tsx
import React from "react";
import {
  getStatusColorAndTitle,
  Status,
} from "../../Planning/Services/PlanningHelper";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";

interface StatusCircleProps {
  status: Status;
  failedReason?: string;
}

const StatusCircle: React.FC<StatusCircleProps> = ({
  status,
  failedReason,
}) => {
  const { color, title } = getStatusColorAndTitle(status, failedReason);

  return (
    <svg
      width="0.8vw" // Adjust size as needed
      height="0.8vw"
      viewBox="0 0 24 24"
      style={{ display: "inline-block", verticalAlign: "middle" }}
      aria-label="Status"
    >
      <circle cx="12" cy="12" r="10" fill={color} />
      <title>{t(title)}</title>
    </svg>
  );
};

export default translate(StatusCircle);
