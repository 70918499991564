import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import Image from "../atoms/Image";
import Icon from "../../../Icon";
import UserImage from "../../../static/images/User.svg";

interface PlanningEmployeeDetailsCardProps {
  cardClassName?: string;
  employeeInformationClass?: string;
  employeeName: string;
  employeeEmpType: string;
  employeeLocation: string;
  employeeFunction: string;
  employeePC: string;
  employeeSalary: string;
  employeeImage?: string; // Optional, default image if not provided
  isCardIcon?: boolean;
  iconName?: string;
  cardMainClassName?: string;
  iconClassName?: string;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  favouriteIconClassName?: string;
  isFavourtite?: boolean;
  favouriteIconName?: string;
}

const PlanningEmployeeDetailsCard: React.FC<
  PlanningEmployeeDetailsCardProps
> = ({
  cardClassName,
  employeeInformationClass,
  employeeName,
  employeeEmpType,
  employeeLocation,
  employeeFunction,
  employeePC,
  employeeSalary,
  employeeImage,
  isCardIcon = false,
  iconName,
  cardMainClassName,
  iconClassName,
  handleClick,
  favouriteIconClassName,
  isFavourtite,
  favouriteIconName,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [tooltipPosition, setTooltipPosition] = useState<string>("bottom");

  useEffect(() => {
    if (cardRef.current) {
      const { top, bottom } = cardRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - bottom;
      const spaceAbove = top;

      if (spaceBelow < 200 && spaceAbove > 200) {
        setTooltipPosition("top");
      } else {
        setTooltipPosition("bottom");
      }
    }
  }, []);
  return (
    <div className={`${cardMainClassName} ${tooltipPosition}`} ref={cardRef}>
      {isCardIcon && (
        <div className={iconClassName}>
          <span onClick={handleClick}>
            <Icon isIcon={true} width="1vw" height="1vw" name={iconName} />
          </span>
        </div>
      )}
      <div className={`${cardClassName}`}>
        {isFavourtite && (
          <div className={`${favouriteIconClassName}`}>
            <span onClick={handleClick}>
              <Icon
                isIcon={true}
                width="1vw"
                height="1vw"
                name={favouriteIconName}
              />
            </span>
          </div>
        )}
        <div className="mx-3">
          <Image
            src={employeeImage || UserImage} // Default image if none provided
            imageWidth="3vw"
            imageHeight="3vw"
            imageBorderRadius="50%"
          />
        </div>
        <div className={employeeInformationClass}>
          <div>{employeeName}</div>
          <div className="empDetails">
            <div>{employeeEmpType}</div>
            <div>{employeePC}</div>
            <div>{employeeFunction}</div>
            <div>€ {employeeSalary}</div>
            <div>{employeeLocation ?? ""}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanningEmployeeDetailsCard;
