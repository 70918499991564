import React from "react";
import { t } from "../../CentralDataMangement/translation/Translation";

interface Props {
  headerLength?: number;
  isDiv?: boolean;
  message?: string; // Optional message prop for customization
  className?: string;
  color?: string;
}

const NoRecords: React.FC<Props> = ({
  headerLength,
  isDiv = false,
  message = t("No records"), // Default to translated "No records" if not provided,
  color = "text-danger",
  className,
}) => {
  return (
    <>
      {isDiv ? (
        <div className={`text-center noRecordsStyle  ${className}`}>{message}</div>
      ) : (
        <tr>
          <td
            colSpan={headerLength}
            className={`border ${color} text-center p-2 noRecordsStyle`}
          >
            {message}
          </td>
        </tr>
      )}
    </>
  );
};

export default NoRecords;
