import React, { ReactNode } from "react";
import { Modal } from "react-bootstrap";
import {
    t,
    translate,
} from "../../CentralDataMangement/translation/Translation";
import Button from "../../common/atoms/Button";

interface ConfirmPopupProps {
    size?: 'sm' | 'lg' | 'xl';
    show: boolean;
    onHide?: () => void;
    title: string;
    body: ReactNode | string;
    onCloseButtonClick?: () => void;
    onConfirmButtonClick?: (event?: any) => void;
    closeTitle?: string;
    confirmTitle?: string;
    className?: string;
    inputClass?: string;
    headerClassName?: string;
    showCloseButton?: boolean;
    isInnerHTML?: boolean;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({
    size,
    show,
    onHide,
    title,
    body,
    onCloseButtonClick,
    onConfirmButtonClick,
    closeTitle,
    confirmTitle,
    className,
    inputClass,
    headerClassName,
    showCloseButton = true,
    isInnerHTML = false
}) => {


    return (
        // <Modal
        //     show={true}
        //     onHide={popupActionNo}
        //     centered
        //     backdrop="static"
        //     className="modalWithoutTabs customModal"
        // >
        <Modal size={size} show={show} onHide={onHide} centered className={`${className} modalWithoutTabs customModal`}>
            <Modal.Header closeButton style={{ backgroundColor: "rgb(133, 113, 185)" }} className="p-0 text-white">
                <Modal.Title className="text-center w-100 py-2 modal-title">
                    {t(title)}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="border-0">
                {t(body)}
            </Modal.Body>

            {/* <Modal.Footer className="border-0 pt-0 pb-3">
                <Button className="resetBtn" handleClick={onCloseButtonClick}>
                    {t("No")}
                </Button>
                <Button className="searchBtn px-3" handleClick={popupActionYes}>
                    {t("Yes")}
                </Button>
            </Modal.Footer> */}
            <Modal.Footer className="border-0">
                {closeTitle && onCloseButtonClick && (
                    <button
                        onClick={onCloseButtonClick}
                        className="resetBtn px-3"
                    >
                        {closeTitle}
                    </button>
                )}
                {confirmTitle && onConfirmButtonClick && (
                    <button
                        onClick={onConfirmButtonClick}
                        className="searchBtn px-3"
                    >
                        {confirmTitle}
                    </button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default translate(ConfirmPopup);
