// src/components/QRCodeDisplay.tsx

import React, { useEffect, useState } from "react";
import {
  CREATE_QR_CODE,
  QR_CODE,
  QR_CODE_REFRESH,
} from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import LabelField from "../common/atoms/LabelField";
import { t } from "../CentralDataMangement/translation/Translation";
import Header from "../common/layout/Header";
import Button from "../common/atoms/Button";
import Icon from "../../Icon";
import { useSelector } from "react-redux";
import BackLink from "../common/atoms/BackLink";
import { useParams } from "react-router-dom";
import PrintFile from "../common/molecules/PrintFile";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
// import "./css/qrCode.css";
interface QRCodeResponse {
  statusCode: number;
  message: string;
  data: string;
}
interface Companies {
  id: number;
  name: string;
  official_name: string;
  profile_picture_url: string | null;
  locations: Array<any>; // Adjust the type based on your location data structure
}

interface QRCodeDisplayProps {
  // companyId: string;
  // item: any;
}
const QRCodeDisplay: React.FC<QRCodeDisplayProps> = () => {
  const { companyId } = useParams();
  const authData = useSelector((state: any) => state?.auth);
  const userId = authData?.userId;
  const companies: Companies[] = authData?.companies;
  const company = companies.find((item) => item.id === Number(companyId));

  const companyName = company ? company.name : "Company not found";

  const [data, setData] = useState({
    qrCodeUrl: "",
    createdOn: "",
  });
  const fetchData = async () => {
    let url = `${QR_CODE}/${companyId}`;
    try {
      const response = await ApiCall.getService(
        url,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );
      console.log(response);
      if (response.message == "Success") {
        setData((prevState: any) => ({
          ...prevState,
          qrCodeUrl: response?.data?.qr_code_path
            ? response?.data?.qr_code_path
            : createQrCode(),
          createdOn: formattedDate(response?.data?.created_at),
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (companyId) {
      fetchData();
    }
  }, [companyId]);

  const createQrCode = async () => {
    let url = `${CREATE_QR_CODE}`;
    let dataObj = {
      company_id: companyId,
      user_id: userId,
    };
    try {
      const response = await ApiCall.service(
        url,
        "POST",
        dataObj,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.message === "Success") {
        setData((prevState: any) => ({
          ...prevState,
          qrCodeUrl: response?.data?.link,
          createdOn: formattedDate(response?.data?.qr_code?.created_at),
        }));
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const formattedDate = (date: string | number | Date | undefined): string => {
    if (!date) return "";

    const createdDate = new Date(date);

    // Get components of the date and time
    const day = String(createdDate.getDate()).padStart(2, "0");
    const month = String(createdDate.getMonth() + 1).padStart(2, "0");
    const year = createdDate.getFullYear();
    const hours = String(createdDate.getHours()).padStart(2, "0");
    const minutes = String(createdDate.getMinutes()).padStart(2, "0");
    const seconds = String(createdDate.getSeconds()).padStart(2, "0");

    // Construct the formatted date string
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleRefresh = async () => {
    const dataObj = {
      company_id: companyId,
      user_id: userId,
    };
    let url = `${QR_CODE_REFRESH}`;
    try {
      const response = await ApiCall.service(url, "POST", dataObj, true);
      if (response.message === "Success") {
        setData((prevState: any) => ({
          ...prevState,
          qrCodeUrl: response?.data?.link,
          createdOn: formattedDate(response?.data?.qr_code?.created_at),
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div>
      <Header headerName={t("QR code")} headerClassName="myAccountCardTitle" />
      <div className="search-bar pb-2">
        <BackLink backLink={"/welcome-dashboard"} />
      </div>
      <div className="createpagesBg py-5 ps-5">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <LabelField
              title={t("QR code") + ": " + t("" + companyName + "")}
              className="mb-2"
            />
            <br />
            <LabelField
              title={"1. " + t("Scan the QR code")}
              className="mb-2"
            />
            <br />
            <LabelField
              title={
                "2. " +
                t("Choose an action in the app that you want to perform")
              }
              className="mb-2"
            />
            <br />
            <LabelField
              title={"3. " + t("Confirm your choice")}
              className="mb-2"
            />
            <br />
            <div className="qr-code-img-wrapper">
              {data.qrCodeUrl && (
                <img
                  className="w-50 m-auto h-100"
                  src={data.qrCodeUrl}
                  alt={t("QR Code")}
                  title={t("QR code")}
                />
              )}
              <div className="d-flex align-items-center">
                <LabelField
                  title={t("QR code created on") + ": " + data.createdOn}
                />
                <div
                  className="d-inline-flex align-items-center"
                  onClick={handleRefresh} // Call handleRefresh when clicked
                >
                  <span className="ms-2 secondaryColorHoverEffect cursor-pointer">
                    <Icon
                      name="refreshIcon"
                      isIcon={true}
                      width="0.8vw"
                      height="0.8vw"
                    />
                  </span>
                  {data.qrCodeUrl && (
                    <div className="print-container secondaryColorHoverEffect cursor-pointer">
                      <PrintFile fileUrl={data?.qrCodeUrl} isQRcode={true} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Button
              title={t("View history")}
              className="searchBtn float-start mt-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCodeDisplay;
