import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Company,
  DropdownOptions,
  ReduxState,
} from "../../Dashboard/services/CompanyInterface";
import { addUserDetails } from "../../../features/auth/AuthSlice";
import { LOCATION_DASHBOARD } from "../../../routes/RouteConstants";
import { ApiCall } from "../../../services/ApiServices";
import Header from "../../common/layout/Header";
import {
  PC_LIST,
  REGISTER_COUNTRY_LIST,
  REGISTER_SETTINGS_LIST,
} from "../../../routes/ApiEndpoints";
import IconWithTitle from "../../common/molecules/IconWithTitle";
import Button from "../../common/atoms/ButtonIcon";
import { t, translate } from "../../CentralDataMangement/translation/Translation";
import LinkTo from "../../common/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Link from "../../common/atoms/Link";
import { selectAuth } from "../../../features/auth/AuthSlice";
type OptionType = { label: string; value: string };

const AddTrade: React.FC = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [pcValue, setPcValue] = useState<OptionType[] | null>(null);
  const user = useSelector((state: ReduxState) => state?.auth);
  const [companyData, setCompanyData] = useState<{
    companyid: number | null;
    companies: Company[];
    countries: DropdownOptions[] | undefined;
    iban: DropdownOptions[] | undefined;
    pc: DropdownOptions[] | undefined;
    timezones: DropdownOptions[] | undefined;
  }>({
    companies: [],
    countries: [],
    iban: [],
    pc: [],
    companyid: null,
    timezones: [],
  });
  type Props = {
    className: string;
    onClick?: () => void;
  };

  const Button: React.FC<Props> = ({ className, onClick }) => (
    <button className={className} onClick={onClick}>
      { }
    </button>
  );

  return (
    <>
      <Header
        headerName={t("Welcome") + "!"}
        headerClassName="myAccountCardTitle"
      />
      <div className="welcomeDashboardWrapper container-fluid">
        <div className="bg-white rounded-5 tradeDashboard">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 mb-5 mb-md-0">
              <Link href="/follow-up">
                <IconWithTitle
                  isIcon={true}
                  isTitle={true}
                  name="add"
                  borderRadius="50%"
                  width={window.innerWidth <= 767
                    ? "15vw"
                    : window.innerWidth <= 1023
                      ? "10vw"
                      : "7vw"}
                  height={window.innerWidth <= 767
                    ? "15vw"
                    : window.innerWidth <= 1023
                      ? "10vw"
                      : "7vw"}
                  iconImageWrapperClassName="text-center"
                  className="p-4"
                  iconBackgroundColor="#898989"
                  fillColor="#fff"
                  labelClassName="colorPrimary companyName mobileFont cursor-pointer nowrap"
                  title={t("Add follow up")}
                  titleImageWrapperClassName="text-center mt-3"
                  subIconImageWrapperClassName="text-center mt-2"
                  subIconname="Settings"
                />
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <Link href="/manage/follow-up">
                <IconWithTitle
                  isIcon={true}
                  isTitle={true}
                  name="add"
                  borderRadius="50%"
                  width={window.innerWidth <= 767
                    ? "15vw"
                    : window.innerWidth <= 1023
                      ? "10vw"
                      : "7vw"}
                  height={window.innerWidth <= 767
                    ? "15vw"
                    : window.innerWidth <= 1023
                      ? "10vw"
                      : "7vw"}
                  iconImageWrapperClassName="text-center"
                  className="p-4"
                  iconBackgroundColor="#898989"
                  fillColor="#fff"
                  labelClassName="colorPrimary companyName mobileFont cursor-pointer nowrap"
                  title={t("Manage follow up")}
                  titleImageWrapperClassName="text-center mt-3"
                  subIconImageWrapperClassName="text-center mt-2"
                  subIconname="Settings"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default translate(AddTrade);
