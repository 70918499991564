import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../../Icon';
import { t } from '../../atoms/translation';
import useWindowWidth from '../../atoms/WindowWidth';


interface Props {
  href?: string; // For URL paths
  handleClick?: () => void; // For function calls
}

const CustomView: React.FC<Props> = ({ href = "", handleClick }) => {
  const isDesktop = useWindowWidth(1024);

  return (
    <>
      {isDesktop ? (
        <Link
          to={href && !handleClick ? href : ""} // Only use `href` if there's no `handleClick`
          className="secondaryColorHoverEffect me-1"
          title={t("View")}
          onClick={handleClick || undefined} // If `handleClick` is provided, it will trigger on click.
        >
          <Icon isIcon={true} width="1vw" height="1vw" name="viewIcon" />
        </Link>
      ) : (
        <Link
          to={href && !handleClick ? href : ""}
          className="searchBtn text-decoration-none w-75 d-flex align-items-center justify-content-center mx-auto mb-3"
          onClick={handleClick || undefined}
        >
          {t("View")}
        </Link>
      )}
    </>
  );
};

export default CustomView;
