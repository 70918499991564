import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { ApiCall } from '../../../../services/ApiServices';
import { t, translate } from '../../../CentralDataMangement/translation/Translation';
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from '../../../../Constants';
import { FullScreenLoading } from '../../../common/utlis/FullScreenLoading';
import TableRendererHoliday from './TableRendererHoliday';
import OperationHolidays from './OperationHolidays';
import { FETCH_ALL_HOLIDAY_OCCASIONS } from '../../../../routes/ApiEndpoints';



const ManageHolidays = () => {

    const [state, setState] = useState({ loaded: false, rows: [], headers: [] })
    const { id, manageTypeHoliday } = useParams();
    const location = useLocation();
    const resolvedUrl = location.pathname + location.search;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        ApiCall.getService(
            FETCH_ALL_HOLIDAY_OCCASIONS,
            "GET",
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
            true
        )
            .then((result) => {
                console.log(result.data, "result");
                setState({
                    ...state,
                    loaded: true,
                    rows: result.status ? result.data : [],
                    headers: ['Holidays', 'Actions'],
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const isEditingOrAdding = resolvedUrl.includes('edit') || resolvedUrl.includes('add');


    return (
        <>
            <div className='container-fluid p-0 m-0'>
                <div className='md-3 col-md-12'>
                    {state.loaded ? (
                        isEditingOrAdding ? (
                            <OperationHolidays {...state} />

                        ) : (
                            <TableRendererHoliday {...state} loadData={loadData} />
                        )
                    ) : (
                        <FullScreenLoading />
                    )}
                </div>
            </div>
        </>
    )

}
export default translate(ManageHolidays);