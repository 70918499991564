import React from 'react';
import { useSelector } from 'react-redux';
import { selectTab, setTab } from '../../features/planning/planningSlice';
import WeeklyPlanning from './WeeklyPlanning';
import EmployeeScheduling from './EmployeeScheduling';
import PlanningOverview from './PlanningOverview';
import { useDispatch } from 'react-redux';
import { t, translate } from "../CentralDataMangement/translation/Translation";
const PlanningTabs: React.FC = () => {
    const dispatch = useDispatch();

    const tab = useSelector(selectTab) ?? dispatch(setTab('tab_1'));; // Access the `tab` state

    switch (tab) {
        case 'tab_1':
            return <WeeklyPlanning />
        case 'tab_2':
            return <EmployeeScheduling />;
        default:
            return <PlanningOverview />; // Default content or message
    }
};

export default translate(PlanningTabs);
