import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"; // import the icons
import React, { useState } from "react";
import { t } from "../../../common/atoms/translation";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";
import { EmployeeCommunicationProps } from "../../registration/Interfaces";
import {
  checkFieldMatch,
  validateMatchPassword,
  validatePasswordComplexityOneMessage,
} from "../../../../services/ValidationService";

const EmployeeCommunication: React.FC<EmployeeCommunicationProps> = ({
  state,
  setState,
  handleChange,
}) => {
  return (
    <div className="row">
      {/* E-mail Field */}
      <div className="col-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("E-mail") + ":"}
            className="inputFieldColor ps-2"
            colClassName="col-8"
            type="email"
            value={state?.email ?? ""}
            handleChange={(e) => {
              handleChange("email")(e);
              // Perform validation after the value is updated
              const { value } = e.target;
              setState((prevState: any) => ({
                ...prevState,
                errors: {
                  ...prevState.errors,
                  confirm_email: prevState.confirm_email
                    ? checkFieldMatch(
                        value.toLowerCase(),
                        prevState.confirm_email.toLowerCase(),
                        "E-mails"
                      )
                    : undefined,
                },
              }));
            }}
            name="email"
            isMandatory={true}
            error={state?.errors?.email ?? undefined}
            id="email"
            labelWrapperClassName="col-4 leftlabelClass"
            autoComplete="new-password"
          />
        </div>
      </div>
      <div className="col-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("Repeat E-mail") + ":"}
            className="inputFieldColor ps-2"
            colClassName="col-8"
            type="email"
            value={state?.confirm_email ?? ""}
            handleChange={(e) => {
              handleChange("confirm_email")(e);

              // Perform validation after the value is updated
              const { value } = e.target;
              setState((prevState: any) => ({
                ...prevState,
                errors: {
                  ...prevState.errors,
                  confirm_email: checkFieldMatch(
                    prevState.email.toLowerCase(),
                    value.toLowerCase(),
                    "E-mails"
                  ),
                },
              }));
            }}
            name="confirm_email"
            isMandatory={true}
            error={state?.errors?.confirm_email ?? undefined}
            id="confirm_email"
            labelWrapperClassName="col-4 pe-0 leftlabelClass"
            autoComplete="off"
            disableCopyPaste={true}
          />
        </div>
      </div>

      {/* Phone Number Field */}
      <div className="col-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("Phone") + ":"}
            className="inputFieldColor ps-2"
            colClassName="col-8"
            type="text"
            value={state?.phone_number ?? ""}
            handleChange={handleChange("phone_number")}
            name="phone_number"
            isMandatory={true}
            error={state?.errors?.phone_number ?? undefined}
            id="phone_number"
            labelWrapperClassName="col-4 leftlabelClass"
            autoComplete="new-password"
          />
        </div>
      </div>

      {/* Password Field */}
      <div className="col-4 mb-3">
        <div className="row position-relative">
          <LabelWithInputField
            label={t("Password") + ":"}
            className="inputFieldColor ps-2"
            colClassName="col-8"
            type={state?.showPassword ? "text" : "password"} // toggle password visibility
            value={state?.security?.password ?? ""}
            handleChange={(e) => {
              const { value } = e?.target;
              const updatedErrors = {
                ...state.errors,
                "security.password": validatePasswordComplexityOneMessage(
                  value.trim()
                ),
                "security.confirm_password": state?.security?.confirm_password
                  ? validateMatchPassword(
                      value.trim(),
                      state?.security?.confirm_password
                    )
                  : undefined,
              };
              setState((prevState: any) => ({
                ...prevState,
                security: {
                  ...prevState.security,
                  password: value.trim(), // Update the password value
                },
                errors: updatedErrors,
              }));
            }}
            name="password"
            id="password"
            labelWrapperClassName="col-4 leftlabelClass"
            isMandatory={state.id === 0 ? true : false}
            error={state?.errors?.["security.password"] ?? undefined}
            autoComplete="new-password"
          />
          <span
            onClick={() =>
              setState((prevData) => ({
                ...prevData,
                showPassword: !prevData.showPassword, // Toggle state
              }))
            }
            className="secondaryColorHoverEffect cursor-pointer col-auto eyeIcon"
          >
            <FontAwesomeIcon
              icon={state?.showPassword ? faEye : faEyeSlash} // change icon based on state
            />
          </span>
        </div>
      </div>

      {/* Confirm Password Field */}
      <div className="col-4 mb-3">
        <div className="row position-relative">
          <LabelWithInputField
            label={t("Confirm password") + ":"}
            className="inputFieldColor ps-2"
            colClassName="col-8"
            type={state?.showConfirmPassword ? "text" : "password"} // toggle confirm password visibility
            value={state?.security?.confirm_password ?? ""}
            handleChange={(e) => {
              const { value } = e.target;
              const updatedErrors = {
                ...state.errors,
                "security.confirm_password": validateMatchPassword(
                  state?.security?.password,
                  value.trim()
                ),
              };
              setState((prevState: any) => ({
                ...prevState,
                security: {
                  ...prevState.security,
                  confirm_password: value.trim(), // Update the password value
                },
                errors: updatedErrors,
              }));
            }}
            name="confirm_password"
            id="confirm_password"
            labelWrapperClassName="col-4 pe-0 leftlabelClass"
            isMandatory={state.id === 0 ? true : false}
            error={state?.errors?.["security.confirm_password"] ?? undefined}
            autoComplete="off"
            disableCopyPaste={true}
          />
          <span
            onClick={() =>
              setState((prevData) => ({
                ...prevData,
                showConfirmPassword: !prevData.showConfirmPassword, // Toggle state
              }))
            }
            className="secondaryColorHoverEffect cursor-pointer col-auto eyeIcon"
          >
            <FontAwesomeIcon
              icon={state?.showConfirmPassword ? faEye : faEyeSlash} // change icon based on state
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCommunication;
