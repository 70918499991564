import React from "react";
import LabelField from "./LabelField";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import Image from "./Image";
import defaultUserImage from "../../../static/images/User.svg";
import Icon from "../../../Icon";

interface Manager {
  id: number;
  name: string;
  email: string;
  profile_pic: string | null;
  since: string;
}
const IconProps = {
  isIcon: true,
  width: "1vw",
  height: "1vw",
};

interface LocationManagersListProps {
  managers: Manager[];
  onRemove: (id: number) => void;
}

const LocationManagersList: React.FC<LocationManagersListProps> = ({
  managers,
  onRemove,
}) => {
  return (
    <div>
      <LabelField title={t("Location managers:")} />
      {managers?.map((manager, index) => (
        <div className="row">
          <div className="col-2">
            {manager.profile_pic ? (
              <Image
                src={manager.profile_pic}
                title={`${manager.name}'s profile`}
              />
            ) : (
              <Image src={defaultUserImage} title="Default profile" />
            )}
          </div>
          <div className="col-4">
            <div className="row">
              <div className="col-12">Name : {manager.name}</div>
              <div className="col-12">Email : {manager.email}</div>
            </div>
          </div>
          <div className="col-4">
            Location manager since:{" "}
            {new Date(manager.since).toLocaleDateString()}
          </div>
          <div className="col-2">
            <span title={t("Remove")} onClick={() => onRemove(manager.id)} className="removeUploadedFile sec">
              <Icon name="RemoveIcon" {...IconProps} />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default translate(LocationManagersList);
