import React, { useContext, useEffect } from "react";
import LinkCoeffEmpContext from "./LinkCoeffDeviationEmp/LinkCoeffDeviationEmpContext";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";
import { helpers } from "./LinkCoeffEmpHelper";

const EmployeeTypeSecondPart = () => {
  const { state, updateStateChanges } = useContext(LinkCoeffEmpContext);
  const {
    employeeTypeArray,
    pclinkingValueobj,
    inputRef,
    selectedPc,
    selectedDevaitionObj,
    ConstructedCoefficientData,
    CoefficientDeviationlinkingValueobj,
  } = state;

  /**
   * [useEffect to check scroll button is needed or not]
   * @param  {function} const               [description]
   * @return {void}       [description]
   */
  useEffect(() => {
    const current = state.inputRef.current["secondpart"];
    const trigger = () => {
      const hasOverflow = current.scrollWidth > current.clientWidth;
      updateStateChanges({
        isOverflow: hasOverflow,
        scrollRight: true,
        scrollLeft: false,
        tableWidth: "97%",
      });
    };
    if (current) {
      trigger();
    }
  }, [selectedPc]);

  /**
   * [handleValueChange handling user input]
   * @param  {[type]} target                 [description]
   * @param  {[int]} _EmpId                 [description]
   * @param  {[int]} _Coeffid               [description]
   * @param  {[int]} _ValId                 [description]
   * @return {[void]}          [description]
   */
  const handleValueChange = (target, _EmpId, _Coeffid, _ValId) => {
    let value = target.value;
    let valueDataObj = {
      ...CoefficientDeviationlinkingValueobj,
      [_EmpId]: {
        ...(CoefficientDeviationlinkingValueobj[_EmpId]
          ? CoefficientDeviationlinkingValueobj[_EmpId]
          : {}),
        [_Coeffid]: {
          ...(CoefficientDeviationlinkingValueobj[_EmpId]
            ? CoefficientDeviationlinkingValueobj[_EmpId][_Coeffid]
            : {}),
          [_ValId]: value,
        },
      },
    };
    // lowHighDefaultChanges(_EmpId, _Coeffid, _ValId, valueDataObj) //not needed as of now
    updateStateChanges({
      CoefficientDeviationlinkingValueobj: valueDataObj,
    });
  };

  /**
   * [checkDecimalPointError description]
   * @param  {[type]} value               [description]
   * @return {[type]}       [description]
   */
  const checkDecimalPointError = (value) => {
    let status = false;
    if (value) {
      let inputVal = value.replace(",", ".");
      let decimals = inputVal.split(".")[1];
      status = decimals && decimals.length > 4 ? true : false;
    }
    return status;
  };

  const getEmployeeTypeTableContent = () => {
    let htmlContent = [];
    selectedDevaitionObj.map((coefficient) => {
      htmlContent.push(
        <tr
          key={`${coefficient.value}-1`}
          className="table-second-part-tbody-tr"
        >
          {employeeTypeArray.map((employeeType) => {
            let _EmpId = employeeType.id,
              _ValId = 1,
              _Coeffid = coefficient.value;
            let { matrixKey, value } = getPcLinkingValue(
              _EmpId,
              _Coeffid,
              _ValId
            );
            return (
              <td
                key={matrixKey}
                id={matrixKey}
                className="table-second-part-tbody-tr"
              >
                <input
                  type="text"
                  className="form-control border border-white shadow-none text-center"
                  value={value}
                  id={`${_EmpId}_${_Coeffid}_${_ValId}`}
                  ref={(ref) =>
                    (inputRef.current[`${_EmpId}_${_Coeffid}_${_ValId}`] = ref)
                  }
                  onChange={(e) =>
                    handleValueChange(e.target, _EmpId, _Coeffid, _ValId)
                  }
                />
              </td>
            );
          })}
        </tr>
      );
    });

    return htmlContent;
  };

  const getPcLinkingValue = (_EmpId, _Coeffid, _ValId) => {
    let matrixKey = `${_EmpId}-${_Coeffid}-${_ValId}`;
    let valueObj = "";
    console.log(matrixKey);
    console.log(CoefficientDeviationlinkingValueobj[_EmpId]);
    if (
      CoefficientDeviationlinkingValueobj[_EmpId] &&
      CoefficientDeviationlinkingValueobj[_EmpId][_Coeffid]
    ) {
      valueObj = CoefficientDeviationlinkingValueobj[_EmpId][_Coeffid][_ValId];
    } else {
      valueObj = ConstructedCoefficientData[_Coeffid]["coefficient"];
      let valueDataObj = {
        ...CoefficientDeviationlinkingValueobj,
        [_EmpId]: {
          ...(CoefficientDeviationlinkingValueobj[_EmpId]
            ? CoefficientDeviationlinkingValueobj[_EmpId]
            : {}),
          [_Coeffid]: {
            ...(CoefficientDeviationlinkingValueobj[_EmpId]
              ? CoefficientDeviationlinkingValueobj[_EmpId][_Coeffid]
              : {}),
            [_ValId]: valueObj,
          },
        },
      };
      updateStateChanges({
        CoefficientDeviationlinkingValueobj: valueDataObj,
      });
    }
    return {
      matrixKey,
      value: valueObj,
    };
  };

  return (
    <div ref={(ref) => (inputRef.current["secondpart"] = ref)}>
      <table className="table mb-0 defaultCoefficientTableRightSection table-bordered customTableProperties">
        <thead>
          <tr className="TableHeader align-middle" style={{ height: "5.99vw" }}>
            {employeeTypeArray.map((emp) => (
              <th className="text-center" key={emp.id} title={emp.name}>
                {emp.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{getEmployeeTypeTableContent()}</tbody>
      </table>
    </div>
  );
};

export default translate(EmployeeTypeSecondPart);
