import { Company, FormErrors, DropdownOptions, DefaultFormState } from "./services/CompanyInterface";

export const initialFormValues: DefaultFormState = {
  formValues: {
    id: null,
    vat_number: "",
    official_name: "",
    search_name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    security: {
      password: "",
      confirm_password: "",
    },
    profile_picture_url: "",
    profile_picture_id: null,
    profile_path: "",
    is_invoice_address: true,
    is_active: true,
    role: "employer",
    address: {

      official: {
        id: null,
        name: "",
        street: "",
        number: "",
        box: "",
        addition: null,
        postal_code: "",
        city: "",
        country: 29,
      },

      invoice: {
        id: null,
        name: "",
        street: "",
        number: "",
        box: "",
        addition: null,
        postal_code: "",
        city: "",
        country: 29,
      },
    },
    pc: [],
    iban_code: "BE",
    source: "",
    extrar_ref: "",
    pcvalues: [],
    timezone: 34
  },
  showPassword: false,
  showConfirmPassword: false,
  previousPcValues: [] as string[] | undefined,
  countryOptions: [] as DropdownOptions[] | undefined,
  pcOptions: [] as DropdownOptions[] | undefined,
  timezoneOptions: [] as DropdownOptions[] | undefined,
  errors: {} as FormErrors,
  ibancountries: [] as DropdownOptions[] | undefined,
  companies: [] as Company[],
  verifiedVatnumber: "",
  editing: false,
  loading: false
};
