import { validatePassword, validatePhoneNumber } from "../../../../../services/ValidationService";
import { Address, FormErrors } from "../../../../Dashboard/services/CompanyInterface";
import { isRequired, validateAddressFields } from "../../../../Dashboard/services/Companyutils";
import { EmergencyContact, FormValues, MyAccount } from "../MyAccountInterface";

export const formValidation = (formState: MyAccount) => {
    const errors: FormErrors = {};

    const fields = {
        string: ["first_name", "last_name", "email", "date_of_birth"] as (keyof FormValues)[],
        number: ["phone_number", "language_id"] as (keyof FormValues)[],
        address: ["street", "postal_code", "number", "country"] as (keyof Address)[],
        emergency_contact: ["name", "contact_number"] as (keyof EmergencyContact)[]
    };

    // Validate string fields
    fields.string.forEach((field) => {
        const value = formState?.formValues[field];
        if (isRequired(value)) {
            errors[field] = 'This field is required.';
        }
    });

    // Validate number fields
    fields.number.forEach((field) => {
        const value = formState?.formValues[field];
        if (isRequired(value)) {
            errors[field] = 'This field is required.';
        } else if (field === 'phone_number' && !/^[+0-9\s/\-()]{10,50}$/.test(value)) {
            errors[field] = 'Invalid phone number.';
        }
    });

    // Validate emergency contact
    validateEmergencyContact(
        formState?.formValues?.emergency_contact,
        errors,
        fields.emergency_contact
    );

    // Validate address fields (for residence)
    validateAddressFields(
        formState?.formValues?.address?.residence,
        errors,
        'residence',
        fields.address
    );

    // Validate passwords
    if (formState?.formValues?.security?.password) {
        const passwordError = validatePassword(formState?.formValues?.security?.password);
        if (passwordError) {
            errors.password = passwordError;
        }

        const confirmPasswordError = validatePassword(
            formState?.formValues?.security?.confirm_password || ""
        );
        if (confirmPasswordError) {
            errors.confirm_password = confirmPasswordError;
        }

        if (
            formState.formValues.security.confirm_password !==
            formState?.formValues?.security?.password
        ) {
            errors.confirm_password = "The two password fields didn't match.";
        }
    }

    return errors;
};

export const validateEmergencyContact = (
    emergency_contact: Record<string, any>,
    errors: FormErrors = {},
    fields: (keyof EmergencyContact)[]
): void => {
    const requiredMessage = 'This field is required.';
    const validRegex = /^[a-zA-Z0-9 .]+$/;
    fields.forEach((field) => {
        const value = emergency_contact?.[field];
        if (!value) {
            errors[`emergency_contact_${field}`] = requiredMessage;
        } else if (field === 'contact_number' && !/^[0-9]{10,50}$/.test(value)) {
            const phoneerror = validatePhoneNumber(field)
            errors[`emergency_contact_${field}`] = phoneerror;
        } else if (field === 'name' && !validRegex.test(value)) {
            errors[`emergency_contact_${field}`] = 'This field is invalid';
        }
    });
};


export const formatUserData = (data: any) => {
    return {
        ...data,
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
        phone_number: data?.phone_number,
        gender: data?.gender,
        date_of_birth: data?.date_of_birth,
        nationality_id: data?.nationality_id,
        is_national: data?.is_national,
        role: "employer",
        profile: true,
        signature: data?.signature,
        source: "web",
        address: {
            residence: {
                name: data?.address?.residence?.name,
                street: data?.address?.residence?.street,
                number: data?.address?.residence?.number,
                addition: data?.address?.residence?.addition,
                postal_code: data?.address?.residence?.postal_code,
                box: data?.address?.residence?.box,
                city: data?.address?.residence?.city ?? "City",
                country: data?.address?.residence?.country,
                latitude: "",
                longitude: ""
            }
        },
        emergency_contact: {
            user_id: data?.emergency_contact?.user_id,
            name: data?.emergency_contact?.name,
            email: data?.emergency_contact?.email,
            contact_number: data?.emergency_contact?.contact_number,
            relation: data?.emergency_contact?.relation
        }
    };
};