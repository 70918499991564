import secureLocalStorage from "react-secure-storage";

type Headers = {
	"Accept"?: string;
	"Content-Type"?: string;
	Authorization?: any;
	AuthToken?: string;
	"x-api-key"?: string;
	"x-tenant-id"?: string;
	//   Access-Control-Allow-Origin": string
};

export const refreshOauthTokenIfNeeded = async () => {
	const oAuthToken: any = secureLocalStorage.getItem('OAuth') ?? null;
	const expiresAt = new Date(oAuthToken?.expires_at).getTime();
	const now = Date.now();

	let token = oAuthToken?.token;
	if (!oAuthToken || expiresAt < now) {
		token = await getOauthKey();
	}
	return token;
};

const getOauthKey = async () => {
	try {
		const headers: Headers = {
			"Content-Type": "application/json",
			// "Access-Control-Allow-Origin": "*",
		};
		const url = `${process.env.REACT_APP_SERVICE_REGISTRY}oauth`;
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(
				{
					"client_secret": process.env.REACT_APP_SERVICE_API
				}),
			headers: headers,
		});

		const data = await response.json();
		if (data.status == "success") {
			secureLocalStorage.setItem('OAuth', data.data);
		} else {
			throw new Error(data.message);
		}
		return data?.data?.token;
	} catch (err) {
		console.error(err);
	}
}