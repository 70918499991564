export type Weekdays = 1 | 2 | 3 | 4 | 5 | 6 | 0;
export type Fields = "from" | "to" | "breakFrom" | "breakUntil" | "total";

export const weekDaysLabels: Record<Weekdays, string> = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const fieldLabels: Record<Fields, string> = {
  from: "From",
  to: "To",
  breakFrom: "Break From",
  breakUntil: "Break To",
  total: "Total",
};

export interface WeeklyPlanningProps {
  weekdays?: Weekdays[];
  fields?: Fields[];
  onChange?: (timeStates: Record<Weekdays, Record<Fields, string>>) => void;
}

export const defaultWeekdays: Weekdays[] = [1, 2, 3, 4, 5, 6, 0];

export const defaultFields: Fields[] = [
  "from",
  "to",
  "total",
  "breakFrom",
  "breakUntil",
];

// Format time input
export const formatTime = (value: string) => {
  value = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
  if (value.length <= 2) return value;
  if (value.length <= 4) return `${value.slice(0, 2)}:${value.slice(2, 4)}`;
  return `${value.slice(0, 2)}:${value.slice(2, 4)}`;
};

// Format total value
export const formatTotalValue = (value: string) => {
  value = value.replace(/[^0-9,]/g, ""); // Remove non-numeric characters and allow commas
  return value;
};

// Convert time to minutes
export const timeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  return !isNaN(hours) && !isNaN(minutes) ? hours * 60 + minutes : 0;
};

// Convert minutes to time format
export const minutesToTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
};

// Validate time format
export const isValidTime = (time: string) =>
  /^([01]\d|2[0-3]):([0-5]\d)$/.test(time)
;

// Convert total minutes to percentage format
export const convertMinutesToPercentage = (totalMinutes: number): string => {
  if (isNaN(totalMinutes) || totalMinutes < 0) return "0,00";
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const minutePercentage = Math.round((minutes / 60) * 100);

  // Format as "hours,minutePercentage"
  return `${hours},${minutePercentage.toString().padStart(2, "0")}`;
};

// Convert hh:mm to percentage format
export const convertTimeToPercentage = (time: string): string => {
  const totalMinutes = timeToMinutes(time);
  return convertMinutesToPercentage(totalMinutes);
};

export const calculateTotalTime = (
  from: string,
  to: string,
  breakFrom: string,
  breakUntil: string
) => {
  let totalMinutes = 0;

  if (isValidTime(from) && from) {
    const fromMinutes = timeToMinutes(from);
    let toMinutes = 24 * 60; // Default to 24:00 if until is not provided

    if (isValidTime(to) && to) {
      toMinutes = timeToMinutes(to);
      totalMinutes = toMinutes - fromMinutes;

      if (totalMinutes < 0) totalMinutes += 24 * 60; // Handle overnight shifts
    } else {
      // If 'until' is not provided, assume end of the day
      totalMinutes = 24 * 60 - fromMinutes;
    }

    if (isValidTime(breakFrom) && breakFrom) {
      if (breakFrom === "00:00") {
        // Special case: breakFrom is "00:00", total = until - by
        totalMinutes = toMinutes - fromMinutes;
        if (totalMinutes < 0) totalMinutes += 24 * 60; // Handle overnight shifts
      } else {
        const breakFromMinutes = timeToMinutes(breakFrom);

        if (isValidTime(breakUntil) && breakUntil) {
          if (breakUntil === "00:00") {
            // Special case: breakUntil is "00:00", calculate total as breakFrom - by
            totalMinutes = breakFromMinutes - fromMinutes;
            if (totalMinutes < 0) totalMinutes += 24 * 60; // Handle overnight shifts
          } else {
            const breakUntilMinutes = timeToMinutes(breakUntil);
            const breakDuration = breakUntilMinutes - breakFromMinutes;

            if (breakDuration > 0) {
              totalMinutes -= breakDuration;
            }
          }
        } else {
          totalMinutes = breakFromMinutes - fromMinutes;
          if (totalMinutes < 0) totalMinutes += 24 * 60; // Handle overnight shifts
        }
      }
    }
  }

  return totalMinutes > 0 ? minutesToTime(totalMinutes) : "00:00";
};


export type TimeStates = Record<Weekdays, Record<Fields, string>>;
// Initial time states with default values

export const initialTimeStates: TimeStates = {
  0: { from: "", to: "", total: "0,00", breakFrom: "", breakUntil: "" },
  1: { from: "", to: "", total: "0,00", breakFrom: "", breakUntil: "" },
  2: { from: "", to: "", total: "0,00", breakFrom: "", breakUntil: "" },
  3: { from: "", to: "", total: "0,00", breakFrom: "", breakUntil: "" },
  4: { from: "", to: "", total: "0,00", breakFrom: "", breakUntil: "" },
  5: { from: "", to: "", total: "0,00", breakFrom: "", breakUntil: "" },
  6: { from: "", to: "", total: "0,00", breakFrom: "", breakUntil: "" },
}
